const TwoColumns = {
  container: {
    fontFamily: "SpaceMono",
    // textAlign:'center',
    alignItems: "center",
    height: "100%",
    // justifyContent: 'centre',
  },
  title: {
    width: "100%",
    margin: 10,
    // marginTop:10,
    // textAlign:'center',
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    // padding: 20,
  },
  body: {
    height: "25%",
    display: "table",
    padding: 5,
    width: "auto",
    marginLeft: 15,
    // borderStyle: BORDER_STYLE,
    // borderColor: BORDER_COLOR,
    // borderWidth: 1,
    // borderRadius:10,
    borderBottom: "1px dashed #000",
    fontSize: 9,
  },
  row: {
    width: "100%",
    flexDirection: "row",
  },
  receipt: {
    width: "100%",
  },
  single: {
    width: 100 + "%",
  },
  double: {
    width: 100 + "%",
    // margin: "auto",
    flexDirection: "row",
  },
  token: {
    width: 100 + "%",
    // margin: "auto",
    fontWeight: "bold",
    fontSize: 11,
  },
  left: {
    width: 50 + "%",
    // margin: "auto",
    flexDirection: "row",
  },
  middle: {},
  right: {
    width: 50 + "%",
    // margin: "auto",
    flexDirection: "row",
  },
  caption: {
    width: "25%",
  },
  value: {
    width: "75%",
  },
  footer: {
    width: "100%",
    textAlign: "center",
    // margin:"auto"
  },
  number: {
    textAlign: "right",
    width: 50 + "%",
    marginRight: 50,
  },
  logo: {
    marginTop: 10,
    width: 97,
    height: 97,
  },
};
export default TwoColumns;
