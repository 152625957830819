import React, { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// @mui material components
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Notif({ open, message, color }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShow(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={show} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={color} sx={{ width: "100%" }}>
          <MKTypography variant="body2" color="white" fontWeight="regular">
            {message}
          </MKTypography>
        </Alert>
      </Snackbar>
    </Stack>
  );
}

Notif.defaultProps = {
  message: "",
  open: false,
  color: "success",
};

Notif.prototype = {
  message: PropTypes.string,
  open: PropTypes.bool,
  color: PropTypes.oneOf(["warning", "error", "info", "success"]),
};

export default Notif;
