const BORDER_COLOR = "#000";
const BORDER_STYLE = "solid";
const OnePage = {
  container: {
    fontFamily: "SpaceMono",
    // textAlign:'center',
    alignItems: "center",
    // justifyContent: 'centre',
  },
  body: {
    alignItems: "center",
  },
  title: {
    margin: 10,
    // marginTop:10,
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
    // padding: 20,
  },
  receipt: {
    display: "table",
    margin: 20,
    padding: 5,
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRadius: 10,
  },
  row: {
    width: "auto",
    margin: "auto",
    flexDirection: "row",
    fontSize: 12,
    marginBottom: 10,
  },
  single: {
    width: 100 + "%",
    textAlign: "center",
  },
  double: {
    width: 100 + "%",
    margin: "auto",
    flexDirection: "row",
  },
  left: {
    width: 50 + "%",
  },
  right: {
    width: 50 + "%",
  },
  number: {
    textAlign: "right",
    width: 50 + "%",
    marginRight: 50,
  },
  logo: {
    marginTop: 10,
    width: 97,
    height: 97,
  },
};
export default OnePage;
