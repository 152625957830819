import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import moment from "moment";
import { fnumber } from "helper/Helper";
import textLogo from "assets/img/logo.png";
import SpaceMonoRegular from "assets/fonts/SpaceMono-Regular.ttf";
import SpaceMonoBoldItalic from "assets/fonts/SpaceMono-BoldItalic.ttf";
import SpaceMonoItalic from "assets/fonts/SpaceMono-Italic.ttf";
import SpaceMonoBold from "assets/fonts/SpaceMono-Bold.ttf";
import receiptToArray from "libraries/receiptToArray";

import secureStorage from "libraries/secureStorage";

import OnePage from "helper/receiptStyles/OnePage";
import TwoColumns from "helper/receiptStyles/TwoColumns";
import ThreeColumns from "helper/receiptStyles/ThreeColumns";

import receipt from "helper/receipt";

const st = {
  OnePage,
  TwoColumns,
  ThreeColumns,
};

Font.register({
  family: "SpaceMono",
  // src: 'https://fonts.googleapis.com/css2?family=Space+Mono&display=swap',
  // fontStyle: 'normal',
  // fontWeight: 'normal',
  format: "truetype",
  fonts: [
    { src: SpaceMonoRegular },
    { src: SpaceMonoBoldItalic },
    { src: SpaceMonoItalic },
    { src: SpaceMonoBold },
  ],
});
// Create styles

// const styles = StyleSheet.create(preSyles)

// Create Document Component
class PdfReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "STRUK",
      body: [],
      logo: "",
      data: "",
    };
  }
  componentDidMount() {
    const data = this.props.data;
    let { title, body } = this.props.data;
    body = !Array.isArray(body) ? receiptToArray(body) : body;
    const printFormat = secureStorage.getItem("printFormat");
    // console.log({printFormat})
    if (printFormat && printFormat.value === "OnePage") {
      const account = secureStorage.getItem("account");
      const info = secureStorage.getItem("domainInfo");

      body.push([`Loket : ${account.name}`]);
      body.push([`Powered by ${info?.appName}`]);
    }
    this.setState({ body, title, data });
  }
  setContent = (title, body) => {
    body = !Array.isArray(body) ? receiptToArray(body) : body;
    this.setState({ title, body });
  };

  render() {
    // const styles = this.state.styles
    const printFormat = secureStorage.getItem("printFormat");
    // console.log(this.state.data);
    const styles = StyleSheet.create(
      printFormat && printFormat.value ? st[printFormat.value] : st.OnePage
    );
    let body = this.getOnePage();
    if (this.state.data) {
      if (printFormat && printFormat.value === "TwoColumns")
        body = this.getTwoColumns(this.state.data);
      else if (printFormat && printFormat.value === "ThreeColumns")
        body = this.getThreeColumns(this.state.data);
    }

    return (
      <Document>
        <Page style={styles.container}>{body}</Page>
      </Document>
    );
  }
  getOnePage(data) {
    // console.log(this.state.body)

    // console.log(typeof data.body[0][1])
    const hostname = window.location.hostname;
    const styles = StyleSheet.create(st.OnePage);
    return (
      <View style={styles.body}>
        <View style={styles.logo}>
          <Image src={hostname !== "payfren.id" ? "/android-icon-512x512.png" : textLogo} />
        </View>
        <View style={styles.title}>
          <Text>{this.state.title.toUpperCase()}</Text>
        </View>
        <View style={styles.receipt}>
          {this.state.body.map((row, index) => (
            <View key={index} style={styles.row}>
              {Array.isArray(row) ? (
                row.length > 1 ? (
                  <View style={styles.double}>
                    <View style={styles.left}>
                      <Text>{row[0].toString().padEnd(15, " ")}</Text>
                    </View>
                    <View style={styles.right}>
                      <Text>
                        {typeof row[1] !== "number"
                          ? row[0] === "TGL TRANSAKSI"
                            ? moment(row[1]).format(":   DDMMMYYYY HH:mm:ss").toUpperCase()
                            : ":   " + row[1]
                          : ":   Rp." + fnumber(row[1]).padStart(20, " ")}
                      </Text>
                    </View>
                  </View>
                ) : (
                  <View style={styles.single}>
                    <Text>{row[0]}</Text>
                  </View>
                )
              ) : (
                <View style={styles.single}>
                  <Text>{row}</Text>
                </View>
              )}
            </View>
          ))}
        </View>
      </View>
    );
  }
  getTwoColumns(data) {
    const { title, middlePage, rightPage } = receipt(data);

    // console.log({ title, middlePage, rightPage });
    const styles = StyleSheet.create(st.TwoColumns);
    return (
      <View style={styles.body} key={data.salesId}>
        <View style={styles.title}>
          <Text>{title.trim().toUpperCase()}</Text>
        </View>
        <View style={styles.receipt}>
          {middlePage.map((row, index) => (
            <View style={styles.row} key={index}>
              {row[1] ? (
                <View style={styles.left}>
                  <View style={styles.caption}>
                    <Text>{row.length > 1 ? row[0] : ""}</Text>
                  </View>
                  <View style={styles.value}>
                    <Text>
                      {row.length > 1
                        ? `: ${
                            row[0] === "TANGGAL"
                              ? typeof row[1] === "object"
                                ? moment(row[1]).format("DD/MM/YY HH:mm")
                                : row[1]
                              : row[1]
                          }`
                        : `  ${row[0]}`}
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={styles.footer}>
                  <View style={styles.footer}>
                    <Text>{row[0]}</Text>
                  </View>
                </View>
              )}

              {rightPage[index][0] !== "" && (
                <View style={styles.right}>
                  <View style={rightPage[index].length > 1 ? styles.caption : styles.token}>
                    <Text>{rightPage[index][0]}</Text>
                  </View>
                  <View style={styles.value}>
                    <Text>
                      {rightPage[index][1]
                        ? typeof rightPage[index][1] !== "number"
                          ? `: ${rightPage[index][1]}`
                          : `: Rp.${fnumber(rightPage[index][1]).padStart(11, " ")}`
                        : rightPage[index][1] !== undefined
                        ? ":"
                        : ""}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          ))}
        </View>
      </View>
    );
  }
  getThreeColumns(data) {
    const { title, leftPage, middlePage, rightPage, payload } = receipt(data);
    const styles = StyleSheet.create(st.ThreeColumns);

    return (
      <View style={styles.body} key={data.salesId}>
        <View style={styles.title}>
          <Text>{title.trim().toUpperCase()}</Text>
        </View>
        <View style={styles.receipt}>
          {middlePage.map((row, index) => (
            <View style={styles.row} key={index}>
              {index < 8 ? (
                <View style={styles.left}>
                  <View style={styles.captionLeft}>
                    <Text>{leftPage[index].length > 1 ? leftPage[index][0] : ""}</Text>
                  </View>
                  <View style={styles.valueLeft}>
                    <Text>
                      {leftPage[index].length > 1
                        ? `: ${
                            typeof leftPage[index][1] !== "number"
                              ? leftPage[index][1]
                              : `Rp.${fnumber(leftPage[index][1]).padStart(11, " ")}`
                          }`
                        : // `: ${leftPage[index][1]}`
                          `  ${leftPage[index][0]}`}
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={styles.footerLeft}>
                  <View style={styles.captionLeft}>
                    <Text>{""}</Text>
                  </View>
                  <View style={styles.valueLeft}>
                    <Text>{""}</Text>
                  </View>
                </View>
              )}
              {index < 6 ? (
                <View style={styles.middle}>
                  <View style={styles.caption}>
                    <Text>{row.length > 1 ? row[0] : ""}</Text>
                  </View>
                  <View style={styles.value}>
                    <Text>
                      {row.length > 1
                        ? row[0] !== ""
                          ? `: ${
                              row[0] === "TANGGAL"
                                ? typeof row[1] === "object"
                                  ? moment(row[1]).format("DD/MM/YY HH:mm")
                                  : row[1]
                                : row[1]
                            }`
                          : `  ${row[0]}`
                        : ""}
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={styles.footerRight}>
                  <View style={styles.footerRight}>
                    <Text>{row[0]}</Text>
                  </View>
                </View>
              )}
              {index < 6 && (
                <View style={styles.right}>
                  <View
                    style={
                      rightPage[index].length > 1
                        ? { ...styles.caption, width: "30%" }
                        : styles.token
                    }
                  >
                    <Text>{rightPage[index][0]}</Text>
                  </View>
                  <View style={{ ...styles.value, width: "70%" }}>
                    <Text>
                      {rightPage[index][1] && rightPage[index].length > 1
                        ? `: ${
                            typeof rightPage[index][1] !== "number"
                              ? rightPage[index][1]
                              : `Rp.${fnumber(rightPage[index][1]).padStart(11, " ")}`
                          }`
                        : rightPage[index][1] !== undefined
                        ? ":"
                        : ""}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          ))}
        </View>
      </View>
    );
  }
}
export default PdfReceipt;
