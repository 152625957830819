import React from "react";
import { Navigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Zoom from "@mui/material/Zoom";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";

import { styled } from "@mui/material/styles";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";

import Loaded from "contents/components/Loaded";
import GSearchActions from "contents/components/GSearchActions";
import CustomTable from "contents/components/CustomTable";
import Pagination from "contents/components/Pagination";

import secureStorage from "libraries/secureStorage";
import { fnumber } from "helper/Helper";

import _ from "lodash";
import axios from "axios";
import Config from "config";
import moment from "moment";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#e8f4f7",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#cbe3ee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Transition = React.forwardRef((props, ref) => {
  return <Zoom in={props.in}>{props.children}</Zoom>;
});
class BillHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 15,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      rows: [],
      details: [],
      //<<pagin states

      keyword: "",

      modalMode: "",
      modalShow: false,
      modalTitle: "",

      modalName: "",
      modalAmount: "",

      selectedBill: "",
      selectedParentBill: "",

      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),

      parentDue: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadBill();
    const now = moment();
    const duedate = moment().subtract(1, "minutes");
  }

  toggleModal = () => {
    this.setState({ modalShow: !this.state.modalShow });
  };

  loadBill = (params) => {
    const payload = {
      startDate: params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      // pagination payload>>
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
      // <<pagination payload
      parentId: params && params.parentId,
      isCharger: this.props.isCharger,
    };
    this.setState({ isLoading: true });
    axios
      .post(`${Config.EndPoint}/bill/history`, payload)
      .then((res) => {
        const rows = !params
          ? { rows: res.data.bills }
          : !params.parentId
            ? { rows: res.data.bills }
            : {};
        const details = params && params.parentId ? { details: res.data.bills } : {};
        this.setState({
          ...rows,
          ...details,
          ...payload,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          isLoading: false,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  handleSubmit = () => {
    this.loadBill();
  };

  renderStatus = (bill) => {
    const { t } = this.props;
    let color;
    switch (bill.statusId) {
      case 1:
        color = "secondary";
        break;
      case 2:
        color = "primary";
        break;
      case 3:
        color = "info";
        break;
      case 4:
        color = "success";
        break;
      case 5:
        color = "warning";
        break;
      case 6:
        color = "error";
        break;
      default:
        color = "info";
    }

    return <MKBadge badgeContent={t(bill.BillStatus.name)} color={color} />;
  };

  getDueDate = (bill, splitted) => {
    const user = secureStorage.getItem("account");
    const createdAt = splitted ? this.state.parentDue : bill.createdAt;
    let dueDate;
    if (bill) {
      if (moment(createdAt).weekday() === 6)
        dueDate = moment().startOf("day").add(2, "days").add(11, "hours").add(30, "minutes");
      else if (moment(createdAt).weekday() === 0)
        dueDate = moment().startOf("day").add(1, "days").add(11, "hours").add(30, "minutes");
      else if (bill.cycle === 2) dueDate = moment(createdAt).add(3, "hours");
      else dueDate = moment(createdAt).startOf("day").add(11, "hours").add(30, "minutes");
      if (user.userTypeId === 2 && this.props.location.pathname === "/PaymentHistory")
        dueDate = dueDate.subtract(30, "minutes");
      return dueDate.format("LLLL");
    }
  };

  render() {
    // const { t } = this.props;
    const user = secureStorage.getItem("account");
    if (!user) return <Navigate to="/" />;

    return (
      <>
        <MKBox wdith="100%" component="section" py={12} mx={2}>
          {/* <Loaded open={this.state.isLoading} /> */}

          <Card sx={{ padding: "25px", marginTop: "10px" }}>
            <Grid container justifyContent="center">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xl={2} lg={2} md={2} sm={12} xs={12} mb={{ xs: 1, md: 0 }}>
                    <MKBox
                      color="rgba(0,0,0,.6)"
                      bgColor="#e8e8e8"
                      borderRadius="lg"
                      shadow="lg"
                      textAlign="center"
                      justifyContent="center"
                      opacity={1}
                      p={1}
                    >
                      {user.userTypeId !== 1 ? "Riwayat Tagihan" : "Riwayat Pembayaran"}
                    </MKBox>
                  </Grid>
                  <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GSearchActions
                      onSubmit={this.loadBill}
                      handleChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
                <CustomTable
                  togglePreview
                  isLoading={this.state.isLoading}
                  tableHead={[
                    "#",
                    "BID",
                    "UID",
                    "NAMA",
                    "TANGGAL JATUH TEMPO",
                    "BANK",
                    "JUMLAH TAGIHAN",
                    "JUMLAH TERBAYAR",
                    "STATUS",
                    user.userTypeId === 1 ||
                      (user.userTypeId === 2 && this.props.location.pathname === "/PaymentHistory")
                      ? "AKUN"
                      : null,
                  ]}
                  tableData={this.state.rows.map((bill, index) => [
                    index +
                    1 +
                    (isNaN(this.state.rowsPerPage)
                      ? 0
                      : (this.state.currentPage - 1) * this.state.rowsPerPage),
                    bill.id.toString().padStart(6, 0),
                    bill.BillCharged.id.toString().padStart(6, 0),
                    bill.BillCharged.name,
                    this.getDueDate(bill),
                    bill.Bank
                      ? bill.Bank.name
                      : bill.remark === "Splitted" && (
                        <MKButton
                          circular
                          size="small"
                          onClick={() => {
                            this.setState({ modalShow: true, parentDue: bill.createdAt });
                            this.loadBill({ parentId: bill.id });
                          }}
                          color="primary"
                        >
                          {"Terbagi"}
                        </MKButton>
                      ),
                    fnumber(bill.amount),
                    fnumber(bill.paidAmount ? bill.paidAmount : 0),
                    this.renderStatus(bill),
                    user.userTypeId === 1 ||
                      (user.userTypeId === 2 && this.props.location.pathname === "/PaymentHistory")
                      ? bill.LoginUser
                        ? bill.LoginUser.name
                        : "-"
                      : null,
                  ])}
                  fontWeightCells={["bold", "bold"]}
                  fontWeightForCells={[6, 7]}
                  textAlignCells={["right", "right", "center"]}
                  textAlignForCells={[6, 7, 8]}
                  textAlignHeaders={["left", "right", "right", "center"]}
                  textAlignForHeaders={[4, 6, 7, 8]}
                />
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
                <MKBox width="100%">
                  <Pagination
                    totalButton={3}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadBill({ currentPage });
                      }
                    }}
                    rowsPerPage={[5, 15, 25, "All"]}
                    defaultRowsPerPage={15}
                    onChangeRowsPerPage={(value) => {
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadBill({ rowsPerPage: value, currentPage: 1 });
                    }}
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Card>
        </MKBox>

        <Modal
          open={this.state.modalShow}
          onClose={this.toggleModal}
          sx={{ display: "grid", placeItems: "center" }}
        >
          <Transition in={this.state.modalShow}>
            <MKBox
              position="relative"
              width="1050px"
              height="auto"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
              style={{ outline: "none" }}
            >
              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Detail Tagihan</MKTypography>
                <CloseIcon
                  fontSize="medium"
                  sx={{ cursor: "pointer" }}
                  onClick={this.toggleModal}
                />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={3} style={{ textAlign: "center" }}>
                <TableContainer>
                  <Table stickyHeader aria-label="customized sticky table">
                    <MKBox component="thead">
                      <TableRow>
                        {this.renderColumn("#")}
                        {this.renderColumn("BID")}
                        {this.renderColumn("UID")}
                        {this.renderColumn("NAMA")}
                        {this.renderColumn("TANGGAL JATUH TEMPO")}
                        {this.renderColumn("BANK")}
                        {this.renderColumn("JUMLAH TAGIHAN")}
                        {this.renderColumn("JUMLAH TERBAYAR")}
                        {this.renderColumn("STATUS")}
                      </TableRow>
                    </MKBox>
                    <TableBody>
                      {this.state.details.map((bill, index) => (
                        <StyledTableRow hover tabIndex={-1} key={`row-${index}`}>
                          {this.rowBody(
                            index +
                            1 +
                            (isNaN(this.state.rowsPerPage)
                              ? 0
                              : (this.state.currentPage - 1) * this.state.rowsPerPage)
                          )}
                          {this.rowBody(bill.id.toString().padStart(6, 0))}
                          {this.rowBody(bill.BillCharged.id.toString().padStart(6, 0))}
                          {this.rowBody(bill.BillCharged.name)}
                          {this.rowBody(this.getDueDate(bill, true))}
                          {this.rowBody(bill.Bank ? bill.Bank.name : "")}
                          {this.rowBody(fnumber(bill.amount), { textAlign: "right" })}
                          {this.rowBody(fnumber(bill.paidAmount ? bill.paidAmount : 0), {
                            textAlign: "right",
                          })}
                          {this.rowBody(this.renderStatus(bill))}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="flex-end" p={1.5}>
                <MKButton variant="gradient" color="error" onClick={this.toggleModal}>
                  Close
                </MKButton>
              </MKBox>
            </MKBox>
          </Transition>
        </Modal>
      </>
    );
  }

  renderColumn = (column, option) => {
    return (
      <MKBox
        component="th"
        p={2}
        sx={({ typography: { size, fontWeightBold }, borders: { borderWidth, borderColor } }) => ({
          fontSize: "12px",
          fontWeight: "bold",
          borderBottom: `${borderWidth[1]} solid ${borderColor}`,
          ...option,
        })}
      >
        {column}
      </MKBox>
    );
  };

  rowBody = (value, options) => {
    return (
      <MKBox
        component="td"
        sx={{
          fontSize: "12px",
          padding: "8px 8px!important",
          ...options,
        }}
      >
        {value}
      </MKBox>
    );
  };
}

export default withTranslation()(BillHistory);
