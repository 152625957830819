import React from "react";
import { withTranslation } from "react-i18next";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { Autocomplete, TextField } from "@mui/material";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

import Loaded from "contents/components/Loaded";
import GSearchActions from "contents/components/GSearchActions";
import CustomTable from "contents/components/CustomTable";
import Pagination from "contents/components/Pagination";
import Receipt from "contents/components/Receipt";

import secureStorage from "libraries/secureStorage";

import _ from "lodash";
import axios from "axios";
import Config from "config";
import moment from "moment";

import { SalesType, DirectUserType, EndUsers } from "helper/userLibs";

class ReportTransactionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      extractedRows: [],
      header: [
        "#",
        "TRID",
        "UID",
        "NAMA",
        "PRODUK",
        "TANGGAL",
        "NOMINAL",
        "BLTH",
        "ADMIN",
        "PENDAPATAN",
        "KETERANGAN",
        "IDPEL",
      ],
      footer: [],

      total: [],

      selectedEndUsers: [],
      endUser: [],

      selectedCas: [],
      ca: [],

      selectedSubcas: [],
      subca: [],

      selectedSalesDms: [],
      salesDm: [],

      selectedMitraDms: [],
      mitraDm: [],

      selectedMitraTls: [],
      mitraTl: [],

      selectedProducts: [],
      products: [],

      selectedCategories: [],
      categories: [],

      selectedPartners: [],
      partners: [],

      salesType: 0,

      user: secureStorage.getItem("account"),

      rowsPerPage: 15,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      currentPageData: [],

      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),

      userTypes: [],

      submitDownloadDisabled: false,
      isLoading: false,

      keyword: "",

      receiptTitle: "",
      receiptBody: "",
    };

    // this.exportTransaction = React.createRef();
    this.loadTransaction = this.loadTransaction.bind(this);
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    const user = secureStorage.getItem("account");
    const salesType = user && this.initSalesType();

    this.loadProduct();
    this.loadCategory();
    // user && user.userTypeId === 1 && this.loadPartner();
    this.initUserTypes({ salesType });
  }

  initSalesType() {
    // ['DM 2 Levels','TL 3 Levels','H2H DM','H2H TL',"DP 3 Levels","H2H RF","FD 5 Levels","FT 5 Levels"]
    const user = secureStorage.getItem("account");
    let salesType = 0;
    if ([1, 6].includes(user.userTypeId)) salesType = 0;
    else if ([2, 3].includes(user.userTypeId)) salesType = 1;
    else if ([5].includes(user.userTypeId)) salesType = 2;
    else if ([8].includes(user.userTypeId)) salesType = 2;
    else if ([9, 10].includes(user.userTypeId)) salesType = 4;
    else if ([12].includes(user.userTypeId)) salesType = 5;
    else if ([14, 15, 16, 17].includes(user.userTypeId)) salesType = 6;
    else if ([19, 20, 21, 22].includes(user.userTypeId)) salesType = 7;
    else if ([24, 25].includes(user.userTypeId)) salesType = 8;
    else if ([26, 27, 28].includes(user.userTypeId)) salesType = 9;
    this.setState({ salesType });

    return salesType;
  }

  loadProduct() {
    axios
      .post(`${Config.EndPoint}/transaction/product`)
      .then((res) => {
        const products = _.uniqBy(
          res.data.map((item) => ({
            name: item.name,
            id: item.id,
            categoryId: item.ProductCategoryId,
          })),
          ({ id }) => id
        );
        this.setState({ products: products });
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  loadCategory() {
    axios
      .post(`${Config.EndPoint}/transaction/category`)
      .then((res) => {
        this.setState({ categories: res.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  initUserTypes({ salesType }) {
    const user = secureStorage.getItem("account");
    axios
      .post(`${Config.EndPoint}/transaction/usertypes`)
      .then((res) => {
        const userTypes = res.data;
        this.setState({ userTypes });
        this.loadUserDropdown({
          salesType,
          parents: [user],
          userTypes,
          parentTypeId: user.userTypeId,
          init: true,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  loadUserDropdown(params) {
    const user = secureStorage.getItem("acoount");
    const userTypes = params.userTypes ? params.userTypes : this.state.userTypes;
    const salesType = params.salesType ? params.salesType : this.state.salesType;
    const payload = {
      salesType,
      parents: params.parents && params.parents,
    };

    axios
      .post(`${Config.EndPoint}/transaction/user`, payload)
      .then((res) => {
        let userTypeIdx = params.parentTypeId;
        if (params.init && user.userTypeId === 1) userTypeIdx = DirectUserType[salesType] - 1;
        this.setState({ [userTypes[userTypeIdx].name]: res.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  loadTransaction(params) {
    const user = secureStorage.getItem("account");
    const userTypes = this.state.userTypes;
    const users = _.flattenDeep(
      userTypes
        .filter((item) => item.id !== 1 && this.state[item.id])
        .map((item) => this.state[item.id])
    );
    this.setState({ isLoading: true });
    const payload = {
      startDate: params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      utcOffset: moment().utcOffset(),
      parents: users.length > 0 ? users : [user],
      products:
        this.state.selectedProducts &&
        this.state.selectedProducts.length > 0 &&
        this.state.selectedProducts,
      categories:
        this.state.selectedCategories &&
        this.state.selectedCategories.length > 0 &&
        this.state.selectedCategories,
      partners:
        this.state.selectedPartners &&
        this.state.selectedPartners.length > 0 &&
        this.state.selectedPartners,
      salesType: params && params.salesType ? params.salesType : this.state.salesType,
      // pagination payload>>
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,

      keyword: params && params.keyword ? params.keyword : this.state.keyword,
    };
    const { t } = this.props;
    axios
      .post(`${Config.EndPoint}/transaction`, payload)
      .then((res) => {
        const { t } = this.props;
        const data = res.data;
        let header = data.extractedRows.header;
        header = header.slice(0, header.length - 1).map((item, i) => {
          console.log(item, item.toLowerCase(), t(item.toLowerCase()));

          return t(item.toLowerCase());
        });
        console.log({ header });
        const footer = data.extractedRows.footer;
        this.setState({
          extractedRows: data.extractedRows.data,
          // total:res.data.extractedRows.footer,
          total: footer.slice(0, footer.length - 1),
          totalPages: data.totalPages,
          totalData: data.totalData,
          startDate: payload.startDate,
          endDate: payload.endDate,
          header,
          isLoading: false,
        });

        // this.exportTransaction.current.updateDataset(res.data.extractedRows.dataset);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  handleDownload = async () => {
    this.setState({ submitDownloadDisabled: true });
    try {
      const user = secureStorage.getItem("account");
      const userTypes = this.state.userTypes;
      const users = _.flattenDeep(
        userTypes
          .filter((item) => item.id !== 1 && this.state[item.id])
          .map((item) => this.state[item.id])
      );

      const payload = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        utcOffset: moment().utcOffset(),
        parents: users.length > 0 ? users : [user],

        products:
          this.state.selectedProducts &&
          this.state.selectedProducts.length > 0 &&
          this.state.selectedProducts,
        categories:
          this.state.selectedCategories &&
          this.state.selectedCategories.length > 0 &&
          this.state.selectedCategories,
        partners:
          this.state.selectedPartners &&
          this.state.selectedPartners.length > 0 &&
          this.state.selectedPartners,
        salesType: this.state.salesType,
        keyword: this.state.keyword,
      };

      const endpoint = `${Config.EndPoint}/transaction/download`;
      const response = await axios.post(endpoint, payload, {
        responseType: "blob", // Untuk memastikan response adalah blob
        // timeout: 30000, // 30seconds
      });

      if (response.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        const a = document.createElement("a");
        a.href = url;

        a.setAttribute(
          "download",
          `Transaksi ${moment(this.state.startDate)
            .utcOffset("+07:00")
            .format("DD-MM-YYYY")} <-> ${moment(this.state.endDate)
            .utcOffset("+07:00")
            .format("DD-MM-YYYY")}`
        );
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.setState({ submitDownloadDisabled: false });
      } else {
        this.setState(
          {
            submitDownloadDisabled: false,
            receiptTitle: "Failed",
            receiptBody: [["Network failed"]],
          },
          () => this.modalRef.current.setShow(true)
        );
      }
    } catch (err) {
      console.log(err.message);
      this.setState(
        {
          submitDownloadDisabled: false,
          receiptTitle: "Failed",
          receiptBody: [[err.message ? err.message : "Network failed"]],
        },
        () => this.modalRef.current.setShow(true)
      );
    }
  };

  render() {
    // const { t } = this.props;
    return (
      <>
        {/* Model */}
        <Receipt
          ref={this.modalRef}
          title={this.state.receiptTitle}
          body={this.state.receiptBody}
        />
        <MKBox wdith="100%" component="section" py={12} mx={2}>
          {/* <Loaded open={this.state.isLoading} /> */}
          {/* <MKBox p={3} coloredShadow="secondary" borderRadius="5px" mb={3} bgColor="white" shadow="xl"> */}
          <Card sx={{ padding: "25px", marginTop: "10px" }}>
            <Grid container justifyContent="center">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xl={2} lg={2} md={2} sm={12} xs={12} mb={{ xs: 1, md: 0 }}>
                    <MKBox
                      color="rgba(0,0,0,.6)"
                      bgColor="#e8e8e8"
                      borderRadius="lg"
                      shadow="lg"
                      textAlign="center"
                      justifyContent="center"
                      opacity={1}
                      p={1}
                    >
                      Laporan Detail
                    </MKBox>
                  </Grid>
                  <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GSearchActions
                      onSubmit={this.loadTransaction}
                      handleChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  {/* Category */}
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12} px={1}>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      fullWidth
                      sx={{ marginTop: 1 }}
                      options={this.state.categories
                        .map((item, index) => ({
                          key: item.id,
                          text: item.name,
                          value: item.id,
                        }))
                        .filter((item, index) => {
                          return !this.state.selectedCategories.includes(item.value);
                        })}
                      // renderOption={(rest, item) => {
                      //   rest.key = item.key;
                      //   return (
                      //     <span
                      //       style={{
                      //         color: "#000",
                      //         opacity: 1,
                      //         fontWeight: "bold",
                      //       }}
                      //       {...rest}
                      //     >
                      //       {item.text}
                      //     </span>
                      //   );
                      // }}
                      getOptionLabel={(option) => {
                        if (!option) return;
                        if (option.text === undefined) {
                          const item = this.state.categories.find((c) => c.id === option);
                          return item.name;
                        } else {
                          return option.text;
                        }
                      }}
                      value={this.state.selectedCategories}
                      onChange={(e, newValue) => {
                        let selected = [...this.state.selectedCategories];
                        if (newValue.length > selected.length) {
                          const item = newValue[newValue.length - 1];
                          selected = [...selected, item.value];
                        } else {
                          selected = newValue;
                        }
                        this.setState({
                          selectedCategories: selected,
                          selectedProducts: [],
                        });
                      }}
                      renderInput={(params) => (
                        <MKInput {...params} label="KATEGORI" variant="standard" fullWidth />
                      )}
                    />
                  </Grid>
                  {/* Product */}
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12} px={1}>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      fullWidth
                      sx={{ marginTop: 1 }}
                      options={this.state.products
                        .map((item, index) => ({
                          key: item.id,
                          text: item.name,
                          value: item.id,
                          categoryId: item.categoryId,
                        }))
                        .filter((item, index) => {
                          if (this.state.selectedCategories.length > 0) {
                            return this.state.selectedCategories.includes(item.categoryId);
                          } else return item;
                        })
                        .filter((item, index) => {
                          return !this.state.selectedProducts.includes(item.value);
                        })}
                      getOptionLabel={(option) => {
                        if (!option) return;
                        if (option.text === undefined) {
                          const item = this.state.products.find((p) => p.id === option);
                          return item.name;
                        } else {
                          return option.text;
                        }
                      }}
                      value={this.state.selectedProducts}
                      onChange={(e, newValue) => {
                        let selected = [...this.state.selectedProducts];
                        if (newValue.length > selected.length) {
                          const item = newValue[newValue.length - 1];
                          selected = [...selected, item.value];
                        } else {
                          selected = newValue;
                        }

                        this.setState({
                          selectedProducts: selected,
                          // selectedCategories:[]
                        });
                      }}
                      renderInput={(params) => (
                        <MKInput {...params} label="PRODUK" variant="standard" fullWidth />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* Search Keyword */}
                <Grid container justifyContent="center" mt={2}>
                  <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                    <MKInput
                      value={this.state.keyword}
                      fullWidth
                      label="SEARCH"
                      size="small"
                      placeholder="IDPEL / TRID"
                      type="tel"
                      onChange={(e) => this.setState({ keyword: e.target.value })}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          this.setState({ keyword: e.target.value });
                          this.loadTransaction({ keyword: e.target.value });
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between">
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                  <MKBox
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <MKTypography variant="body" fontWeight="bold">
                      {this.state.totalData}
                    </MKTypography>
                    <MKTypography variant="body" fontWeight="bold" textTransform="uppercase">
                      Record(s)
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <MKBox width="100%" display="flex" justifyContent="flex-end">
                    <MKButton
                      variant="outlined"
                      color="info"
                      disabled={this.state.submitDownloadDisabled}
                      onClick={this.handleDownload}
                      size="medium"
                    >
                      <Icon sx={{ mr: 1 }}>download</Icon>
                      Download File
                    </MKButton>
                  </MKBox>
                </Grid>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
                <CustomTable
                  togglePreview
                  isLoading={this.state.isLoading}
                  tableHead={this.state.header}
                  tableData={this.state.extractedRows}
                  tableFoot={this.state.total}
                  fontWeightCells={[..._.times(this.state.header.length - 1 - 6, (n) => "bold")]}
                  fontWeightForCells={[..._.times(this.state.header.length - 1 - 6, (n) => n + 6)]}
                  textAlignHeaders={[
                    "center",
                    ..._.times(this.state.header.length - 1 - 6, (n) => "right"),
                  ]}
                  textAlignForHeaders={[
                    0,
                    ..._.times(this.state.header.length - 1 - 6, (n) => n + 6),
                  ]}
                  textAlignCells={[
                    "center",
                    ..._.times(this.state.header.length - 1 - 6, (n) => "right"),
                  ]}
                  textAlignForCells={[
                    0,
                    ..._.times(this.state.header.length - 1 - 6, (n) => n + 6),
                  ]}
                  textAlignFooters={[
                    "center",
                    ..._.times(this.state.header.length - 2 - 5, (n) => "right"),
                  ]}
                  textAlignForFooters={[
                    0,
                    ..._.times(this.state.header.length - 2 - 5, (n) => n + 5),
                  ]}
                />
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
                <MKBox width="100%">
                  <Pagination
                    totalButton={7}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadTransaction({ currentPage });
                      }
                    }}
                    rowsPerPage={[5, 15, 25, "All"]}
                    defaultRowsPerPage={15}
                    onChangeRowsPerPage={(value) => {
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadTransaction({ rowsPerPage: value, currentPage: 1 });
                    }}
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Card>
        </MKBox>
      </>
    );
  }
}

export default withTranslation()(ReportTransactionDetail);
