import { useState, useEffect, useRef } from "react";
// react-router-dom components
import { useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import axios from "axios";
import Config from "config";
import secureStorage from "libraries/secureStorage";
import ReCAPTCHA from "react-google-recaptcha";
import * as EmailValidator from "email-validator";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "&& .MuiInputBase-input": {
      opacity: 1,
      "line-height": 1.42857,
      "font-size": "30px",
      "text-align": "center",
      "font-weight": 400,
      "max-width": "150px",
    },
    "&&	.MuiInputBase-formControl": {
      "border-bottom": "1px solid #333 !important",
    },
  },
});

function Verification() {
  const [count, setCount] = useState(0);
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [verCode, setVerCode] = useState("");
  const [message, setMessage] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [inputChange, setInputChange] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [inputTimeout, setInputTimeout] = useState("");

  const [captchaRes, setCaptchaRes] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [captchaKey, setCaptchaKey] = useState("");

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendEmailDisabled, setResendEmailDisabled] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [resetPass, setResetPass] = useState(secureStorage.getItem("resetPass"));

  const navigate = useNavigate();
  const reCaptcha = useRef();

  useEffect(() => {
    const token = secureStorage.getItem("token");
    const account = secureStorage.getItem("account");
    const _resetPass = secureStorage.getItem("resetPass");
    // console.log({ token, account });
    // if (!account) navigate("/login");

    if (!token) {
      if (resetPass) {
        const { email, phone, user } = _resetPass;
        setEmail(email);
        setPhone(phone);
        setUser(user);
        secureStorage.removeItem("resetPass");
      } else if (account) {
        const { email, phone } = account;
        setEmail(email);
        setPhone(phone);
        setUser(account);
      }
    } else {
      navigate("/");
    }

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  // useEffect(()=>{
  // 	console.log({user, resetPass})
  // 	// if (!user && !resetPass)
  // 	// 	navigate('/login')
  // }, [resetPass, user])

  useEffect(() => {
    if (confirmCode) {
      navigate(`/reset/${confirmCode}`);
    }
  }, [confirmCode]);

  const verify = () => {
    if (verCode.length === 6) {
      const dataUser = resetPass ? user : secureStorage.getItem("account");
      const payload = {
        user: dataUser,
        verCode,
        captchaRes: resetPass && captchaRes ? captchaRes : false,
        resetPass: resetPass ? true : false,
        device: !resetPass && secureStorage.getItem("device"),
      };

      axios
        .post(`${Config.EndPoint}/user/verification`, payload)
        .then((res) => {
          if (!resetPass) {
            dataUser.isVerified = true;
            secureStorage.setItem("user", dataUser);
            secureStorage.setItem("token", res.data.token);
            axios.defaults.headers.Authorization = "Bearer " + res.data.token;
            setMessage("");
            navigate("/");
          } else {
            setConfirmCode(res.data.confirmCode);
            navigate(`/reset/${res.data.confirmCode}`);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (count === 3) secureStorage.removeItem("user");
            if (err.response.status === 400) {
              setCount((prevCount) => prevCount + 1);
            }
            // reCaptcha && reCaptcha.current.reset();
            setMessage(err.response.data);
            setSubmitDisabled(true);
          } else {
            setMessage("Koneksi jaringan bermasalah");
            setSubmitDisabled(true);
          }
        });
    }
  };

  const changeData = (e) => {
    const user = secureStorage.getItem("account");
    const payload = {
      user,
      email: modalTitle === "Email" && inputChange,
      phone: modalTitle !== "Email" && inputChange,
    };
    axios
      .post(Config.EndPoint + "/user/verification/changedata", payload)
      .then((res) => {
        const data = res.data;
        const email = modalTitle === "Email" ? { email: inputChange } : {};
        const phone = modalTitle !== "Email" ? { phone: inputChange } : {};
        const newUser = {
          ...user,
          ...email,
          ...phone,
        };
        secureStorage.setItem("account", newUser);
        setResendDisabled(true);
        setErrMessage("");
        setSaveDisabled(true);
        setOpenModal(false);
        if (modalTitle === "Email") setEmail(inputChange);
        else setPhone(inputChange);
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  const classes = useStyles();
  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Container>
          <MKBox component="section" width="100%" py={3}>
            <Card>
              <MKBox p={3}>
                <Grid container item xs={12} md={12}>
                  <MKBox width="100%" display="flex" alignItems="center" flexDirection="column">
                    {resetPass ? (
                      <MKTypography variant="body2" fontWeight="regular">
                        Kode OTP telah dikirim via {email ? "email" : "SMS"} ke{" "}
                        {email ? email : phone}
                      </MKTypography>
                    ) : (
                      <MKTypography variant="body2" fontWeight="regular">
                        Kode OTP telah dikirim via email ke <b>{email}</b>
                      </MKTypography>
                    )}
                    <MKTypography variant="h5">Masukkan kode verifikasi OTP</MKTypography>
                    <MKBox mt={3}>
                      <MKInput
                        id="verCode"
                        type="tel"
                        variant="standard"
                        className={classes.root}
                        sx={{ borderBottom: "1px", borderColorBottom: "#000" }}
                        onChange={(e) => {
                          let val = e.target.value;
                          if (val.length > 6) val = val.substring(0, val.length - 1);
                          !isNaN(val) && setVerCode(val);
                          if (resetPass)
                            val.length === 6 &&
                              !isNaN(val) &&
                              captchaRes &&
                              setSubmitDisabled(false);
                          else val.length === 6 && !isNaN(val) && setSubmitDisabled(false);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (resetPass) captchaRes && verify();
                            else verify();
                          }
                        }}
                        value={verCode}
                      />
                    </MKBox>
                    {message ? (
                      <MKTypography variant="body2" color="error">
                        {message}
                      </MKTypography>
                    ) : null}
                    {resetPass && (
                      <MKBox mt={2}>
                        <ReCAPTCHA
                          ref={reCaptcha}
                          hl="id"
                          // sitekey={"6LeTB2YdAAAAAMRSlP7bunNp5XvSbZlBViFvKltd"}
                          sitekey={"6LfFQRwqAAAAAGicM3Ug910qi17m1XNUcQqkSwnY"}
                          // sitekey={
                          //   info && info.captchaKey
                          //     ? info.captchaKey
                          //     : "6LfFQRwqAAAAAGicM3Ug910qi17m1XNUcQqkSwnY"
                          // }
                          onChange={(e) => {
                            if (verCode.length !== 6) {
                              setCaptchaRes(e);
                              setSubmitDisabled(true);
                            } else if (isNaN(verCode)) {
                              setCaptchaRes(e);
                              setSubmitDisabled(true);
                            } else {
                              setCaptchaRes(e);
                              setSubmitDisabled(false);
                            }
                          }}
                        />
                      </MKBox>
                    )}

                    <MKBox mt={3}>
                      <MKButton
                        variant="gradient"
                        disabled={submitDisabled}
                        onClick={verify}
                        color="info"
                      >
                        submit
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </Grid>
                {!resetPass && (
                  <Grid container item xs={12} md={12} mt={10}>
                    <MKBox width="100%" display="flex" alignItems="center" flexDirection="column">
                      {/* BTN RESEND WITH EMAIL */}
                      <MKTypography variant="body2" fontWeight="regular">
                        {resendEmailDisabled
                          ? "Kode verifikasi OTP telah terkirim ulang. Silahkan cek Email."
                          : "Tekan tombol dibawah untuk mengirim ulang kode verifikasi OTP via Email"}
                      </MKTypography>
                      <MKBox my={1}>
                        <MKButton
                          size="small"
                          disabled={resendEmailDisabled}
                          onClick={() => {
                            setResendEmailDisabled(true);
                            axios
                              .post(Config.EndPoint + "/user/verification/resend", {
                                user,
                                email: true,
                              })
                              .then((res) => {
                                // console.log('[RESEND EMAIL] ', res.data)
                              })
                              .catch((err) => {});
                          }}
                          color="info"
                        >
                          kirim ulang otp email
                        </MKButton>
                      </MKBox>

                      {/* BTN RESEND WITH PHONE */}
                      <MKTypography variant="body2" fontWeight="regular">
                        {resendDisabled
                          ? "Kode verifikasi OTP telah terkirim. Silahkan cek SMS"
                          : `Tekan tombol dibawah untuk mengirim kode verifikasi OTP via SMS ke nomor ${phone}`}
                      </MKTypography>
                      <MKBox my={1}>
                        <MKButton
                          size="small"
                          disabled={resendDisabled}
                          onClick={() => {
                            setResendDisabled(true);
                            axios
                              .post(Config.EndPoint + "/user/verification/resend", {
                                user,
                                phone: true,
                              })
                              .then((res) => {
                                // console.log('[RESEND PHONE] ', res.data)
                              })
                              .catch((err) => {});
                          }}
                          color="info"
                        >
                          kirim otp sms
                        </MKButton>
                      </MKBox>
                    </MKBox>
                  </Grid>
                )}

                {user && !user.isVerified && (
                  <Grid container item xs={12} sm={2} md={12} mt={5}>
                    <MKBox width="100%" display="flex" alignItems="center" flexDirection="column">
                      <MKTypography variant="body2" fontWeight="regular">
                        Salah No HP atau Email ?
                      </MKTypography>
                      <MKBox my={1} display="flex" flexDirection="row">
                        <MKBox mx={1}>
                          <MKButton
                            size="small"
                            disabled={resendDisabled}
                            onClick={() => {
                              setOpenModal(true);
                              setModalTitle("No HP");
                              setInputChange(user && user.phone);
                            }}
                            color="info"
                          >
                            ubah no hp
                          </MKButton>
                        </MKBox>
                        <MKBox mx={1}>
                          <MKButton
                            size="small"
                            disabled={resendDisabled}
                            onClick={() => {
                              setOpenModal(true);
                              setModalTitle("Email");
                              setInputChange(user && user.email);
                            }}
                            color="info"
                          >
                            ubah email
                          </MKButton>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </Grid>
                )}
              </MKBox>
            </Card>
          </MKBox>
        </Container>
      </MKBox>

      <Modal
        disableAutoFocus={false}
        closeAfterTransition={true}
        sx={{ display: "grid", placeItems: "center" }}
        onClose={() => setOpenModal(false)}
        open={openModal}
        keepMounted
      >
        <Slide direction="down" in={openModal} timeout={200}>
          <Card>
            <MKBox width="400px" display="flex" flexDirection="column">
              <MKBox display="flex" alignItems="center" justifyContent="center" p={2}>
                <MKTypography variant="h5">Ubah {modalTitle}</MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox width="100%" p={2}>
                <MKInput
                  id="inputChange"
                  type={modalTitle === "Email" ? "email" : "tel"}
                  label={modalTitle}
                  variant="standard"
                  value={inputChange}
                  onChange={(e) => {
                    setSaveDisabled(false);
                    inputTimeout && clearTimeout(inputTimeout);
                    const val = e.target.value;
                    if (
                      !val ||
                      val === user.phone ||
                      val === user.email ||
                      (modalTitle === "Email" && !EmailValidator.validate(val)) ||
                      (modalTitle === "No HP" && isNaN(val)) ||
                      (modalTitle === "No HP" && val.indexOf("08") !== 0)
                    ) {
                      setSaveDisabled(true);
                    } else {
                      setInputTimeout(
                        setTimeout(() => {
                          const payload = {
                            username: null,
                            email: modalTitle === "Email" && val,
                            phone: modalTitle !== "Email" && val,
                          };
                          axios
                            .post(Config.EndPoint + "/user/availability", payload)
                            .then((res) => {
                              setErrMessage("");
                              setSaveDisabled(false);
                            })
                            .catch((err) => {
                              // console.log(err)
                              // saveDisabled = true
                              if (err.response) {
                                const data = err.response.data;
                                const target = Object.keys(data)[0];
                                setErrMessage(data[target]);
                                setSaveDisabled(true);
                              }
                            });
                        }),
                        1500
                      );
                    }
                    setInputChange(val);
                  }}
                  onKeyDown={(e) => e.key === "Enter" && changeData()}
                  fullWidth
                />
                <MKTypography variant="button" color="error">
                  {errMessage}
                </MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="flex-end" py={2} px={1.5}>
                <MKButton
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    setOpenModal(false);
                    setErrMessage("");
                  }}
                >
                  batal
                </MKButton>
                <MKBox ml={1}>
                  <MKButton
                    variant="gradient"
                    disabled={saveDisabled}
                    onClick={changeData}
                    color="info"
                  >
                    simpan
                  </MKButton>
                </MKBox>
              </MKBox>
            </MKBox>
          </Card>
        </Slide>
      </Modal>
    </>
  );
}

export default Verification;
