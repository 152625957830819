
import moment from 'moment'
import loader from './loader'
// import _ from 'lodash'
import secureStorage from 'libraries/secureStorage'
import getinfo from 'helper/getinfo'

const receipt = (payload) => {
  const printer = secureStorage.getItem('printer')
  const user = secureStorage.getItem('account')
  const info = secureStorage.getItem('domainInfo')
  const title = `${payload.title.padStart(105, ' ')}\r\n`
  const body = payload.body
  const date = body.date && body.date.value ? body.date.value : payload.createdAt

  const leftPage = [
    ['TGL', moment(date).format('DD/MM/YY HH:mm')],
    ['NOPEL', body.customerCode.value],
    [body.name.caption, body.name.value],
    ['PERIOD', body.period.value],
    ['JMLTAG', body.qty.value],
    ['RP TAG', body.amount.value],
    ['ADMIN', body.admin.value],
    ['BAYAR', body.total.value],
  ]

  for (let i = leftPage.length; i < 11; i++)
    leftPage.push([''])


  const middlePage = [
    ['TGL', moment(date).format('DD/MM/YY HH:mm')],
    ['NOPEL', body.customerCode.value],
    [body.name.caption, body.name.value],
    ['ITEM', body.item.value],
    [body.licenseNo.caption, body.licenseNo.value],

    ...getinfo(body.info.value),
  ]

  printer === 'dotmatrix' ? middlePage.push([`${String.fromCharCode(27)}4Loket : ${user.name}`]) : middlePage.push([`Loket : ${user.name}`])
  printer === 'dotmatrix' ? middlePage.push([`Powered by ${payload.appName}${String.fromCharCode(27)}5`]) : middlePage.push([`Powered by ${payload.appName}`])

  for (let i = middlePage.length; i < 11; i++)
    middlePage.push([''])

  const rightPage = [
    [body.referenceNo.caption, body.referenceNo.value.toString().substring(0, 15)],
    [body.qty.caption, body.qty.value],
    [body.amount.caption, body.amount.value],
    [body.admin.caption, body.admin.value],
    [body.total.caption, body.total.value],
  ]
  for (let i = rightPage.length; i < 11; i++)
    rightPage.push([''])

  if (printer === 'dotmatrix')
    return loader(title, leftPage, middlePage, rightPage, payload)
  else
    return { title, leftPage, middlePage, rightPage, payload }
}
export default receipt