import React, { createRef } from "react";
// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import modalReceipt from "helper/modalReceipt";
import { Zoom } from "@mui/material";

export default class Receipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.closeRef = createRef();
    this.addRef = createRef()
  }

  toggleModal = () => this.setShow(!this.state.show);

  setShow = (show) => {
    this.setState({ show });
  };
  setReceipt = (title, body) => { };

  render() {
    return (
      <Modal
        open={this.state.show}
        onClose={this.toggleModal}
        sx={{ display: "grid", placeItems: "center" }}
      >
        <Zoom
          in={this.state.show}
          onEntered={(e) => {
            if (this.props.isConfirm) this.addRef.current.focus()
            else this.closeRef.current.focus();
          }}
        >
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
            style={{ outline: "none" }}
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">{this.props.title}</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={this.toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox pl={5}>{modalReceipt(this.props.body)}</MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton
                ref={this.closeRef}
                variant="gradient"
                color="error"
                onClick={this.toggleModal}
              >
                tutup
              </MKButton>
              {this.props.isConfirm && (
                <MKButton
                  ref={this.addRef}
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    console.log('ADD ROWS')
                    this.props.onAdded(this.props.body)
                    this.toggleModal()
                  }}
                >
                  tambahkan
                </MKButton>
              )}

            </MKBox>
          </MKBox>
        </Zoom>
      </Modal>
    );
  }
}
