import { useEffect, useState, useRef } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAvatar from "components/MKAvatar";

import SimpleFooter from "examples/Footers/SimpleFooter";
// Authentication pages components

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import LOGO from "assets/images/Logo.png";

import axios from "axios";
import Config from "config";
import secureStorage from "libraries/secureStorage";
import * as EmailValidator from "email-validator";
import ReCAPTCHA from "react-google-recaptcha";
import Resource from "libraries/resource";

// import FingerprintJS from "@fingerprintjs/fingerprintjs";

function Login() {
  const [info, setInfo] = useState(null);
  const [pin, setPin] = useState("");
  const [wrongPin, setWrongPin] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [redirectToRef, setRedirectRef] = useState(false);
  const [device, setDevice] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resetErrMsg, setResetErrMsg] = useState("");
  const [captchaRes, setCaptchaRes] = useState("");
  const [captchaKey, setCaptchaKey] = useState("");
  const [logo, setLogo] = useState(
    window.location.hostname === "payfren.id" ? LOGO : "/android-icon-512x512.png"
  );

  const [rememberMe, setRememberMe] = useState(false);
  const [verification, setVerification] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [acResetPass, setAcResetPass] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [resetDisabled, setResetDisabled] = useState(true);

  const [authCode, setAuthCode] = useState("");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();
  const reCaptcha = useRef();

  useEffect(() => {
    const hostname = window.location.hostname;
    hostname !== "payfren.id" && initResources();
    getDeviceUnique();

    const account = secureStorage.getItem("account");
    if (account) navigate("/");
    else {
      secureStorage.removeItem("token");
      secureStorage.removeItem("account");
    }
  }, []);

  // useEffect(()=>{
  //   if (redirectToRef)
  //     navigate('/')
  //   if (verification)
  //     navigate('/verification', {state: {authCode}})
  // }, [verification, redirectToRef])

  const initResources = () => {
    Resource.checkUpdate();
    Resource.getInfo()
      .then((info) => {
        console.log({ info });
        setInfo(info);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getUID = async () => {
  //   const fp = await FingerprintJS.load();
  //   const result = await fp.get();
  //   return result.visitorId;
  // };

  const getDeviceUnique = async () => {
    const device = secureStorage.getItem("device");

    if (!device) {
      // const uid = await getUID();
      // secureStorage.setItem("device", uid);
      // setDevice(uid);
      axios
        .post(Config.EndPoint + "/user/device")
        .then((res) => {
          secureStorage.setItem("device", res.data.device);
          setDevice(res.data.device);
        })
        .catch((err) => {
          console.log(err);
        });
    } else setDevice(device);
  };

  // const initDotmatrix = () => {
  //   if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") === -1) {
  //     secureStorage.setItem("dotmatrix", "offline");
  //   } else {
  //     axios
  //       .post("http://localhost:4321/login")
  //       .then((res) => {
  //         secureStorage.setItem("dotmatrix", res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         secureStorage.setItem("dotmatrix", "offline");
  //       });
  //   }
  // };

  const handleLogin = (e) => {
    const payload = {
      username,
      password,
      device,
    };

    console.log({ payload });
    axios
      .post(`${Config.EndPoint}/user/login`, payload)
      .then((r) => {
        secureStorage.setItem("account", r.data.user);

        if (r.data.isLogged) {
          console.log({ token: r.data.token });
          secureStorage.setItem("token", r.data.token);
          axios.defaults.headers.Authorization = "Bearer " + r.data.token;
          setRedirectRef(true);
          navigate("/");
        } else {
          setVerification(true);
          setAuthCode(r.data.authCode);
          navigate("/verification", { state: { authCode } });
        }
        // initDotmatrix();
        // navigate('/')
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (pin) setWrongPin(true);
          else setErrorMessage(err.response.data);
        }
      });
  };

  const handleChange = (e) => {
    if (e.target.id === "username") setUsername(e.target.value);
    else setPassword(e.target.value);
  };

  const handleResetPass = () => {
    const phone = !isNaN(resetPass) && resetPass;
    const email = EmailValidator.validate(resetPass) && resetPass;

    if ((phone || email) && captchaRes) {
      axios
        .post(Config.EndPoint + "/user/resetpass", {
          phone,
          email,
          captchaRes,
          // acResetPass,
        })
        .then((res) => {
          secureStorage.setItem("resetPass", { phone, email, user: res.data.user });
          setVerification(true);
          setOpenModal(false);
          navigate("/verification", { state: { authCode } });
        })
        .catch((err) => {
          reCaptcha && reCaptcha.current.reset();
          // err.response && this.setState({resetErrMsg:err.response.data})
          if (err.response) {
            setResetErrMsg(err.response.data);
          }
        });
    }
  };

  const handleKeydown = (e) => {
    if (e.key === "Enter" && resetDisabled === false && captchaRes) handleResetPass();
  };

  return (
    <Layout image={bgImage}>
      <Card>
        <MKBox
          display="flex"
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          justifyContent="center"
        >
          {/* <MKAvatar src={logo} variant="rounded" size="xxl" alt="Logo" /> */}
          <MKBox
            component="img"
            src={window.location.hostname === "payfren.id" ? LOGO : "/android-icon-512x512.png"}
            sx={{
              height: 200,
              width: 200,
              borderRadius: "50%",
              maxHeight: { xs: 200, md: 200 },
              maxWidth: { xs: 200, md: 200 },
            }}
          ></MKBox>
          {/* <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MKTypography> */}
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MKTypography component={MuiLink} href="#" variant="body1" color="white">
              </MKTypography>
            </Grid>
            <Grid item xs={2}>
              <MKTypography component={MuiLink} href="#" variant="body1" color="white">
              </MKTypography>
            </Grid>
            <Grid item xs={2}>
              <MKTypography component={MuiLink} href="#" variant="body1" color="white">
              </MKTypography>
            </Grid>
          </Grid> */}
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput
                type="username"
                label="Username"
                fullWidth
                id="username"
                onChange={handleChange}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Password"
                id="password"
                fullWidth
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
            </MKBox>
            {/* <MKBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Ingat saya
              </MKTypography>
            </MKBox> */}
            <MKBox alignItems="center">
              <MKTypography
                variant="body2"
                fontWeight="regular"
                color="error"
                sx={{ textAlign: "center" }}
              >
                {errorMessage}
              </MKTypography>
            </MKBox>
            <MKBox mt={4} mb={1}>
              <MKButton variant="gradient" color="info" fullWidth onClick={handleLogin}>
                masuk
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              {/* <MKTypography variant="button" color="text"> */}
              <MKTypography
                onClick={() => setOpenModal(true)}
                sx={{ cursor: "pointer" }}
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Lupa passsword ?
              </MKTypography>
              {/* </MKTypography> */}
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>

      <Modal
        keepMounted
        disableAutoFocus={false}
        closeAfterTransition={true}
        sx={{ display: "grid", placeItems: "center" }}
        onClose={() => setOpenModal(false)}
        open={openModal}
      >
        <Slide direction="down" in={openModal} timeout={200}>
          <MKBox
            position="relative"
            width="700px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="center" p={2}>
              <MKTypography variant="h5">Reset Password</MKTypography>
            </MKBox>
            <Divider sx={{ my: 0 }} />

            <MKBox width="100%" p={2}>
              <MKTypography fontWeight="regular" variant="body2">
                Masukkan Email atau No HP
              </MKTypography>
              <MKInput
                id="resetPass"
                label="Email/No HP"
                placeholder="Masukkan Email atau No HP"
                variant="standard"
                onChange={(e) => {
                  setResetErrMsg("");
                  const val = e.target.value;
                  if (!val) {
                    setResetPass(val);
                    setResetDisabled(true);
                  } else if (isNaN(val) && !EmailValidator.validate(val)) {
                    setResetPass(val);
                    setResetDisabled(true);
                  } else if (!isNaN(val) && val.indexOf("08") !== 0) {
                    setResetPass(val);
                    setResetDisabled(true);
                  } else if (!captchaRes) {
                    setResetPass(val);
                    setResetDisabled(true);
                  } else {
                    setResetPass(val);
                    setResetDisabled(false);
                  }
                }}
                onKeyDown={handleKeydown}
                fullWidth
              />
              <MKTypography variant="button" color="error">
                {resetErrMsg}
              </MKTypography>

              <MKBox mt={2}>
                <ReCAPTCHA
                  ref={reCaptcha}
                  hl="id"
                  // sitekey={"6LeTB2YdAAAAAMRSlP7bunNp5XvSbZlBViFvKltd"}
                  sitekey={"6LfFQRwqAAAAAGicM3Ug910qi17m1XNUcQqkSwnY"}
                  // sitekey={
                  //   info && info.captchaKey
                  //     ? info.captchaKey
                  //     : "6LfFQRwqAAAAAGicM3Ug910qi17m1XNUcQqkSwnY"
                  // }
                  onChange={(e) => {
                    if (resetPass === "") {
                      setCaptchaRes(e);
                      setResetDisabled(true);
                    } else if (isNaN(resetPass) && !EmailValidator.validate(resetPass)) {
                      setCaptchaRes(e);
                      setResetDisabled(true);
                    } else {
                      setCaptchaRes(e);
                      setResetDisabled(false);
                    }
                  }}
                />
              </MKBox>
            </MKBox>

            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="flex-end" py={2} px={1.5}>
              <MKButton variant="gradient" color="error" onClick={() => setOpenModal(false)}>
                cancel
              </MKButton>
              <MKBox ml={1}>
                <MKButton
                  variant="gradient"
                  disabled={resetDisabled}
                  onClick={handleResetPass}
                  color="info"
                >
                  confirm
                </MKButton>
              </MKBox>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>
    </Layout>
  );
}

export default Login;

function Layout({ image, children }) {
  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={8} lg={5} xl={5} xxl={3}>
            {children}
          </Grid>
        </Grid>
      </MKBox>
      {/* <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light links={[]} />
      </MKBox> */}
    </>
  );
}
