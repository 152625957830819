/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import React from "react";
import secureStorage from "libraries/secureStorage";
import axios from "axios";
import Config from "config";

class Counters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: 0,
    };
  }
  componentDidMount() {
    this.getBalance();
  }
  getBalance() {
    if (secureStorage.getItem("token")) {
      axios
        .post(Config.EndPoint + "/user/balance")
        .then((res) => {
          const balance = res.data.saldo;
          secureStorage.setItem("balance", res.data.saldo);
          this.setState({ balance });
        })
        .catch((error) => {});
    }
  }

  loadBalance() {
    const balance = secureStorage.getItem("balance");
    this.setState({ balance: balance ? balance : 0 });
  }
  render() {
    return (
      // <MKBox component="section" pt={20} pb={12}>
      // <Container sx={{ mb: 8 }}>
      //   <Grid container spacing={3}>
      //     <Grid item xs={12} md={6} lg={4}>
      <MKBox component="section" pt={{ xs: 10, md: 10, xl: 10 }} pb={5}>
        <Container>
          <Grid container item xs={12} lg={12} xl={12} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <MKBox border="solid" sx={{ borderRadius: 5 }}>
                <DefaultCounterCard
                  count={this.state.balance}
                  // suffix="+"
                  prefix="Rp "
                  separator="."
                  title="Saldo"
                  // description="From buttons, to inputs, navbars, alerts or cards, you are covered"
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              {/* <Divider
                orientation="vertical"
                sx={{ display: { xs: "none", md: "block" }, mx: 0 }}
              /> */}
              <MKBox border="solid" sx={{ borderRadius: 5 }}>
                <DefaultCounterCard
                  count={300000000}
                  // suffix="+"
                  prefix="Rp "
                  separator="."
                  title="Kewajiban Setor"
                  // description="Mix the sections, change the colors and unleash your creativity"
                />
              </MKBox>
              {/* <Divider
                orientation="vertical"
                sx={{ display: { xs: "none", md: "block" }, ml: 0 }}
              /> */}
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <DefaultCounterCard
                count={560000}
                prefix="Rp "
                separator="."
                title="TRX Hari Ini"
                // description="Save 3-4 weeks of work when you use our pre-made pages for your website"
              />
            </Grid> */}
          </Grid>
        </Container>
      </MKBox>
    );
  }
}

export default Counters;
