import moment from "moment";
import loader from "./loader";
// import _ from 'lodash'
import secureStorage from "libraries/secureStorage";
import getinfo from "helper/getinfo";
import cutStr from "helper/receipt/cutstr";

const getStandMeter = (details) => {
  let standMeter = " - ";

  if (details.length === 1) {
    const firstStand = details[0]["standMeter"].value;

    let standAwal = firstStand.split("-")[0].trim();
    let standAkhir = firstStand.split("-")[1].trim();

    standMeter = `${parseInt(standAwal)} - ${parseInt(standAkhir)}`;
  } else if (details.length > 1) {
    const firstStand = details[0]["standMeter"].value;
    const lastStand = details[details.length - 1]["standMeter"].value;

    let standAwal = firstStand.split("-")[0].trim();
    let standAkhir = lastStand.split("-")[0].trim();
    standMeter = `${parseInt(standAwal)} - ${parseInt(standAkhir)}`;
  }

  return standMeter;
};

const receipt = (payload) => {
  const printer = secureStorage.getItem("printer");
  const user = secureStorage.getItem("account");
  const info = secureStorage.getItem("domainInfo");
  const title = `${payload.title.padStart(105, " ")}\r\n`;
  const body = payload.body;
  const details = body && body.details;

  const standMeter = details && details.length > 0 ? getStandMeter(details) : "-";
  // const period = body.period ?? body.details.map((item) => item.period.value).join(",");
  const period =
    body.details && body.details.length > 0
      ? body.details.map((item) => item.period.value).join(",")
      : body.period && body.period.value
      ? body.period.value
      : "-";
  // const period = body.period && body.period.value ? [["BLTH", body.period.value]] : [];
  const date = body.date && body.date.value ? body.date.value : payload.createdAt;

  const leftPage = [
    ["TGL", moment(date).format("DD/MM/YY HH:mm")],
    ["IDPEL", body.subscriberId.value],
    ["NAMA", body.name.value],
    ["BLTH", period],
    // ["JMLTAG", body.qty.value],
    ["BAYAR", body.total.value],
    // ...period,
    // ["RP TAG", body.amount.value],
    // ["ADMIN", body.admin.value],
  ];

  for (let i = leftPage.length; i < 11; i++) leftPage.push([""]);

  const middlePage = [
    ["TANGGAL", moment(date).format("DD/MM/YY HH:mm")],
    ["IDPEL", body.subscriberId.value],
    ["NAMA", body.name.value],
    ["BLTH", period],
    // ...period,
    [body.branch.value],
    ["-"],
    ...getinfo(body.info.value),
  ];

  printer === "dotmatrix"
    ? middlePage.push([`${String.fromCharCode(27)}4Loket : ${user.name}`])
    : middlePage.push([`Loket : ${user.name}`]);
  printer === "dotmatrix"
    ? middlePage.push([`Powered by ${payload.appName}${String.fromCharCode(27)}5`])
    : middlePage.push([`Powered by ${payload.appName}`]);

  for (let i = middlePage.length; i < 11; i++) middlePage.push([""]);

  const rightPage = [
    ["STAND METER", standMeter],
    [body.referenceNo.caption, body.referenceNo.value.toString().substring(0, 20)],
    [body.qty.caption, body.qty.value],
    // [body.amount.caption, body.amount.value],
    // [body.admin.caption, body.admin.value],
    [body.total.caption, body.total.value],
  ];
  for (let i = rightPage.length; i < 11; i++) rightPage.push([""]);

  // console.log({ leftPage: leftPage.length, middlePage: middlePage.length });
  // return loader(title, leftPage, middlePage, rightPage, payload);
  if (printer === "dotmatrix") return loader(title, leftPage, middlePage, rightPage, payload);
  else return { title, leftPage, middlePage, rightPage, payload };
};
export default receipt;
