import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";


import BPJS from 'assets/images/bpjs.png'
import PLN from 'assets/images/pln.png'
import PDAM from 'assets/images/pdam.png'
import TELKOM from 'assets/images/telkom.jpg'
import INDOSAT from 'assets/images/indosat.png'
import PULSA from 'assets/images/paket-data.png'
import EWALLET from 'assets/images/emoney.jpg'


const images = { BPJS, PLN, PDAM, TELKOM, INDOSAT, PULSA, EWALLET }
function getImage(name) {
  const keys = Object.keys(images).filter(key => name.indexOf(key) !== -1)
  if (keys.length > 0) {
    return images[keys[0]]
  }
  return ''
}

// Components
export default function Item({ name, customerCode, category }) {
  // console.log({ name, customerCode, category })

  const key = [6, 7, 14].includes(category) ? 'PULSA' : [2, 10].includes(category) ? 'EWALLET' : name
  return (
    <MKBox display="flex" alignItems="center" px={1} py={0.5}>
      <MKBox mr={2}>
        <MKAvatar src={getImage(key)} alt={name} size="sm" variant="rounded" />
      </MKBox>
      <MKBox display="flex" flexDirection="column">
        <MKTypography variant="button" fontWeight="medium">
          {name}
        </MKTypography>
        <MKTypography variant="caption" color="secondary">
          <b>{customerCode}</b>
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the Item
Item.propTypes = {
  name: PropTypes.string.isRequired,
  category: PropTypes.number,
  customerCode: PropTypes.string.isRequired,
};