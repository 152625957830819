import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

import Loaded from "contents/components/Loaded";
import Notif from "contents/components/Notif";

import axios from "axios";
import config from "config";
import Provinces from "helper/Provinces";
import defaultImage from "assets/images/faces/profile.png";
import secureStorage from "libraries/secureStorage";

function AccountInfo() {
  const [isLoading, setIsloading] = useState(false);
  // State Notif
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState("success");
  const [message, setMessage] = useState("");

  const [buttonDisabled, setButtonDisbled] = useState(true);
  // Profile
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [province, setProvice] = useState(null);
  // Image
  const [profileImage, setProfileImage] = useState(defaultImage);
  const [imageFile, setImageFile] = useState("");
  const [imageData, setImageData] = useState("");

  useEffect(() => {
    initField();
    initProfileImage();
  }, []);

  const initField = () => {
    const account = secureStorage.getItem("account");
    setUsername(account.username || "");
    setFullName(account.name || "");
    setEmail(account.email || "");
    setPhone(account.phone || "");
    setAddress(account.address || "");
    setProvice(
      account.province ? Provinces.findIndex((item) => item.name === account.province) : null
    );
  };

  const initProfileImage = () => {
    const imageData = secureStorage.getItem("profileImage");
    if (imageData) {
      setProfileImage(imageData.image);
      setImageData(imageData);
    }
  };

  const showNotif = ({ message, color }) => {
    setMessage(message);
    setColor(color);
    setIsOpen(true);
    setTimeout(() => setIsOpen(false), 2000);
  };

  const handleChange = (e) => {
    if (buttonDisabled) setButtonDisbled(false);

    switch (e.target.id) {
      case "email":
        setEmail(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
      case "fullName":
        setFullName(e.target.value);
        break;
      case "address":
        setAddress(e.target.value);
        break;
    }
  };

  const handleSubmit = () => {
    setIsloading(true);
    const account = secureStorage.getItem("account");
    const payload = {};
    if (fullName !== account.name) payload.name = fullName;
    if (email !== account.email) payload.email = email;
    if (phone !== account.phone) payload.phone = phone;
    if (address !== account.address) payload.address = address;
    if (province !== Provinces.findIndex((item) => item.name === account.province))
      payload.province = Provinces[province];

    axios
      .post(`${config.EndPoint}/user/update`, payload)
      .then((res) => {
        setIsloading(false);
        secureStorage.setItem("account", res.data.user);
        showNotif({
          color: "success",
          message: "Data telah diupdate",
        });
      })
      .catch((err) => {
        setIsloading(false);
        if (err.response) {
          showNotif({
            color: "error",
            message: err.response.data,
          });
        } else {
          showNotif({
            color: "error",
            message: "Koneksi jaringan terputus",
          });
        }
      });
  };

  return (
    <Container>
      <MKBox component="section" width="100%" py={12}>
        {/* LOADED */}
        <Loaded open={isLoading} />
        {/* ALERT TOAST */}
        <Notif open={isOpen} color={color} message={message} />

        <MKBox
          p={3}
          coloredShadow="secondary"
          borderRadius="5px"
          mb={3}
          bgColor="white"
          shadow="xl"
        >
          <Grid container item xs={12} mt={2} md={12} mx="auto" justifyContent="center">
            <MKBox width="100%" display="flex" justifyContent="center">
              <MKTypography
                mb={1}
                variant="h5"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
              >
                Account Detail
              </MKTypography>
            </MKBox>
          </Grid>

          <Grid container spacing={2} mt={1} justifyContent="center">
            <Grid item xs={12} md={12}>
              <MKInput
                variant="standard"
                disabled
                label="Username"
                id="username"
                value={username}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} mt={1}>
              <MKInput
                variant="standard"
                label="Email"
                id="email"
                value={email}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} mt={1}>
              <MKInput
                variant="standard"
                label="No Handphone"
                id="phone"
                value={phone}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} mt={1}>
              <MKInput
                variant="standard"
                label="Nama Lengkap"
                id="fullName"
                value={fullName}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} mt={1}>
              <MKInput
                variant="standard"
                label="Alamat Lengkap"
                id="address"
                value={address}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} mt={1}>
              <Autocomplete
                value={province}
                options={Provinces}
                getOptionLabel={(option) => {
                  if (!option) return;
                  if (option.name) {
                    return option?.name;
                  } else {
                    const prov = Provinces[option];
                    return prov?.name;
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    const index = Provinces.findIndex((key) => key.name === newValue.name);
                    setProvice(index);
                  }
                }}
                renderInput={(params) => (
                  <MKInput {...params} label="PROVINSI" variant="standard" fullWidth />
                )}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <MKBox display="flex" justifyContent="center">
                <MKButton
                  type="button"
                  variant="gradient"
                  color="info"
                  disabled={buttonDisabled}
                  onClick={handleSubmit}
                >
                  SIMPAN
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
    </Container>
  );
}

export default AccountInfo;
