import React from "react";
// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { Zoom } from "@mui/material";
import MKInput from "components/MKInput";
import { fnumber } from "helper/Helper";
import axios from "axios";
import Config from "config";

import { v4 as uuidv4 } from "uuid";

export default class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      buttonDisabled: true,
      AdminNominals: [],
      isLoading: false
    };
  }

  toggleModal = () => this.setShow(!this.state.show);

  setShow = (show, productId) => {
    this.setState({ show });
    // productId && this.loadAdminNominal(productId);
  };

  setData = (data) => {
    this.setState(data)
  }

  loadAdminNominal = (productId) => {
    axios
      .post(`${Config.EndPoint}/product/admin`, {
        productId,
      })
      .then((res) => {
        this.setState({ AdminNominals: res.data, isLoading: false });
      })
      .catch((err) => { this.setState({ isLoading: false }) });
  };

  render() {
    return (
      <Modal
        open={this.state.show}
        onClose={this.toggleModal}
        sx={{ display: "grid", placeItems: "center" }}
      >
        <Zoom in={this.state.show}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
            style={{ outline: "none" }}
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Pilih Admin</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={this.toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox pl={5} pr={5}>
              {this.state.isLoading ? (
                <MKBox display="flex" justifyContent="center" m={3}>
                  <CircularProgress color="inherit" />
                </MKBox>
              ) : this.state.AdminNominals.map((item) => (
                <MKBox mb={1} key={uuidv4()}>
                  <MKButton
                    fullWidth
                    color="info"
                    variant="gradient"
                    width="100%"
                    onClick={() => {
                      this.setState({ show: false, isLoading: false }, () => {
                        this.props.onConfirm(item.nominal);
                      });
                    }}
                  >
                    {fnumber(item.nominal)}
                  </MKButton>
                </MKBox>
              ))}
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="error" onClick={this.toggleModal}>
                batal
              </MKButton>
            </MKBox>
          </MKBox>
        </Zoom>
      </Modal>
    );
  }
}
