import { useState, useEffect, useRef } from "react";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Autocomplete, Icon } from "@mui/material";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKAlert from "components/MKAlert";

import Loaded from "contents/components/Loaded";
import Notif from "contents/components/Notif";

import axios from "axios";
import Config from "config";
import { fnumber } from "helper/Helper";
import { TlUsers, DmUsers } from "helper/userLibs";
import secureStorage from "libraries/secureStorage";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-formControl": {
      fontSize: "20px",
      // fontWeight: 'bold',
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  styleRek: {
    "& .MuiInputBase-formControl": {
      fontSize: "23px",
      fontWeight: "bold",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
});

function Topup() {
  const classes = useStyles();
  const bankRef = useRef();
  const navigate = useNavigate();

  // const [account, setAccount] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  // State Notif
  const [isNotif, setIsNotif] = useState(false);
  const [color, setColor] = useState("success");
  const [message, setMessage] = useState("");

  // Modal
  const [showModal, setShowModal] = useState(false);

  // Bank
  const [bank, setBank] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");

  const [status, setStatus] = useState("");
  const [amount, setAmount] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ticket, setTicket] = useState("");
  const [unique, setUnique] = useState("345");
  const [uniqueAmount, setUniqueAmount] = useState(2000345);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    initBank();
    initTicket();
  }, []);

  const initBank = () => {
    axios
      .post(Config.EndPoint + "/topup/bank")
      .then((res) => {
        const data = res.data;
        setBank(data.filter((key) => key));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initTicket = () => {
    axios
      .post(Config.EndPoint + "/topup/ticket/status")
      .then((res) => {
        const ticket = res.data;
        setTicket(ticket);
        setStatus(ticket.statusId);
        setUnique(ticket.unique);
        setUniqueAmount(ticket.uniqueAmount ? ticket.uniqueAmount : ticket.finalAmount);
        setAccountNumber(
          `${ticket.Bank.name} ${ticket.Bank.accountNumber} a.n. ${ticket.Bank.accountName}`
        );
        if (res.data.statusId !== 2) setButtonDisabled(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showNotif = ({ message, color }) => {
    setMessage(message);
    setColor(color);
    setIsNotif(true);
    setTimeout(() => setIsNotif(false), 500);
  };

  const handleChange = (e) => {
    if (buttonDisabled) setButtonDisabled(false);

    const valStr = e.target.value.replace(/\./g, "");
    let value = isNaN(valStr) || valStr === "" ? 0 : parseInt(valStr);
    if (value < 0) value = 0;

    setAmount(fnumber(value));
    // this.setState({ amount: fnumber(value) })
    if (!value || value < 1000 || !selectedBank) setButtonDisabled(true);
  };

  const submitTicket = () => {
    setButtonDisabled(true);
    const payload = {
      amount,
      bank: selectedBank,
      ticket,
    };

    axios
      .post(Config.EndPoint + "/topup/ticket", payload)
      .then((res) => {
        const ticket = res.data;
        setTicket(ticket);
        setStatus(ticket.statusId);
        setUniqueAmount(ticket.uniqueAmount ? ticket.uniqueAmount : ticket.finalAmount);
        setUnique(ticket.unique);
        setAccountNumber(
          `${ticket.Bank.name} ${ticket.Bank.accountNumber} a.n. ${ticket.Bank.accountName}`
        );
        if (ticket.statusId !== 2) setButtonDisabled(false);
      })
      .catch((err) => {
        if (err.response) {
          showNotif({ message: err.response.data, color: "error" });
        } else {
          showNotif({ message: "Koneksi jaringan terputus", color: "error" });
        }
      });
  };

  const handleSubmit = (e) => {
    if (status < 3) {
      submitTicket();
    } else {
      axios
        .post(Config.EndPoint + "/topup/ticket/settle", { ticket })
        .then((res) => {
          setButtonDisabled(true);
          setTicket("");
          setAmount("");
          setSelectedBank("");
          setStatus("");
          // navigate("/");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const renderNotAvalible = () => (
    <Container>
      <MKBox component="section" width="100%" py={12}>
        <MKBox
          p={3}
          coloredShadow="secondary"
          borderRadius="5px"
          mb={3}
          bgColor="white"
          shadow="xl"
        >
          <Grid container item xs={12} md={12} mt={2} mx="auto" justifyContent="center">
            <MKBox width="100%" display="flex" justifyContent="center">
              <MKTypography
                mb={1}
                variant="h5"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
              >
                HALAMAN TIDAK TERSEDIA
              </MKTypography>
            </MKBox>
          </Grid>
        </MKBox>
      </MKBox>
    </Container>
  );

  const renderAvalible = () => (
    <Container>
      <MKBox component="section" width="100%" py={12}>
        {/* LOADED */}
        <Loaded open={isLoading} />
        {/* ALERT TOAST */}
        <Notif open={isNotif} color={color} message={message} />
        <MKBox
          p={3}
          coloredShadow="secondary"
          borderRadius="5px"
          mb={3}
          bgColor="white"
          shadow="xl"
        >
          <Grid container item xs={12} md={12} mt={2} mx="auto" justifyContent="center">
            <MKBox width="100%" display="flex" justifyContent="center">
              <MKTypography
                mb={1}
                variant="h5"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
              >
                Topup
              </MKTypography>
            </MKBox>
          </Grid>

          {!status ? (
            <Grid container spacing={2} mt={2} justifyContent="center">
              <Grid item xs={12} md={12}>
                <Autocomplete
                  options={bank.map((key) => {
                    return {
                      id: key.id,
                      name: key.name,
                    };
                  })}
                  value={selectedBank}
                  onChange={(e, value) => {
                    setSelectedBank(value);
                  }}
                  getOptionLabel={(option) => option && option.name}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <MKInput {...params} inputRef={bankRef} variant="standard" placeholder="BANK" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} mt={1}>
                <MKInput
                  variant="standard"
                  type="tel"
                  label="Nominal"
                  placeholder="Masukkan Nominal"
                  id="amount"
                  value={amount}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          ) : (
            <MKBox mt={2}>
              <Grid container item xs={12} md={12} justifyContent="center">
                <MKAlert color="warning">
                  <Icon fontSize="small">info_outline</Icon>&nbsp;
                  {status === 1 ? (
                    <span style={{ color: "#000" }}>
                      Tiket ini berlaku sampai dengan 1 hari. Silahkan transfer sebesar{" "}
                      <b color="#FF0000">Nominal Transfer</b>. Pastikan buka kembali halaman ini
                      saat mentransfer uang. Untuk mengecek apakah tiket masih berlaku atau sudah
                      expired. Tekan tombol <b color="#FF0000">Selesai</b>. jika sudah melakukan
                      transfer. Kemudian sistem akan mengecek dan menambahkan saldo secara otomatis,
                      paling lama 15 menit.
                    </span>
                  ) : status === 2 ? (
                    <span style={{ color: "#000" }}>
                      Sistem sedang melakukan verifikasi silahkan tunggu. Saldo akan bertambah
                      secara otomatis.
                    </span>
                  ) : status === 3 ? (
                    <span style={{ color: "#000" }}>
                      Verifikasi berhasil, saldo anda telah bertambah.
                    </span>
                  ) : status === 6 ? (
                    <span style={{ color: "#000" }}>Ticket telah expired.</span>
                  ) : status === 7 ? (
                    <span style={{ color: "#000" }}>
                      Sistem tidak dapat memverifikasi ticket. Silahkan kirim foto bukti transfer ke
                      email <b style={{ color: "#FF0000" }}>topup@payfren.id</b>
                    </span>
                  ) : (
                    <span style={{ color: "#000" }}>Ticket dibatalkan</span>
                  )}
                </MKAlert>
              </Grid>

              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <MKBox width="100%" display="flex" justifyContent="center">
                    <MKInput
                      variant="standard"
                      type="tel"
                      label="Unique Code"
                      id="unique"
                      className={classes.root}
                      value={unique}
                      readOnly
                      fullWidth
                    />
                  </MKBox>
                </Grid>
              </Grid>

              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <MKBox display="flex" justifyContent="center">
                    <MKInput
                      variant="standard"
                      type="tel"
                      label="Nominal Transfer"
                      id="uniqueAmount"
                      className={classes.root}
                      value={fnumber(uniqueAmount)}
                      readOnly
                      fullWidth
                    />
                  </MKBox>
                </Grid>
              </Grid>

              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={12}>
                  <MKBox display="flex" justifyContent="center">
                    <MKInput
                      variant="standard"
                      type="tel"
                      label="Rekening"
                      id="accountNumber"
                      className={classes.styleRek}
                      value={accountNumber}
                      readOnly
                      fullWidth
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          )}

          <Grid container spacing={2} mt={1} justifyContent="center">
            <Grid item xs={12} md={12}>
              <MKBox display="flex" justifyContent="center">
                <MKButton
                  type="button"
                  variant="gradient"
                  color="info"
                  disabled={buttonDisabled}
                  onClick={handleSubmit}
                >
                  {!status ? "Request Ticket" : status === 1 ? "Selesai Transfer" : "Tutup"}
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
    </Container>
  );

  const account = secureStorage.getItem("account");

  return account && DmUsers.includes(account.userTypeId)
    ? renderAvalible()
    : account && TlUsers.includes(account.userTypeId) && account.isDm
    ? renderAvalible()
    : renderNotAvalible();
}

export default Topup;
