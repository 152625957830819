module.exports = {
  // EndPoint: "http://localhost:30001",
  // EndPoint: "https://backend.payfren.id/dev",
  // ApiSunrise: "https://mon-backend.payfren.id.id/dev",

  EndPoint: "https://rainbow.saminara.com",
  ApiFren: "https://sunrise.saminara.com",
  PayFren: "https://saminara.com",
  Resource: "https://resource.saminara.com/",

  // EndPoint: "https://backend.payfren.id",
  // ApiSunrise: "https://mon-backend.payfren.id",
  // Resource: "https://resource.saminara.com/",
};
