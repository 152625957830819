import Icon from "@mui/material/Icon";

// // Pages
// import SettingPartner from "contents/Setting/Partner";

// Account

// Sections

import Dashboard from "contents/Dashboard";
import Transaction from "contents/Transaction/Transaction";
import Collective from "contents/Transaction/Collective";
import SummarizeIcon from "@mui/icons-material/Summarize";

// Report
import ReportTransactionDetail from "contents/Report/Transaction/Detail";
import ReportTransactionRekap from "contents/Report/Transaction/Rekap";
// import ReportTransactionLog from "contents/Report/Transaction/Log"
import BalanceMutation from "contents/Report/Balance";
import BillHistorry from "contents/Report/Bill/History";
import BillPayment from "contents/Report/Bill/Payment";

// Settings
import ChangePassword from "contents/Settings/Account/ChangePassword";
import AccountInfo from "contents/Settings/Account/Info";
// import AccountPin from "contents/Settings/Account/Pin"
import SettingPrinter from "contents/Settings/Printer";
import Topup from "contents/Settings/Account/Topup";
// import InvitationLink from "contents/Settings/Invitation"
import Donwload from "contents/Settings/Download";
import AdminNominal from "contents/Settings/AdminNominal";

const menuSetting = (userTypeId) => {
  const topup = userTypeId
    ? [7, 8, 10, 11, 18, 23, 29].includes(userTypeId)
      ? [
          {
            name: "Deposit",
            key: "topup",
            description: "Deposit Saldo",
            route: "/settings/account/topup",
            component: <Topup />,
          },
        ]
      : []
    : [];
  // const topup =
  //   userTypeId && [7, 8, 10, 11, 18, 23, 29].includes(userTypeId)
  //     ? [
  //         {
  //           name: "Deposit",
  //           key: "topup",
  //           description: "Deposit Saldo",
  //           route: "/settings/account/topup",
  //           component: <Topup />,
  //         },
  //       ]
  //     : [];

  return [
    {
      name: "pengaturan",
      icon: <Icon>settings</Icon>,
      route: "/settings",
      // index:true,
      key: "settings",
      collapse: [
        {
          name: "akun",
          description: "Pengaturan Akun",
          route: "/settings/account",
          key: "setting-account",
          dropdown: true,
          collapse: [
            {
              name: "informasi akun",
              description: "Ubah Informasi Akun",
              route: "/settings/account/info",
              key: "info",
              index: true,
              component: <AccountInfo />,
            },
            {
              name: "ganti password",
              description: "Ganti Password Baru",
              route: "/settings/account/changepass",
              key: "changepass",
              component: <ChangePassword />,
            },
            ...topup,
          ],
        },
        {
          name: "printer",
          route: "/settings/printer",
          key: "setting-printer",
          description: "Pilih Printer",
          index: true,
          component: <SettingPrinter />,
        },
        {
          name: "download",
          route: "/settings/download",
          key: "download",
          description: "Unduh Aplikasi",
          component: <Donwload />,
        },
      ],
    },
  ];
};

const routes = [
  {
    name: "dashboard",
    icon: <Icon>dashboard</Icon>,
    route: "/dashboard",
    index: true,
    component: <Dashboard />,
    key: "dashboard",
  },
  {
    name: "transaksi",
    icon: <Icon>shopping-cart</Icon>,
    route: "/transaction",
    key: "transaction",
    collapse: [
      {
        name: "transaksi",
        index: true,
        description: "Transaksi",
        route: "/transaction/transaction",
        key: "detail",
        component: <Transaction />,
      },
      {
        name: "BayarKolektif",
        index: true,
        description: "Transaksi Bayar Kolektif",
        route: "/transaction/multipayment",
        key: "multipayment",
        component: <Collective />,
      },
    ],
  },
  {
    name: "laporan",
    icon: <SummarizeIcon />,
    route: "/report",
    key: "report",
    collapse: [
      {
        name: "transaksi",
        description: "Laporan Transaksi",
        route: "/report/transaction",
        key: "transaction",
        // index:true,
        dropdown: true,
        collapse: [
          // {
          //   name : 'Log',
          //   description: 'Laporan Log Transaksi',
          //   route : '/report/transaction/log',
          //   key: 'report-log',
          //   component : <ReportTransactionLog/>
          // },
          {
            name: "detail",
            index: true,
            description: "Laporan Detail Transaksi",
            route: "/report/transaction/detail",
            key: "report-detail",
            component: <ReportTransactionDetail />,
          },
          {
            name: "rekap",
            description: "Laporan Rekap Transaksi",
            route: "/report/transaction/rekap",
            key: "report-rekap",
            component: <ReportTransactionRekap />,
          },
        ],
      },
      {
        index: true,
        name: "saldo",
        description: "Laporan Saldo",
        icon: <Icon>contacts</Icon>,
        route: "/report/balance",
        component: <BalanceMutation />,
        key: "balance",
      },
      {
        name: "setoran",
        description: "Laporan Setoran",
        route: "/report/bill",
        key: "bill",
        dropdown: true,
        collapse: [
          {
            name: "pembayaran tagihan",
            index: true,
            description: "Ticket Pembayaran Tagihan",
            route: "/report/bill/payment",
            component: <BillPayment />,
            key: "bill-payment",
          },
          {
            name: "tagihan",
            description: "Riwayat Tagihan",
            route: "/report/bill/history",
            key: "bill-history",
            component: <BillHistorry />,
          },
        ],
      },
    ],
  },
  {
    name: "pengaturan",
    icon: <Icon>settings</Icon>,
    route: "/settings",
    // index:true,
    key: "settings",
    collapse: [
      {
        name: "akun",
        description: "Pengaturan Akun",
        route: "/settings/account",
        key: "setting-account",
        dropdown: true,
        collapse: [
          {
            name: "informasi akun",
            description: "Ubah Informasi Akun",
            route: "/settings/account/info",
            key: "info",
            index: true,
            component: <AccountInfo />,
          },
          {
            name: "ganti password",
            description: "Ganti Password Baru",
            route: "/settings/account/changepass",
            key: "changepass",
            component: <ChangePassword />,
          },
          {
            name: "Deposit",
            key: "topup",
            description: "Deposit Saldo",
            route: "/settings/account/topup",
            component: <Topup />,
          },
        ],
      },
      {
        name: "Default Admin",
        route: "/settings/defaultadmin",
        key: "defaultadmin",
        description: "settinga default admin",
        component: <AdminNominal />,
      },
      {
        name: "printer",
        route: "/settings/printer",
        key: "setting-printer",
        description: "Pilih Printer",
        index: true,
        component: <SettingPrinter />,
      },
      {
        name: "download",
        route: "/settings/download",
        key: "download",
        description: "Unduh Aplikasi",
        component: <Donwload />,
      },
    ],
  },
];

const getMenus = (user) => {
  const userTypeId = user && user.userTypeId ? user.userTypeId : null;

  return [...routes, ...menuSetting(userTypeId)];
};

// export default routes;
export { routes, getMenus };
