import React from "react";
// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { Zoom } from "@mui/material";
import MKInput from "components/MKInput";
import { fnumber } from "helper/Helper";

export default class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      buttonDisabled: true,
    };
  }

  toggleModal = () => this.setShow(!this.state.show);

  setShow = (show) => {
    this.setState({ show });
  };

  render() {
    return (
      <Modal
        open={this.state.show}
        onClose={this.toggleModal}
        sx={{ display: "grid", placeItems: "center" }}
      >
        <Zoom in={this.state.show}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
            style={{ outline: "none" }}
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Pilih Nominal</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={this.toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox pl={5} pr={5}>
              <MKBox mb={1}>
                <MKButton
                  fullWidth
                  color="info"
                  variant="gradient"
                  width="100%"
                  onClick={() => {
                    this.props.onConfirm(20000);
                    this.setState({ show: false });
                  }}
                >
                  {fnumber(20000)}
                </MKButton>
              </MKBox>
              <MKBox mb={1}>
                <MKButton
                  fullWidth
                  color="info"
                  variant="gradient"
                  width="100%"
                  onClick={() => {
                    this.props.onConfirm(50000);
                    this.setState({ show: false });
                  }}
                >
                  {fnumber(50000)}
                </MKButton>
              </MKBox>
              <MKBox mb={1}>
                <MKButton
                  fullWidth
                  color="info"
                  variant="gradient"
                  width="100%"
                  onClick={() => {
                    this.props.onConfirm(100000);
                    this.setState({ show: false });
                  }}
                >
                  {fnumber(100000)}
                </MKButton>
              </MKBox>
              <MKBox mb={1}>
                <MKButton
                  fullWidth
                  color="info"
                  variant="gradient"
                  width="100%"
                  onClick={() => {
                    this.props.onConfirm(200000);
                    this.setState({ show: false });
                  }}
                >
                  {fnumber(200000)}
                </MKButton>
              </MKBox>
              <MKBox mb={1}>
                <MKButton
                  fullWidth
                  color="info"
                  variant="gradient"
                  width="100%"
                  onClick={() => {
                    this.props.onConfirm(500000);
                    this.setState({ show: false });
                  }}
                >
                  {fnumber(500000)}
                </MKButton>
              </MKBox>
              <MKBox mb={1}>
                <MKButton
                  fullWidth
                  color="info"
                  variant="gradient"
                  width="100%"
                  onClick={() => {
                    this.props.onConfirm(1000000);
                    this.setState({ show: false });
                  }}
                >
                  {fnumber(1000000)}
                </MKButton>
              </MKBox>
              <MKBox mb={1}>
                <MKButton
                  fullWidth
                  color="info"
                  variant="gradient"
                  width="100%"
                  onClick={() => {
                    this.props.onConfirm(5000000);
                    this.setState({ show: false });
                  }}
                >
                  {fnumber(5000000)}
                </MKButton>
              </MKBox>
              <MKBox mb={1}>
                <MKButton
                  fullWidth
                  color="info"
                  variant="gradient"
                  width="100%"
                  onClick={() => {
                    this.props.onConfirm(10000000);
                    this.setState({ show: false });
                  }}
                >
                  {fnumber(10000000)}
                </MKButton>
              </MKBox>
              <MKBox mb={1}>
                <MKButton
                  fullWidth
                  color="info"
                  variant="gradient"
                  width="100%"
                  onClick={() => {
                    this.props.onConfirm(50000000);
                    this.setState({ show: false });
                  }}
                >
                  {fnumber(50000000)}
                </MKButton>
              </MKBox>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="error" onClick={this.toggleModal}>
                batal
              </MKButton>
            </MKBox>
          </MKBox>
        </Zoom>
      </Modal>
    );
  }
}
