import React, { createRef } from "react";
// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { Zoom } from "@mui/material";
import MKInput from "components/MKInput";
// import Confirmation from "../Confirmation";
import axios from "axios";
import Config from "config";
import secureStorage from "libraries/secureStorage";

export default class ReLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      amount: 0,
      totalAmount: 0,
      buttonDisabled: true,
      password: "",
    };
    this.inputPasswordRef = createRef();
    this.confirmRef = createRef();
  }

  toggleModal = () => this.setShow(!this.state.show);

  setShow = (show) => {
    this.setState({ show });
  };

  handleLogin = (e) => {
    const account = secureStorage.getItem("account");
    const device = secureStorage.getItem("device");
    const username = account.username;
    const password = this.state.password;
    const payload = {
      username,
      password,
      device,
    };
    // console.log({ payload });
    if (device) {
      axios
        .post(`${Config.EndPoint}/user/login`, payload)
        .then((r) => {
          secureStorage.setItem("account", r.data.user);
          secureStorage.setItem("token", r.data.token);
          axios.defaults.headers.Authorization = "Bearer " + r.data.token;

          this.setState({ password: "" }, () => {
            this.toggleModal();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.props.onLogout();
    }
  };

  render() {
    return (
      <>
        <Modal
          open={this.state.show}
          // onClose={this.toggleModal}
          sx={{ display: "grid", placeItems: "center" }}
        >
          <Zoom
            in={this.state.show}
            onEntered={(e) => {
              this.inputPasswordRef.current.focus();
            }}
          >
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
              style={{ outline: "none" }}
            >
              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Masukkan Password</MKTypography>
                {/* <CloseIcon
                  fontSize="medium"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    this.toggleModal();
                    this.props.onLogout();
                  }}
                /> */}
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox pl={5} pr={5}>
                <MKInput
                  inputRef={this.inputPasswordRef}
                  fullWidth
                  //   inputProps={{ style: { textAlign: "right", fontSize: 40 } }}
                  type="password"
                  label="Password"
                  value={this.state.password}
                  onChange={(e) => {
                    const buttonDisabled = e.target.value !== "" ? false : true;
                    this.setState({ password: e.target.value, buttonDisabled });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleLogin();
                    }
                  }}
                />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    this.toggleModal();
                    this.props.onLogout();
                  }}
                >
                  keluar
                </MKButton>
                <MKButton
                  disabled={this.state.buttonDisabled}
                  variant="gradient"
                  color="info"
                  onClick={this.handleLogin}
                >
                  masuk
                </MKButton>
              </MKBox>
            </MKBox>
          </Zoom>
        </Modal>
      </>
    );
  }
}
