import React from "react";
import { Document, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import moment from "moment";
import { fnumber } from "helper/Helper";
// import textLogo from "assets/img/logo.png"
import SpaceMonoRegular from "assets/fonts/SpaceMono-Regular.ttf";
import SpaceMonoBoldItalic from "assets/fonts/SpaceMono-BoldItalic.ttf";
import SpaceMonoItalic from "assets/fonts/SpaceMono-Italic.ttf";
import SpaceMonoBold from "assets/fonts/SpaceMono-Bold.ttf";
import _ from "lodash";
import receipt from "helper/receipt";
import receiptToArray from "libraries/receiptToArray";

import secureStorage from "libraries/secureStorage";

import TwoColumns from "helper/receiptStyles/TwoColumns";
import ThreeColumns from "helper/receiptStyles/ThreeColumns";

const st = {
  TwoColumns,
  ThreeColumns,
};

Font.register({
  family: "SpaceMono",
  // src: 'https://fonts.googleapis.com/css2?family=Space+Mono&display=swap',
  // fontStyle: 'normal',
  // fontWeight: 'normal',
  format: "truetype",
  fonts: [
    { src: SpaceMonoRegular },
    { src: SpaceMonoBoldItalic },
    { src: SpaceMonoItalic },
    { src: SpaceMonoBold },
  ],
});

// const styles = StyleSheet.create(TwoColumns)

// Create Document Component
class PdfCollective extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "STRUK",
      data: [],
      logo: "",
      rowsPerPage: 0,
      totalPages: 0,
    };
  }
  componentDidMount() {
    const printFormat = secureStorage.getItem("printFormat");
    const styles = printFormat
      ? require(`helper/receiptStyles/${printFormat.value}`)
      : require("helper/receiptStyles/TwoColumns");
    const { data } = this.props.data;
    const rowsPerPage = 4;
    const totalPages = Math.ceil(data.length / rowsPerPage);
    // console.log({ data, rowsPerPage, totalPages });
    this.setState({ data, rowsPerPage, totalPages, styles: StyleSheet.create(styles) });
  }
  setContent = (title, body) => {
    this.setState({ title, body });
  };

  getTwoColumns(body) {
    const account = secureStorage.getItem("account");
    const { directUser } = account;
    const { title, middlePage, rightPage } = receipt(body);
    // console.log({title,leftPage,middlePage,rightPage})
    const printFormat = secureStorage.getItem("printFormat");
    const styles = StyleSheet.create(
      printFormat && printFormat.value ? st[printFormat.value] : st.TwoColumns
    );
    return (
      <View style={styles.body} key={body.salesId}>
        <View style={styles.title}>
          <Text>{title.trim().toUpperCase()}</Text>
        </View>
        <View style={styles.receipt}>
          {middlePage.map((row, index) => (
            <View style={styles.row} key={index}>
              {index < 6 ? (
                <View style={styles.left}>
                  <View style={styles.caption}>
                    <Text>{row.length > 1 ? row[0] : ""}</Text>
                  </View>
                  <View style={styles.value}>
                    <Text>
                      {row.length > 1
                        ? `: ${
                            row[0] === "TANGGAL"
                              ? moment(row[1]).format("DD MMMM YYYY HH:mm")
                              : row[1]
                          }`.toUpperCase()
                        : `  ${row[0]}`}
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={styles.footer}>
                  <View style={styles.footer}>
                    <Text>
                      {row[0].indexOf("payfren.id") !== -1 && [350].includes(directUser.id)
                        ? ""
                        : row[0]}
                    </Text>
                  </View>
                </View>
              )}
              {index < 6 && (
                <View style={styles.right}>
                  <View style={styles.caption}>
                    <Text>{rightPage[index][0]}</Text>
                  </View>
                  <View style={styles.value}>
                    <Text>
                      {/* {rightPage[index][1]} */}
                      {`: ${
                        typeof rightPage[index][1] !== "number"
                          ? rightPage[index][1]
                          : `Rp.${fnumber(rightPage[index][1]).padStart(11, " ")}`
                      }`}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          ))}
        </View>
      </View>
    );
  }

  getThreeColumns(data) {
    // const account = secureStorage.getItem("account");
    // const { directUser } = account;
    const { title, leftPage, middlePage, rightPage } = receipt(data);
    const styles = StyleSheet.create(st.ThreeColumns);
    return (
      <View style={{ ...styles.body }} key={data.salesId}>
        <View style={styles.title}>
          <Text>{title.trim().toUpperCase()}</Text>
        </View>
        <View style={styles.receipt}>
          {middlePage.map((row, index) => (
            <View style={styles.row} key={index}>
              {index < 8 ? (
                <View style={styles.left}>
                  <View style={styles.captionLeft}>
                    <Text>{leftPage[index].length > 1 ? leftPage[index][0] : ""}</Text>
                  </View>
                  <View style={styles.valueLeft}>
                    <Text>
                      {leftPage[index].length > 1
                        ? `: ${
                            typeof leftPage[index][1] !== "number"
                              ? leftPage[index][1]
                              : `Rp.${fnumber(leftPage[index][1]).padStart(11, " ")}`
                          }`
                        : // `: ${leftPage[index][1]}`
                          `  ${leftPage[index][0]}`}
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={styles.footerLeft}>
                  <View style={styles.captionLeft}>
                    <Text>{""}</Text>
                  </View>
                  <View style={styles.valueLeft}>
                    <Text>{""}</Text>
                  </View>
                </View>
              )}
              {index < 6 ? (
                <View style={styles.middle}>
                  <View style={styles.caption}>
                    <Text>{row.length > 1 ? row[0] : ""}</Text>
                  </View>
                  <View style={styles.value}>
                    <Text>
                      {row.length > 1
                        ? row[0] !== ""
                          ? `: ${
                              row[0] === "TANGGAL"
                                ? typeof row[1] === "object"
                                  ? moment(row[1]).format("DD/MM/YY HH:mm")
                                  : row[1]
                                : row[1]
                            }`
                          : `  ${row[0]}`
                        : ""}
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={styles.footerRight}>
                  <View style={styles.footerRight}>
                    <Text>{row[0]}</Text>
                  </View>
                </View>
              )}
              {index < 6 && (
                <View style={styles.right}>
                  <View
                    style={
                      rightPage[index].length > 1
                        ? { ...styles.caption, width: "30%" }
                        : styles.token
                    }
                  >
                    <Text>{rightPage[index][0]}</Text>
                  </View>
                  <View style={{ ...styles.value, width: "70%" }}>
                    <Text>
                      {rightPage[index][1] && rightPage[index].length > 1
                        ? `: ${
                            typeof rightPage[index][1] !== "number"
                              ? rightPage[index][1]
                              : `Rp.${fnumber(rightPage[index][1]).padStart(11, " ")}`
                          }`
                        : rightPage[index][1] !== undefined
                        ? ":"
                        : ""}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          ))}
        </View>
      </View>
    );
  }

  render() {
    // const hostname = window.location.hostname;
    // const printFormat = secureStorage.getItem("printFormat");
    // const styles = StyleSheet.create(
    //   printFormat && printFormat.value ? st[printFormat.value] : st.TwoColumns
    // );
    const styles = StyleSheet.create(st.ThreeColumns);

    return (
      <Document>
        {/* {_.times(this.state.totalPages, (currentPage) => {
          return (
            <Page style={styles.container} pageNumber={currentPage + 1} key={currentPage + 1}>
              {this.state.data.map((item, index) => this.getThreeColumns(item))}
            </Page>
          );
        })} */}
        <Page style={styles.container}>
          {this.state.data.map((item, index) => this.getThreeColumns(item))}
        </Page>
      </Document>
    );
  }
}
export default PdfCollective;
