import React from "react";
import { Navigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { Autocomplete, TextField } from "@mui/material";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

import Loaded from "contents/components/Loaded";
import GSearchActions from "contents/components/GSearchActions";
import CustomTable from "contents/components/CustomTable";
import Pagination from "contents/components/Pagination";

import secureStorage from "libraries/secureStorage";
import { fnumber, formatDateTime } from "helper/Helper";

import _ from "lodash";
import axios from "axios";
import Config from "config";
import moment from "moment";

class BalanceMutation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 15,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      rows: [],
      //<<pagin states
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),

      selectedProducts: [],
      products: [],

      selectedType: null,
      types: [{ id: 1, label: 'MASUK' }, { id: 2, label: 'KELUAR' }],

      isLoading: false,
    };
  }

  componentDidMount() {
    const user = secureStorage.getItem("account");
    if (user) {
      this.loadMutation();
      this.loadProduct();
    }
  }

  loadMutation = (params) => {
    const user = secureStorage.getItem("account");
    const userId = this.props.match && this.props.params ? this.props.match.params.userId : user.id;
    this.setState({ isLoading: true })

    const typeId = this.state.selectedType ? { typeId: this.state.selectedType?.id } : {}
    const payload = {
      userId,
      startDate: params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      // paging payload >>
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
      // << paging payload
      products:
        this.state.selectedProducts &&
        this.state.selectedProducts.length > 0 &&
        this.state.selectedProducts,
      ...typeId
    };
    axios
      .post(`${Config.EndPoint}/user/balance/mutation`, payload)
      .then((res) => {
        this.setState({
          name: res.data.name,
          rows: res.data.balances,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          startDate: payload.startDate,
          endDate: payload.endDate,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  loadProduct() {
    axios
      .post(`${Config.EndPoint}/product/dropdown`)
      .then((res) => {
        this.setState({ products: res.data });
      })
      .catch((error) => { });
  }

  render() {
    const user = secureStorage.getItem("account");
    if (!user) {
      return <Navigate to="/" />;
    }
    const userId =
      this.props.match && this.props.params ? parseInt(this.props.match.params.userId) : null;

    return (
      <>
        <MKBox wdith="100%" component="section" py={12} mx={2}>
          {/* <Loaded open={this.state.isLoading} /> */}
          <Card sx={{ padding: "25px", marginTop: "10px" }}>
            <Grid container justifyContent="center">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xl={2} lg={2} md={2} sm={12} xs={12} mb={{ xs: 1, md: 0 }}>
                    <MKBox
                      color="rgba(0,0,0,.6)"
                      bgColor="#e8e8e8"
                      borderRadius="lg"
                      shadow="lg"
                      textAlign="center"
                      justifyContent="center"
                      opacity={1}
                      p={1}
                    >
                      Mutasi Saldo
                    </MKBox>
                  </Grid>
                  <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GSearchActions
                      onSubmit={this.loadMutation}
                      handleChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12} px={1}>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      fullWidth
                      sx={{ marginTop: 1 }}
                      options={this.state.products
                        .map((item, index) => ({
                          key: item.id,
                          text: item.name,
                          value: item.id,
                          categoryId: item.categoryId,
                        }))
                        .filter((item, index) => {
                          const productIds = this.state.selectedProducts.map((key) => key.value);
                          return !productIds.includes(item.value);
                        })}
                      getOptionLabel={(option) => {
                        if (!option) return;
                        if (option.text === undefined) {
                          const item = this.state.products.find((p) => p.id === option);
                          return item.name;
                        } else {
                          return option.text;
                        }
                      }}
                      value={this.state.selectedProducts}
                      onChange={(e, newValue) => {
                        let selected = [...this.state.selectedProducts];
                        if (newValue.length > selected.length) {
                          const item = newValue[newValue.length - 1];
                          selected = [...selected, item.value];
                        } else {
                          selected = newValue;
                        }
                        this.setState({
                          selectedProducts: selected,
                          selectedCategories: [],
                        });
                      }}
                      renderInput={(params) => (
                        <MKInput {...params} label="PRODUK" variant="standard" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12} px={1}>
                    <Autocomplete
                      fullWidth
                      sx={{ marginTop: 1 }}
                      options={this.state.types}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(e, newValue) => this.setState({ selectedType: newValue })}
                      renderInput={(params) => (
                        <MKInput {...params} label="TYPE" variant="standard" fullWidth />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
                <CustomTable
                  togglePreview
                  isLoading={this.state.isLoading}
                  tableHead={[
                    "#",
                    "Keterangan",
                    "Masuk/Keluar",
                    "Dari/Ke",
                    "Tanggal",
                    "Nominal",
                    "Saldo",
                    user && user.userTypeId === 1 && "Akun",
                  ]}
                  tableData={this.state.rows.map((row, index) => [
                    index +
                    1 +
                    (isNaN(this.state.rowsPerPage)
                      ? 0
                      : (this.state.currentPage - 1) * this.state.rowsPerPage),
                    row.TrMutation.remark ? row.TrMutation.remark : "-",
                    userId ? (
                      row.TrMutation.senderId === userId ? (
                        <MKBadge color="error" badgeContent={"Keluar"} />
                      ) : (
                        <MKBadge color="success" badgeContent={"Masuk"} />
                      )
                    ) : row.TrMutation.senderId === user.id ? (
                      <MKBadge color="error" badgeContent={"Keluar"} />
                    ) : (
                      <MKBadge color="success" badgeContent={"Masuk"} />
                    ),
                    userId
                      ? row.TrMutation.senderId === userId
                        ? row.TrMutation.Receiver.name
                        : row.TrMutation.Sender.name
                      : row.TrMutation.senderId !== user.id
                        ? row.TrMutation.Sender
                          ? row.TrMutation.Sender.name
                          : "ADDITION"
                        : row.TrMutation.Receiver
                          ? row.TrMutation.Receiver.name
                          : "DEDUCTION",
                    formatDateTime(row.TrMutation.createdAt),
                    fnumber(row.TrMutation.amount),
                    fnumber(row.amount),
                    user.userTypeId === 1
                      ? row.TrMutation.LoginUser
                        ? row.TrMutation.LoginUser.name
                        : "-"
                      : "",
                  ])}
                  tableFoot={this.state.total}
                  textAlignCells={["center", "center", "center", "right", "right"]}
                  textAlignForCells={[2, 3, 4, 5, 6]}
                  fontWeightCells={["bold", "bold"]}
                  fontWeightForCells={[5, 6]}
                  textAlignHeaders={["center", "center", "center", "right", "right"]}
                  textAlignForHeaders={[2, 3, 4, 5, 6]}
                />
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
                <MKBox width="100%">
                  <Pagination
                    totalButton={3}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadMutation({ currentPage });
                      }
                    }}
                    rowsPerPage={[5, 15, 25, "All"]}
                    defaultRowsPerPage={15}
                    onChangeRowsPerPage={(value) => {
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadMutation({ rowsPerPage: value, currentPage: 1 });
                    }}
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Card>
        </MKBox>
      </>
    );
  }
}

export default BalanceMutation;
