import PropTypes from "prop-types";

// react-countup component
import CountUp from "react-countup";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function FNumber({ children, color, ...rest }) {
  return (
    <MKBox textAlign="center" lineHeight={1}>
      <MKTypography variant="p" color={color} textGradient>
        <CountUp end={children} duration={1} prefix="Rp " separator="." {...rest} />
      </MKTypography>
    </MKBox>
  );
}

// Setting default props for the DefaultCounterCard
FNumber.defaultProps = {
  color: "info",
};

// Typechecking props for the DefaultCounterCard
FNumber.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  children: PropTypes.node.isRequired,
};

export default FNumber;
