module.exports = (caption, value, length) => {
  let result = [[caption, value]];
  if (value.toString().length > length) {
    const first = value.slice(0, length);
    const seconds = value.slice(length);
    result = [
      [caption, first],
      ["", seconds],
    ];
  }

  return result;
};
