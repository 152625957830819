import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import _ from "lodash";
// Create styles
const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL0_WIDTH = 10;
const COLN_WIDTH = (100 - COL0_WIDTH) / 3;
const tableCol = {
  width: COLN_WIDTH + "%",
  borderStyle: BORDER_STYLE,
  borderColor: BORDER_COLOR,
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
};
const preSyles = {
  title: {
    margin: 5,
    fontSize: 14,
    fontWeight: 500,
  },
  date: {
    margin: 5,
    fontSize: 10,
  },
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },

  tableColHeader: {
    ...tableCol,
    borderBottomColor: "#000",
  },
  tableCol,
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 8,
  },
};
const COLS = [5, 12, 20, 10, 10, 5, 8, 10, 20];
const align = ["right", "left", "left", "center", "right", "center", "right", "right", "left"];
COLS.map((item, index) => {
  preSyles["tableColHeader" + index] = {
    ...preSyles.tableColHeader,
    width: item + "%",
    textAlign: align[index],
  };
  preSyles["tableCol" + index] = {
    ...preSyles.tableCol,
    width: item + "%",
    textAlign: align[index],
  };
  return item;
});
const styles = StyleSheet.create(preSyles);

// Create Document Component
class PdfPrintList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "DAFTAR TAGIHAN LISTRIK",
      header: [],
      data: [],
      rowsPerPage: 0,
      totalPages: 0,
    };
    this.myRef = React.createRef();
  }
  componentDidMount() {
    const data = this.props.data;
    // console.log(data)
    if (data.data && data.footer) {
      // const data1 = data.data
      const footer = data.footer;
      const rowFooter = {};
      Object.keys(data.data[0]).map((key, index) => {
        rowFooter[key] = footer[index];
        return key;
      });

      // console.log({ rowFooter })
      // data1.push(rowFooter)
      data.data.push(rowFooter);
    }
    // if(data)
    const rowsPerPage = 36;
    // const n = data.data.length <= 40 ? 2 : 0
    const totalPages = Math.ceil(data.data.length / rowsPerPage); // +2 if 39/40 -> 2 pages

    console.log({ totalPages, datalength: data.data.length });
    this.setState({ ...data, totalPages, rowsPerPage });
  }
  setData = (params) => {
    // const states = Object.keys(params).map(key=>)

    if (params.data && params.footer) {
      const data = params.data;
      const footer = params.footer;
      const rowFooter = {};
      Object.keys(data).map((key, index) => {
        rowFooter[key] = footer[index];
        return key;
      });
      data.push(rowFooter);
      params.data = data;
    }
    this.setState({ ...params });
  };

  render() {
    return (
      <Document>
        {_.times(this.state.totalPages, (currentPage) => {
          const startIndex = currentPage * this.state.rowsPerPage; //- (currentPage === 0 ? 0 : 2);
          // (0 + 1) * 40 - (2) -> index 0
          // (1 + 1) * 40 - (0) -> index 1 - n
          const endIndex = (currentPage + 1) * this.state.rowsPerPage;
          // (currentPage + 1) * this.state.rowsPerPage -
          // (currentPage === 0 ? 2 : 0);

          console.log({ startIndex, endIndex });
          return (
            <Page key={currentPage} style={styles.body} pageNumber={currentPage + 1}>
              {currentPage === 0 && (
                <View style={styles.title}>
                  <Text>{this.state.title.toUpperCase()}</Text>
                </View>
              )}
              {currentPage === 0 && (
                <View style={styles.date}>
                  <Text>
                    {`Tanggal cetak : ${moment().format("dddd, DD MMMM YYYY HH:mm")}`.toUpperCase()}
                  </Text>
                </View>
              )}
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  {this.state.header.map((item, index) => (
                    <View
                      key={index}
                      style={styles["tableColHeader" + index] ?? styles.tableColHeader}
                    >
                      <Text style={styles.tableCellHeader}>{item}</Text>
                    </View>
                  ))}
                </View>
                {this.state.data.slice(startIndex, endIndex).map((item, index) => (
                  <View key={index} style={styles.tableRow}>
                    {Object.keys(item).map((key, idx) => (
                      <View key={idx} style={styles["tableCol" + idx] ?? styles.tableCol}>
                        <Text style={styles.tableCell}>{item[key].toString()}</Text>
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            </Page>
          );
        })}
      </Document>
    );
  }
}
export default PdfPrintList;
