import React from "react";
import { withTranslation } from "react-i18next";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Autocomplete, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

import { makeStyles, withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

// Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";

import secureStorage from "libraries/secureStorage";
import { SalesType, DirectUserType, EndUsers } from "helper/userLibs";
import { fnumber } from "helper/Helper";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import _ from "lodash";
import axios from "axios";
import Config from "config";
import moment from "moment";

import Pagination from "contents/components/Pagination";
import Loaded from "contents/components/Loaded";
import GSearchActions from "contents/components/GSearchActions";
// import ExportExcel from "contents/components/ExportExcel";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#e8f4f7",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#cbe3ee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const styles = {
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: "#f9fafb",
  },
};

class ReportTransactionRekap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      total: null,
      // #	UID	NAMA	ITEM	ADMIN	BLTH	PENDAPATAN	TOTAL ADMIN	TOTAL NOMINAL	TOTAL
      header: [
        "#",
        "UID",
        "NAMA",
        "PRODUK",
        "ADMIN",
        "QTY",
        "PENDAPATAN",
        "TOTAL ADMIN",
        "TOTAL NOMINAL",
        "TOTAL",
      ],

      selectedEndUsers: [],
      endUser: [],

      selectedProducts: [],
      products: [],

      selectedPartners: [],
      partners: [],

      selectedCategories: [],
      categories: [],

      groups: [],
      groupBy: "user",

      salesType: 0,

      user: secureStorage.getItem("account"),

      rowsPerPage: 15,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      currentPageData: [],

      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),

      userTypes: [],

      isLoading: false,
      buttonDownloadDisabled: true,
    };

    this.exportTransaction = React.createRef();
  }

  componentDidMount() {
    const user = secureStorage.getItem("account");
    const salesType = user && this.initSalesType();

    const { groups, groupBy } = this.initGroups(salesType);

    user && this.initUserTypes({ salesType });
    // user && user.userTypeId === 1 && this.loadPartner();
  }

  initGroups(salesType) {
    const user = secureStorage.getItem("account");
    let groups = [];
    if (user) {
      if ([1].indexOf(user.userTypeId) !== -1) {
        switch (salesType) {
          case 0:
            groups = ["user", "sldm", "self"];
            break;
          case 1:
          case 4:
            groups = ["user", "subca", "ca", "self"];
            break;
          case 5:
            groups = ["user", "borf", "self"];
            break;
          case 2:
          case 3:
            groups = ["user", "self"];
            break;
          case 6:
          case 7:
            groups = ["user", "subca2", "subca1", "ca", "mitra", "self"];
            break;
          default:
        }
      } else if ([2, 9].indexOf(user.userTypeId) !== -1) groups = ["user", "subca", "self"];
      else if ([3, 6, 10, 12, 17, 22].indexOf(user.userTypeId) !== -1) groups = ["user", "self"];
      else if ([14, 19].indexOf(user.userTypeId) !== -1)
        groups = ["user", "subca2", "subca1", "ca", "self"];
      else if ([15, 20].indexOf(user.userTypeId) !== -1)
        groups = ["user", "subca2", "subca1", "self"];
      else if ([16, 21].indexOf(user.userTypeId) !== -1)
        groups = ["user", "subca2", "subca1", "self"];
      else if ([17, 22].indexOf(user.userTypeId) !== -1) groups = ["user", "subca2", "self"];
    }

    let groupBy;
    if (groups.length > 0) groupBy = groups[0];
    else groupBy = "self";
    this.setState({ groups, groupBy });
    return { groups, groupBy };
  }

  initSalesType() {
    // ['DM 2 Levels','TL 3 Levels','H2H DM','H2H TL',"DP 3 Levels","H2H RF","FD 5 Levels","FT 5 Levels"]
    const user = secureStorage.getItem("account");
    let salesType = 0;
    if ([1, 6].includes(user.userTypeId)) salesType = 0;
    else if ([2, 3].includes(user.userTypeId)) salesType = 1;
    else if ([5].includes(user.userTypeId)) salesType = 2;
    else if ([8].includes(user.userTypeId)) salesType = 2;
    else if ([9, 10].includes(user.userTypeId)) salesType = 4;
    else if ([12].includes(user.userTypeId)) salesType = 5;
    else if ([14, 15, 16, 17].includes(user.userTypeId)) salesType = 6;
    else if ([19, 20, 21, 22].includes(user.userTypeId)) salesType = 7;
    else if ([24, 25].includes(user.userTypeId)) salesType = 8;
    else if ([26, 27, 28].includes(user.userTypeId)) salesType = 9;
    this.setState({ salesType });

    return salesType;
  }

  initUserTypes({ salesType }) {
    const user = secureStorage.getItem("account");
    axios
      .post(`${Config.EndPoint}/transaction/usertypes`)
      .then((res) => {
        const userTypes = res.data;
        this.setState({ userTypes });
        this.loadUserDropdown({
          salesType,
          parents: [user],
          userTypes,
          parentTypeId: user.userTypeId,
          init: true,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  // loadPartner() {
  //   axios
  //     .post(Config.ApiSunrise + "/transaction/partner")
  //     .then((res) => {
  //       this.setState({ partners: res.data });
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  // }

  loadUserDropdown(params) {
    const user = secureStorage.getItem("account");
    const userTypes = params.userTypes ? params.userTypes : this.state.userTypes;
    const salesType = params.salesType ? params.salesType : this.state.salesType;
    const payload = {
      salesType,
      parents: params.parents && params.parents,
    };
    axios
      .post(`${Config.EndPoint}/transaction/user`, payload)
      .then((res) => {
        let userTypeIdx = params.parentTypeId;
        if (params.init && user.userTypeId === 1) userTypeIdx = DirectUserType[salesType] - 1;
        this.setState({ [userTypes[userTypeIdx].name]: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  clearUserDropDown = () => {
    this.state.userTypes.map((userType) => {
      this.state[userType.id] && this.setState({ [userType.id]: undefined });
      return userType;
    });
  };

  getUserDropDown = (userTypeId) => {
    if (this.state.userTypes.length > 0 && this.state[this.state.userTypes[userTypeId - 1].name]) {
      return (
        <Grid item xl={4} lg={4} md={4} sm={4} xs={6} px={1}>
          <Autocomplete
            multiple
            id="tags-standard"
            fullWidth
            sx={{ marginTop: 1 }}
            options={this.state[this.state.userTypes[userTypeId - 1].name].map((item, index) => ({
              key: item.id,
              text: item.name,
              value: item.id,
            }))}
            getOptionLabel={(option) => {
              return option?.text;
            }}
            onChange={(e, newValue) => {
              const value = newValue.map((key) => key.value);
              if (value.length === 0 && this.state.userTypes[userTypeId])
                this.setState({
                  [this.state.userTypes[userTypeId].name]: undefined,
                  [this.state.userTypes[userTypeId].id]: undefined,
                });

              if (EndUsers.indexOf(userTypeId) === -1 && value.length > 0)
                this.loadUserDropdown({
                  parents: this.state[this.state.userTypes[userTypeId - 1].name].filter(
                    (item) => value.indexOf(item.id) !== -1
                  ),
                  parentTypeId: userTypeId,
                });
              const val = this.state[this.state.userTypes[userTypeId - 1].name]
                .filter((item) => value.indexOf(item.id) !== -1)
                .map((item) => item);
              this.setState({
                [this.state.userTypes[userTypeId - 1].id]: val,
              });
            }}
            renderInput={(params) => (
              <MKInput
                {...params}
                placeholder={this.state.userTypes[userTypeId - 1].name}
                variant="standard"
                fullWidth
              />
            )}
          />
        </Grid>
      );
    }
  };

  loadTransaction = (params) => {
    const user = secureStorage.getItem("account");
    const userTypes = this.state.userTypes;
    const users = _.flattenDeep(
      userTypes
        .filter((item) => item.id !== 1 && this.state[item.id])
        .map((item) => this.state[item.id])
    );
    this.setState({ isLoading: true });
    const groups = params && params.groups ? params.groups : this.state.groups;
    const payload = {
      startDate: params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      utcOffset: moment().utcOffset(),
      // header : params && params.header?params.header : this.state.header,
      //users payload
      parents: users.length > 0 ? users : [user],

      products:
        this.state.selectedProducts &&
        this.state.selectedProducts.length > 0 &&
        this.state.selectedProducts,
      categories:
        this.state.selectedCategories &&
        this.state.selectedCategories.length > 0 &&
        this.state.selectedCategories,
      partners:
        this.state.selectedPartners &&
        this.state.selectedPartners.length > 0 &&
        this.state.selectedPartners,
      salesType: params && params.salesType ? params.salesType : this.state.salesType,
      groupBy:
        this.state.groupBy === "user" && groups.length === 2 ? "directUser" : this.state.groupBy,
      // pagination payload>>
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
      // <<pagination payload
    };
    axios
      .post(`${Config.EndPoint}/transaction/byproduct`, payload)
      .then((res) => {
        const { t } = this.props;
        this.setState({
          rows: res.data.groupedUserSales,
          total: res.data.total,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          startDate: payload.startDate,
          endDate: payload.endDate,
          // header:res.data.header.map(item=>t(item)),
          // header: res.data.header.map((item, i) => {
          //   let label = item.toLowerCase().replace(/qty/g, "blth");
          //   return t(label === "idpel" || label === "blth" ? label : item);
          // }),
          isLoading: false,
        });
        this.exportTransaction.current.updateDataset(res.data.dataset);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        // console.log(error);
      });
  };

  getParent = (parent) => {
    const user = secureStorage.getItem("account");
    if (parent && parent.Parent && parent.userTypeId > user.userTypeId + 1) {
      return `${parent.userType}:${parent.name} - \n${this.getParent(parent.Parent)}`;
    } else {
      if (parent) return `${parent.userType}:${parent.name}`;
      else return "";
    }
  };

  renderFoot = (value) => {
    return (
      <MKBox
        component="th"
        py={2}
        pr={1}
        sx={({ typography: { size, fontWeightBold }, borders: { borderWidth, borderColor } }) => ({
          fontSize: "12px",
          borderBottom: `${borderWidth[1]} solid ${borderColor}`,
          fontWeight: "bold",
          textAlign: "right",
        })}
      >
        {value}
      </MKBox>
    );
  };

  rowBody = (value, opt, rest) => {
    return (
      <MKBox
        component="td"
        sx={{
          fontSize: "12px",
          padding: "8px 8px!important",
          ...opt,
        }}
      >
        {value}
      </MKBox>
    );
  };

  render() {
    const { classes } = this.props;
    const user = secureStorage.getItem("account");
    const textAlignForHeaders = [0, ..._.times(this.state.header.length - 4, (n) => n + 4)];
    return (
      <>
        <MKBox wdith="100%" component="section" py={12} mx={2}>
          {/* <Loaded open={this.state.isLoading} /> */}
          {/* <MKBox p={3} coloredShadow="secondary" borderRadius="5px" mb={3} bgColor="white" shadow="xl"> */}
          <Card sx={{ padding: "25px", marginTop: "10px" }}>
            <Grid container justifyContent="center">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xl={2} lg={2} md={2} sm={12} xs={12} mb={{ xs: 1, md: 0 }}>
                    <MKBox
                      color="rgba(0,0,0,.6)"
                      bgColor="#e8e8e8"
                      borderRadius="lg"
                      shadow="lg"
                      textAlign="center"
                      justifyContent="center"
                      opacity={1}
                      p={1}
                    >
                      Laporan Rekap
                    </MKBox>
                  </Grid>
                  <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GSearchActions
                      onSubmit={this.loadTransaction}
                      handleChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                    />
                  </Grid>
                </Grid>

                {/* <Grid container justifyContent="center" mt={2}>
                  {user && user.userTypeId === 1 && (
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} px={1}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        fullWidth
                        sx={{ marginTop: 1 }}
                        options={this.state.partners
                          .map((item, index) => ({
                            key: item.id,
                            text: item.name,
                            value: item.id,
                          }))
                          .filter((item, index) => {
                            return !this.state.selectedPartners.includes(item.value);
                          })}
                        getOptionLabel={(option) => {
                          if (!option) return;
                          if (option.text === undefined) {
                            const item = this.state.partners.find((c) => c.id === option);
                            return item.name;
                          } else {
                            return option.text;
                          }
                        }}
                        value={this.state.selectedPartners}
                        onChange={(e, newValue) => {
                          let selected = [...this.state.selectedPartners];
                          if (newValue.length > selected.length) {
                            const item = newValue[newValue.length - 1];
                            selected = [...selected, item.value];
                          } else {
                            selected = newValue;
                          }
                          this.setState({
                            selectedPartners: selected,
                          });
                        }}
                        renderInput={(params) => (
                          <MKInput {...params} placeholder="Partner" variant="standard" fullWidth />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12} px={1}>
                    {this.state.groups.length > 0 && (
                      <Autocomplete
                        fullWidth
                        sx={{ marginTop: 1 }}
                        options={this.state.groups}
                        value={this.state.groupBy}
                        getOptionLabel={(option) => {
                          if (!option) return;
                          return option;
                        }}
                        onChange={(e, newValue) => {
                          this.setState({ groupBy: newValue });
                        }}
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            placeholder="Group By"
                            variant="standard"
                            fullWidth
                          />
                        )}
                      />
                    )}
                  </Grid>
                  {user && user.userTypeId === 1 && (
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} px={1}>
                      <Autocomplete
                        fullWidth
                        sx={{ marginTop: 1 }}
                        options={SalesType.map((key, i) => ({ id: i, name: key }))}
                        value={this.state.salesType}
                        getOptionLabel={(option) => {
                          if (!option) return SalesType[option];
                          if (option.name) {
                            return option?.name;
                          } else {
                            return SalesType[option];
                          }
                        }}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            this.setState({
                              salesType: newValue.id,
                            });
                            this.clearUserDropDown();
                            this.loadUserDropdown({
                              salesType: newValue.id,
                              parents: [user],
                              // userTypes,
                              parentTypeId: user.userTypeId,
                              init: true,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            placeholder="Sales Type"
                            variant="standard"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                {user && (
                  <Grid container justifyContent="center" mt={2}>
                    {user.userTypeId === 1 &&
                      this.state.salesType === 0 &&
                      this.getUserDropDown(DirectUserType[0])}
                    {[1, 6].includes(user.userTypeId) &&
                      [0].includes(this.state.salesType) &&
                      this.getUserDropDown(7)}

                    {user.userTypeId === 1 &&
                      this.state.salesType === 1 &&
                      this.getUserDropDown(DirectUserType[1])}
                    {[1, 2].includes(user.userTypeId) &&
                      [1].includes(this.state.salesType) &&
                      this.getUserDropDown(3)}
                    {[1, 2, 3].includes(user.userTypeId) &&
                      [1].includes(this.state.salesType) &&
                      this.getUserDropDown(4)}

                    {user.userTypeId === 1 &&
                      this.state.salesType === 2 &&
                      this.getUserDropDown(DirectUserType[2])}
                    {user.userTypeId === 1 &&
                      this.state.salesType === 3 &&
                      this.getUserDropDown(DirectUserType[3])}

                    {user.userTypeId === 1 &&
                      this.state.salesType === 4 &&
                      this.getUserDropDown(DirectUserType[4])}
                    {[1, 9].includes(user.userTypeId) &&
                      [4].includes(this.state.salesType) &&
                      this.getUserDropDown(10)}
                    {[1, 9, 10].includes(user.userTypeId) &&
                      [4].includes(this.state.salesType) &&
                      this.getUserDropDown(11)}

                    {user.userTypeId === 1 &&
                      this.state.salesType === 5 &&
                      this.getUserDropDown(DirectUserType[5])}
                    {[1, 12].includes(user.userTypeId) &&
                      [5].includes(this.state.salesType) &&
                      this.getUserDropDown(13)}

                    {user.userTypeId === 1 &&
                      this.state.salesType === 6 &&
                      this.getUserDropDown(DirectUserType[6])}
                    {[1, 14].includes(user.userTypeId) &&
                      [6].includes(this.state.salesType) &&
                      this.getUserDropDown(15)}
                    {[1, 14, 15].includes(user.userTypeId) &&
                      [6].includes(this.state.salesType) &&
                      this.getUserDropDown(16)}
                    {[1, 14, 15, 16].includes(user.userTypeId) &&
                      [6].includes(this.state.salesType) &&
                      this.getUserDropDown(17)}
                    {[1, 14, 15, 16, 17].includes(user.userTypeId) &&
                      [6].includes(this.state.salesType) &&
                      this.getUserDropDown(18)}

                    {user.userTypeId === 1 &&
                      this.state.salesType === 7 &&
                      this.getUserDropDown(DirectUserType[7])}
                    {[1, 19].includes(user.userTypeId) &&
                      [7].includes(this.state.salesType) &&
                      this.getUserDropDown(20)}
                    {[1, 19, 20].includes(user.userTypeId) &&
                      [7].includes(this.state.salesType) &&
                      this.getUserDropDown(21)}
                    {[1, 19, 20, 21].includes(user.userTypeId) &&
                      [7].includes(this.state.salesType) &&
                      this.getUserDropDown(22)}
                    {[1, 19, 20, 21, 22].includes(user.userTypeId) &&
                      [7].includes(this.state.salesType) &&
                      this.getUserDropDown(23)}

                    {user.userTypeId === 1 &&
                      this.state.salesType === 8 &&
                      this.getUserDropDown(DirectUserType[8])}
                    {[1, 24].includes(user.userTypeId) &&
                      [8].includes(this.state.salesType) &&
                      this.getUserDropDown(25)}

                    {user.userTypeId === 1 &&
                      this.state.salesType === 9 &&
                      this.getUserDropDown(DirectUserType[9])}
                    {[1, 26].includes(user.userTypeId) &&
                      [9].includes(this.state.salesType) &&
                      this.getUserDropDown(27)}
                    {[1, 26, 27].includes(user.userTypeId) &&
                      [9].includes(this.state.salesType) &&
                      this.getUserDropDown(28)}
                    {[1, 26, 27, 28].includes(user.userTypeId) &&
                      [9].includes(this.state.salesType) &&
                      this.getUserDropDown(29)}
                  </Grid>
                )} */}
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={1}>
                <MKBox width="100%" display="flex" justifyContent="flex-end">
                  {/* <ExportExcel
                    title={`DOWNLOAD ${moment().format("YYYY-MM-DD HH:mm")}`}
                    filename={`Trx Report Rekap ${moment().format("YYYY-MM-DD HH:mm")}`}
                    ref={this.exportTransaction}
                  /> */}
                  {/* <MKButton variant="outlined" color="info" 
										disabled={this.state.buttonDownloadDisabled}
										onClick={this.donwloadFile}
										size="small">
										<Icon sx={{ mr: 1 }}>download</Icon>
										{`DOWNLOAD ${moment().format('YYYY-MM-DD HH:mm')}`}
									</MKButton> */}
                </MKBox>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
                <MKBox width="100%" sx={{ overflow: "hidden" }}>
                  <TableContainer
                    sx={{
                      maxHeight: 500,
                    }}
                  >
                    {this.state.isLoading ? (
                      <MKBox display="flex" justifyContent="center">
                        <CircularProgress color="inherit" />
                      </MKBox>
                    ) : (
                      <Table stickyHeader aria-label="customized table">
                        <MKBox component="thead">
                          <TableRow
                            className={classes.stickyHeader}
                            sx={({
                              palette: { socialMediaColors, white },
                              functions: { rgba },
                            }) => ({
                              backgroundColor: `${rgba(
                                socialMediaColors.instagram.main,
                                0.8
                              )} !important`,
                              backdropFilter: `saturate(200%) blur(30px)`,
                            })}
                          >
                            {this.state.header.map((key, i) => {
                              const textAlign = textAlignForHeaders.includes(i)
                                ? { textAlign: "right" }
                                : {};
                              return (
                                <MKBox
                                  component="th"
                                  p={2}
                                  key={`column-${i}`}
                                  sx={({
                                    typography: { size, fontWeightBold },
                                    borders: { borderWidth, borderColor },
                                  }) => ({
                                    color: "#fff",
                                    fontSize: size.xs,
                                    fontWeight: fontWeightBold,
                                    borderBottom: `${borderWidth[1]} solid ${borderColor}`,
                                    ...textAlign,
                                  })}
                                >
                                  {key.toUpperCase()}
                                </MKBox>
                              );
                            })}
                          </TableRow>
                        </MKBox>
                        <TableBody>
                          {_.orderBy(this.state.rows, "name").map((userItem, idx) => {
                            let qty = 0;
                            let partner = 0;
                            let revenue = 0;

                            let admin = 0;
                            let amount = 0;
                            let total = 0;
                            const sales = [...userItem.sales, "-"];

                            const SaleFee = {};
                            const start =
                              user.userTypeId === 1
                                ? this.state.header.indexOf("Partner") + 1
                                : this.state.header.indexOf("blth") + 1;
                            const end = this.state.header.indexOf("Total Admin") - 2;
                            for (let key = start; key <= end; key++)
                              SaleFee[this.state.header[key]] = 0;

                            return _.orderBy(sales, "product").map((item, index) => {
                              if (index < userItem.sales.length) {
                                qty += item.qty;
                                partner += item.partner;
                                revenue += item.revenue;

                                Object.keys(SaleFee).map((key) => {
                                  SaleFee[key] += item.SaleFee[key] ? item.SaleFee[key] : 0;
                                  return key;
                                });

                                admin += item.admin * item.qty;
                                amount += item.amount;
                                total += item.admin * item.qty + item.amount;
                              }

                              if (index === 0) {
                                return this.state["u" + idx] ? (
                                  <StyledTableRow hover tabIndex={-1} key={idx + index}>
                                    {this.rowBody(idx + 1, {})}
                                    {this.rowBody(userItem.id.toString().padStart(6, 0), {})}
                                    {this.rowBody(userItem.name, {})}
                                    {this.state.header.indexOf("Parent") !== -1 &&
                                      this.rowBody(this.getParent(userItem.Parent), {})}
                                    {this.rowBody(item.product, {})}
                                    {this.rowBody(fnumber(item.admin), { textAlign: "right" })}
                                    {this.rowBody(fnumber(item.qty), { textAlign: "right" })}
                                    {user.userTypeId === 1
                                      ? this.rowBody(fnumber(item.partner), { textAlign: "right" })
                                      : null}

                                    {Object.keys(SaleFee).map((key) =>
                                      this.rowBody(
                                        fnumber(item.SaleFee[key]),
                                        {
                                          textAlign: "right",
                                        },
                                        key
                                      )
                                    )}

                                    {this.rowBody(fnumber(item.revenue), { textAlign: "right" })}
                                    {this.rowBody(fnumber(item.admin * item.qty), {
                                      textAlign: "right",
                                    })}
                                    {this.rowBody(fnumber(item.amount), { textAlign: "right" })}
                                    {this.rowBody(fnumber(item.admin * item.qty + item.amount), {
                                      textAlign: "right",
                                    })}
                                  </StyledTableRow>
                                ) : null;
                              } else if (index < userItem.sales.length && this.state["u" + idx]) {
                                return (
                                  <StyledTableRow hover tabIndex={-1} key={idx + index}>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    {this.state.header.indexOf("Parent") !== -1 &&
                                      this.rowBody(this.getParent(userItem.Parent), {})}

                                    {this.rowBody(item.product, {})}
                                    {this.rowBody(fnumber(item.admin), { textAlign: "right" })}
                                    {this.rowBody(fnumber(item.qty), { textAlign: "right" })}

                                    {user.userTypeId === 1
                                      ? this.rowBody(fnumber(item.partner), { textAlign: "right" })
                                      : null}

                                    {Object.keys(SaleFee).map((key) =>
                                      this.rowBody(
                                        fnumber(item.SaleFee[key]),
                                        {
                                          textAlign: "right",
                                        },
                                        key
                                      )
                                    )}

                                    {this.rowBody(fnumber(item.revenue), { textAlign: "right" })}
                                    {this.rowBody(fnumber(item.admin * item.qty), {
                                      textAlign: "right",
                                    })}
                                    {this.rowBody(fnumber(item.amount), { textAlign: "right" })}
                                    {this.rowBody(fnumber(item.admin * item.qty + item.amount), {
                                      textAlign: "right",
                                    })}
                                  </StyledTableRow>
                                );
                              } else if (index === userItem.sales.length) {
                                return (
                                  <StyledTableRow
                                    hover
                                    fontWeight="bold"
                                    tabIndex={-1}
                                    key={idx + index}
                                  >
                                    {this.rowBody(!this.state["u" + idx] && idx + 1, {
                                      fontWeight: "bold",
                                    })}
                                    {this.rowBody(
                                      !this.state["u" + idx] &&
                                        userItem.id.toString().padStart(6, 0),
                                      { fontWeight: "bold" }
                                    )}
                                    {this.rowBody(!this.state["u" + idx] && userItem.name, {
                                      fontWeight: "bold",
                                    })}

                                    {this.state.header.indexOf("Parent") !== -1 ? (
                                      !this.state["u" + idx] ? (
                                        this.rowBody(this.getParent(userItem.Parent), {
                                          fontWeight: "bold",
                                        })
                                      ) : (
                                        <TableCell></TableCell>
                                      )
                                    ) : null}

                                    <MKBox
                                      component="td"
                                      sx={{
                                        fontSize: "12px",
                                        padding: "8px 8px!important",
                                        textAlign: "right",
                                      }}
                                    >
                                      {!this.state["u" + idx] ? (
                                        <MKButton
                                          size="small"
                                          color="info"
                                          variant="gradient"
                                          onClick={() => this.setState({ ["u" + idx]: true })}
                                        >
                                          Tampilkan
                                          {/* <Icon ml={1}>arrow_down</Icon> */}
                                          <ArrowDownwardIcon ml={2} size="lg" color="inherit" />
                                        </MKButton>
                                      ) : (
                                        <MKButton
                                          size="small"
                                          color="error"
                                          variant="gradient"
                                          onClick={() => this.setState({ ["u" + idx]: false })}
                                        >
                                          Sembunyikan
                                          <ArrowUpwardIcon ml={2} size="lg" color="inherit" />
                                        </MKButton>
                                      )}
                                    </MKBox>

                                    {this.rowBody("TOTAL", {
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    })}
                                    {this.rowBody(fnumber(qty), {
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    })}
                                    {user.userTypeId === 1 &&
                                      this.rowBody(fnumber(partner), {
                                        textAlign: "right",
                                        fontWeight: "bold",
                                      })}

                                    {Object.keys(SaleFee).map((key) =>
                                      this.rowBody(
                                        fnumber(SaleFee[key]),
                                        {
                                          textAlign: "right",
                                          fontWeight: "bold",
                                        },
                                        key
                                      )
                                    )}

                                    {this.rowBody(fnumber(revenue), {
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    })}
                                    {this.rowBody(fnumber(admin), {
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    })}
                                    {this.rowBody(fnumber(amount), {
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    })}
                                    {this.rowBody(fnumber(total), {
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    })}
                                  </StyledTableRow>
                                );
                              } else {
                                return null;
                              }
                            });
                          })}
                        </TableBody>
                        {this.state.total && this.state.rows.length > 1 && (
                          <MKBox component="tfoot">
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              {this.state.header.indexOf("Parent") !== -1 && (
                                <TableCell></TableCell>
                              )}
                              {this.renderFoot("Grand Total")}
                              <TableCell></TableCell>
                              {this.renderFoot(fnumber(this.state.total.qty))}
                              {user.userTypeId === 1 &&
                                this.renderFoot(fnumber(this.state.total.partner))}
                              {Object.keys(this.state.total.SaleFee).map((key) =>
                                this.renderFoot(fnumber(this.state.total.SaleFee[key]))
                              )}
                              {this.renderFoot(fnumber(this.state.total.revenue))}
                              {this.renderFoot(fnumber(this.state.total.admin))}
                              {this.renderFoot(fnumber(this.state.total.amount))}
                              {this.renderFoot(
                                fnumber(this.state.total.admin + this.state.total.amount)
                              )}
                            </TableRow>
                          </MKBox>
                        )}
                      </Table>
                    )}
                  </TableContainer>
                </MKBox>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
                <MKBox width="100%">
                  <Pagination
                    totalButton={3}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadTransaction({ currentPage });
                      }
                    }}
                    rowsPerPage={[5, 15, 25, "All"]}
                    defaultRowsPerPage={15}
                    onChangeRowsPerPage={(value) => {
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadTransaction({ rowsPerPage: value, currentPage: 1 });
                    }}
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Card>
          {/* </MKBox> */}
        </MKBox>
      </>
    );
  }
}

export default withStyles(styles)(ReportTransactionRekap);
