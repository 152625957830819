import React from 'react'
import { useParams } from 'react-router-dom'

const withRouter = WrapperComponent => props => {
    const params = useParams()

    return (
        <WrapperComponent
            {...props}
            params={params}
        />
    )
}

export default withRouter