const Provinces = [
    { name: 'Aceh', timeZone: 7 },
    { name: 'Sumatra Utara', timeZone: 7 },
    { name: 'Sumatra Barat', timeZone: 7 },
    { name: 'Riau', timeZone: 7 },
    { name: 'Kepulauan Riau', timeZone: 7 },
    { name: 'Kepulauan Bangka Belitung', timeZone: 7 },
    { name: 'Jambi', timeZone: 7 },
    { name: 'Bengkulu', timeZone: 7 },
    { name: 'Sumatra Selatan', timeZone: 7 },
    { name: 'Lampung', timeZone: 7 },
    { name: 'Banten', timeZone: 7 },
    { name: 'Daerah Khusus Ibukota Jakarta', timeZone: 7 },
    { name: 'Jawa Barat', timeZone: 7 },
    { name: 'Jawa Tengah', timeZone: 7 },
    { name: 'Daerah Istimewa Yogyakarta', timeZone: 7 },
    { name: 'Jawa Timur', timeZone: 7 },
    { name: 'Kalimantan Barat', timeZone: 7 },
    { name: 'Kalimantan Tengah', timeZone: 7 },

    { name: 'Bali', timeZone: 8 },
    { name: 'Nusa Tenggara Barat', timeZone: 8 },
    { name: 'Nusa Tenggara Timur', timeZone: 8 },
    { name: 'Kalimantan Selatan', timeZone: 8 },
    { name: 'Kalimantan Timur', timeZone: 8 },
    { name: 'Kalimantan Utara', timeZone: 8 },
    { name: 'Sulawesi Utara', timeZone: 8 },
    { name: 'Gorontalo', timeZone: 8 },
    { name: 'Sulawesi Tengah', timeZone: 8 },
    { name: 'Sulawesi Barat', timeZone: 8 },
    { name: 'Sulawesi Selatan', timeZone: 8 },
    { name: 'Sulawesi Tenggara', timeZone: 8 },

    { name: 'Maluku', timeZone: 9 },
    { name: 'Maluku Utara', timeZone: 9 },
    { name: 'Papua', timeZone: 9 },
    { name: 'Papua Barat', timeZone: 9 },
]
export default Provinces;