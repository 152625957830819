import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { getMenus, routes } from "routes";
import secureStorage from "libraries/secureStorage";
import axios from "axios";
import Config from "config";
import Resource from "libraries/resource";
import LOGO from "assets/images/Logo.png";
import MKBox from "components/MKBox";

export default function Layout() {
  const [info, setInfo] = useState("");
  const [user, setUser] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    initResources();
    initDotmatrix();
    const account = secureStorage.getItem("account");
    if (!account) {
      navigate("/login");
    }

    setUser(account);
  }, []);

  const initDotmatrix = () => {
    // console.log('test')
    // if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") === -1) {
    //   secureStorage.setItem("dotmatrix", "offline");
    // } else {
    axios
      .post("http://127.0.0.1:4321/check")
      .then((res) => {
        // console.log(res.data);
        secureStorage.setItem("dotmatrix", res.data);
      })
      .catch((err) => {
        console.log(err);
        secureStorage.setItem("dotmatrix", "offline");
      });
    // }
  };

  const initResources = () => {
    Resource.checkUpdate();
    Resource.getInfo()
      .then((info) => {
        setInfo(info);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogout = (e) => {
    axios.post(`${Config.EndPoint}/user/logout`).catch((err) => {});
    secureStorage.removeItem("account");
    secureStorage.removeItem("token");
    navigate("/login");
    navigate(0);
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        brand={
          <MKBox
            component="img"
            src={window.location.hostname === "payfren.id" ? LOGO : "/android-icon-512x512.png"}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10,
              height: 100,
              width: 100,
              borderRadius: "50%",
              maxHeight: { xs: 100, md: 100 },
              maxWidth: { xs: 100, md: 100 },
            }}
          />
        }
        light
        action={{
          type: "internal",
          onClick: handleLogout,
          route: "/dashboard",
          label: "keluar",
          color: "error",
        }}
        sticky
      />
      <Outlet />
    </>
  );
}
