import React from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableFooter,
//   TableHead,
//   TableRow,
//   Paper,
// } from '@mui/material';
import Paper from "@mui/material/Paper";
import MKBox from "components/MKBox";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

const CustomTh = ({ children, align }) => {
  return (
    <MKBox
      component="th"
      width={"auto"}
      pt={1.5}
      pb={1.25}
      // pl={align === "left" ? pl : 3}
      // pr={align === "right" ? pr : 3}
      textAlign={align}
      color="secondary"
      opacity={0.8}
      sx={({ typography: { size, fontWeightBold }, borders: { borderWidth, borderColor } }) => ({
        fontSize: size.md,
        fontWeight: fontWeightBold,
        borderBottom: `${borderWidth[1]} solid ${borderColor}`,
      })}
      style={{
        backgroundColor: "#fff",
      }}
    >
      {children}
    </MKBox>
  );
};
const CustomTd = ({ children, align, index }) => {
  return (
    <MKBox
      component="th"
      p={0.5}
      textAlign={align}
      sx={({ borders: { borderWidth, borderColor } }) => ({
        borderBottom: 0,
      })}
      style={{
        backgroundColor: index % 2 == 0 ? "#c7efff" : "#fff",
      }}
    >
      {children}
    </MKBox>
  );
};

const SimpleTable = ({ columns, rows, footer }) => {
  // Sample data for the table
  //   const rows = [
  //     { id: 1, name: 'Item 1', price: 100 },
  //     { id: 2, name: 'Item 2', price: 200 },
  //     { id: 3, name: 'Item 3', price: 300 },
  //   ];

  // Calculate the total price for the footer
  //   const totalPrice = rows.reduce((sum, row) => sum + row.price, 0);

  return (
    <TableContainer component={Paper} style={{ maxHeight: 500 }}>
      <MuiTable>
        {/* Table Header */}
        <MKBox component="thead">
          <TableRow
            style={{
              position: "sticky",
              top: 0,
              zIndex: 10,
              // backgroundColor: "#fff",
              minWidth: 50,
              // color : '#000',
            }}
          >
            <CustomTh align="center">NO</CustomTh>
            <CustomTh align="left">IDPEL</CustomTh>
            <CustomTh align="center">NAMA</CustomTh>
            <CustomTh align="center">PROSES</CustomTh>
            <CustomTh align="center">STATUS</CustomTh>
            <CustomTh align="right">RPTAG</CustomTh>
            <CustomTh align="center">BLTH</CustomTh>
            <CustomTh align="right">ADMIN</CustomTh>
            <CustomTh align="right">TOTAL</CustomTh>
            <CustomTh align="center">KET</CustomTh>
            <CustomTh align="center">HAPUS</CustomTh>
          </TableRow>
        </MKBox>
        {/* Table Body */}
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.no}>
              <CustomTd align="center" index={index}>
                {row.no}
              </CustomTd>
              <CustomTd align="left" index={index}>
                {row.idpel}
              </CustomTd>
              <CustomTd align="center" index={index}>
                {row.nama}
              </CustomTd>
              <CustomTd align="center" index={index}>
                {row.proses}
              </CustomTd>
              <CustomTd align="center" index={index}>
                {row.status}
              </CustomTd>
              <CustomTd align="right" index={index}>
                {row.rptag}
              </CustomTd>
              <CustomTd align="center" index={index}>
                {row.blth}
              </CustomTd>
              <CustomTd align="right" index={index}>
                {row.admin}
              </CustomTd>
              <CustomTd align="right" index={index}>
                {row.total}
              </CustomTd>
              <CustomTd align="center" index={index}>
                {row.keterangan}
              </CustomTd>
              <CustomTd align="center" index={index}>
                {row.hapus}
              </CustomTd>
            </TableRow>
          ))}
        </TableBody>
        {/* Table Footer */}
        <MKBox component="tfoot">
          <TableRow
            style={{
              position: "sticky",
              bottom: 0,
              zIndex: 10,
              backgroundColor: "#fff",
              color: "#000",
              fontWeight: "bold",
            }}
          >
            {footer.map((item, index) => (
              <CustomTh align={item.align} key={index}>
                {item.value}
              </CustomTh>
            ))}
          </TableRow>
        </MKBox>
      </MuiTable>
    </TableContainer>
  );
};

export default SimpleTable;
