import React, { createRef } from "react";
// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { Zoom } from "@mui/material";
import MKInput from "components/MKInput";
import { fnumber } from "helper/Helper";
import Confirmation from "../Confirmation";

export default class PaymentConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      amount: 0,
      totalAmount: 0,
      buttonDisabled: true,
    };
    this.inputPaymentRef = createRef();
    this.confirmRef = createRef();
  }

  toggleModal = () => this.setShow(!this.state.show);

  setShow = (show, totalAmount) => {
    this.setState({ show, totalAmount, amount: 0 });
  };

  render() {
    return (
      <>

        <Modal
          open={this.state.show}
          onClose={this.toggleModal}
          sx={{ display: "grid", placeItems: "center" }}
        >
          <Zoom
            in={this.state.show}
            onEntered={(e) => {
              this.inputPaymentRef.current.focus();
            }}
          >
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
              style={{ outline: "none" }}
            >
              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Konfirmasi Pembayaran</MKTypography>
                <CloseIcon
                  fontSize="medium"
                  sx={{ cursor: "pointer" }}
                  onClick={this.toggleModal}
                />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox pl={5} pr={5}>
                <MKInput
                  inputRef={this.inputPaymentRef}
                  fullWidth
                  inputProps={{ style: { textAlign: "right", fontSize: 40 } }}
                  label="Pembayaran"
                  value={fnumber(this.state.amount)}
                  onChange={(e) => {
                    const amount = e.target.value.replace(/\./g, "");
                    let buttonDisabled = true;
                    if (amount >= this.state.totalAmount) buttonDisabled = false;
                    this.setState({ amount, buttonDisabled });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // console.log("test");
                      // this.confirmRef.current.setShow(true);
                      // this.toggleModal();
                      // this.props.onConfirm(parseInt(this.state.amount));
                      this.confirmRef.current.setShow(true);
                    }
                  }}
                />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="error" onClick={this.toggleModal}>
                  batal
                </MKButton>
                <MKButton
                  disabled={this.state.buttonDisabled}
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    this.confirmRef.current.setShow(true);
                    // this.toggleModal();
                    // this.props.onConfirm && this.props.onConfirm(parseInt(this.state.amount));
                  }}
                >
                  bayar
                </MKButton>
                <Confirmation
                  title="Konfirmasi Pembayaran"
                  body={<MKTypography>Apakah anda yakin akan melakukan pembayaran ?</MKTypography>}
                  ref={this.confirmRef}
                  onConfirm={() => {
                    this.toggleModal();
                    this.props.onConfirm && this.props.onConfirm(parseInt(this.state.amount));
                  }}
                  confirm="bayar"
                />
              </MKBox>
            </MKBox>
          </Zoom>
        </Modal>
      </>
    );
  }
}
