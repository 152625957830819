import React, { createRef } from "react";

// react-router components
import { Routes, Route, BrowserRouter, HashRouter as Router } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
// import Presentation from "layouts/pages/presentation";
// import Dashboard from "contents/dashboard";

import * as serviceWorker from "serviceWorker";

// Material Kit 2 PRO React routes
import { routes } from "routes";
import secureStorage from "libraries/secureStorage";
import Login from "contents/Login";
import Register from "contents/Register";
import Verification from "contents/Login/Verification";
import ResetPassword from "contents/Login/ResetPassword";
// import Confirmation from "contents/Login/Confirmation";
import { withSnackbar } from "notistack";
import Layout from "contents";

import axios from "axios";
import { Button } from "@mui/material";
import ReLogin from "contents/Login/ReLogin";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingWorker: {},
      newVersionAvailable: false,
    };
    // this.reLoginRef = createRef();
  }
  // const { pathname } = useLocation();

  // const [mounted, setMounted] = useState(false);
  // const [waitingWorker, sertWaitingWorker] = useState({});
  // const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  componentDidMount() {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // setMounted(true);

    if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1) {
      // wsClient.on('update',this.onAppUpdate)
    }
  }
  UNSAFE_componentWillMount() {
    serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate });

    this.setRequestHeader();
    this.sessionCheck();
  }
  setRequestHeader = () => {
    const token = secureStorage.getItem("token");
    if (token) {
      axios.defaults.headers.Authorization = `Bearer ${token}`;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      // axios.defaults.withCredentials = true
    }
  };
  logout = () => {
    secureStorage.removeItem("token");
    secureStorage.removeItem("account");
    window.location.reload();
  };
  sessionCheck = () => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.data === "Forbidden Access") {
          this.logout();
          // if (secureStorage.getItem("token")) this.reLoginRef.current.setShow(true);
        }
        return Promise.reject(error);
      }
    );
  };

  updateServiceWorker = () => {
    const { waitingWorker } = this.state;

    waitingWorker && waitingWorker.postMessage("forceUpdate");
  };

  refreshAction = (key) => {
    //render the snackbar button
    return (
      <React.Fragment>
        <Button color="error" size="small" onClick={this.updateServiceWorker}>
          {"update"}
        </Button>
      </React.Fragment>
    );
  };
  updateApp = () => {
    // wsClient.send('restart',{data:null})
  };
  restartAction = (key) => {
    //render the snackbar button
    return (
      <React.Fragment>
        <Button color="error" size="small" onClick={this.updateApp}>
          {"restart"}
        </Button>
      </React.Fragment>
    );
  };
  onAppUpdate = () => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Aplikasi dekstop versi terbaru telah tersedia", {
      persist: true,
      variant: "success",
      action: this.restartAction(),
    });
    // wsClient.send('restart',{data:null})
  };

  onServiceWorkerUpdate = (waitingWorker) => {
    const { enqueueSnackbar } = this.props;
    console.log("여기여");
    this.setState({
      waitingWorker,
      newVersionAvailable: true,
    });

    // sertWaitingWorker(waitingWorker);
    // setNewVersionAvailable(true);

    enqueueSnackbar("Versi terbaru telah tersedia", {
      persist: true,
      variant: "success",
      action: this.refreshAction(),
    });
    //}
  };
  // if (!mounted) {
  //   serviceWorker.register({ onUpdate: onServiceWorkerUpdate });

  //   setRequestHeader();
  //   sessionCheck();
  // }

  // useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   setMounted(true);
  //   if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1) {
  //     // wsClient.on('update',this.onAppUpdate)
  //   }
  // }, [pathname]);

  getRoutes = (allRoutes) => {
    const index = [];
    const theRoutes = allRoutes.map((route) => {
      if (route.index) index.push(<Route index element={route.component} key={route.key} />);
      if (route.collapse) {
        return (
          <Route exact path={route.route} element={route.component} key={route.key}>
            {this.getRoutes(route.collapse)}
          </Route>
        );
      } else if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });
    return theRoutes.concat(index);
  };
  render() {
    // const account = secureStorage.getItem("account");
    const routeTemp = this.getRoutes(routes);
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <ReLogin ref={this.reLoginRef} onLogout={this.logout} /> */}
        <BrowserRouter>
          <Routes>
            <Route exact path="/reg/:invitationId" element={<Register />} key="register" />
            <Route exact path="/login" element={<Login />} key="login" />
            {/* <Route path="/confirm/:confirmCode" element={<Confirmation/>} key="confirmation"/> */}
            <Route exact path="/verification" element={<Verification />} key="verification" />
            <Route path="/reset/:confirmCode" element={<ResetPassword />} key="reset-password" />
            <Route path="/" element={<Layout key="layout" />}>
              {routeTemp}
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}
export default withSnackbar(App);
