import React, { createRef } from "react";
import PropTypes from "prop-types";
// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { Slide, Zoom } from "@mui/material";
// import MKInput from 'components/MKInput';
// import { fnumber } from 'helper/Helper';
const Transition = React.forwardRef((props, ref) => {
  if (props.transition === "slide")
    return (
      <Slide direction="down" in={props.in} onEntered={props.onEntered}>
        {props.children}
      </Slide>
    );
  else
    return (
      <Zoom in={props.in} onEntered={props.onEntered}>
        {props.children}
      </Zoom>
    );
});
class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      buttonDisabled: false,
    };

    this.confirmRef = createRef();
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.confirmDisabled !== prevProps.confirmDisabled) {
  //     console.log({ prevProps, props: this.props });
  //     console.log({ buttonDisabled: prevProps.confirmDisabled, title: prevProps.title });

  //     this.setState({
  //       buttonDisabled: prevProps.confirmDisabled,
  //     });
  //   }
  // }

  toggleModal = () => {
    this.setShow(!this.state.show);
    this.props.onClose && this.props.onClose();
  };

  setShow = (show) => {
    this.setState({ show });
  };

  render() {
    return (
      <Modal
        open={this.state.show}
        onClose={this.toggleModal}
        sx={{ display: "grid", placeItems: "center" }}
      >
        <Transition
          transition={this.props.transition}
          in={this.state.show}
          onEntered={(e) => {
            this.confirmRef.current && this.confirmRef.current.focus();
          }}
        >
          <MKBox
            position="relative"
            width={this.props.fullScreen ? "100%" : "500px"}
            height={this.props.fullScreen ? "100vw" : "auto"}
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
            style={{ outline: "none" }}
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">{this.props.title}</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={this.toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox pl={5} pr={5} style={{ textAlign: "center" }}>
              {this.props.body}
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="error" onClick={this.toggleModal}>
                {this.props.cancel ?? "batal"}
              </MKButton>
              {this.props.isConfirm && (
                <MKButton
                  ref={this.confirmRef}
                  disabled={this.state.buttonDisabled}
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    this.toggleModal();
                    this.props.onConfirm && this.props.onConfirm();
                  }}
                >
                  {this.props.confirm ?? "Confirm"}
                </MKButton>
              )}
            </MKBox>
          </MKBox>
        </Transition>
      </Modal>
    );
  }
}

Confirmation.defaultProps = {
  cancel: "batal",
  confirm: "Confirm",
  transition: "slide",
  fullScreen: false,
  isConfirm: true,
  confirmDisabled: false,
};

Confirmation.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  cancel: PropTypes.string,
  confirm: PropTypes.string,
  transition: PropTypes.string,
  fullScreen: PropTypes.bool,
  isConfirm: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default Confirmation;
