// import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

import SimpleFooter from "examples/Footers/SimpleFooter";
// import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

import Stack from "@mui/material/Stack";
// import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";
// HelpCenter page components

// Images
// import { Icon } from "@mui/material";

// Routes

import Counters from "contents/components/Counters";
import AccountInfo from "contents/AccountInfo";
import ImageBox from "contents/ImageBox";
import BRI from "assets/images/bri.png";
import BNI from "assets/images/bni.jpg";
import MANDIRI from "assets/images/mandiri.png";
import BCA from "assets/images/bca.png";
import { useEffect, useState } from "react";
import secureStorage from "libraries/secureStorage";
import axios from "axios";
import Config from "config";
import { v4 as uuidv4 } from "uuid";

function Dashboard() {
  // const [collapse, setCollapse] = useState(false);
  const [account, setAccount] = useState("");
  const [bank, setBank] = useState([]);

  const initBank = () => {
    axios
      .post(`${Config.EndPoint}/bill/bank`)
      .then((res) => {
        // console.log(res.data);
        setBank(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const account = secureStorage.getItem("account");
    // console.log({ account });
    setAccount(account);
    initBank();
  }, []);

  function getImage(name) {
    if (name.toLowerCase().indexOf("bni") !== -1) return BNI;
    if (name.toLowerCase().indexOf("bca") !== -1) return BCA;
    if (name.toLowerCase().indexOf("mandiri") !== -1) return MANDIRI;
    if (name.toLowerCase().indexOf("bri") !== -1) return BRI;
  }

  return (
    <>
      {/* <Counters /> */}
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={12} justifyContent="center">
            <MKBox
              mt={15}
              px={3}
              borderRadius="lg"
              shadow="lg"
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              }}
            >
              <Container>
                <Grid container alignItems="center">
                  <Grid item xs={12} lg={5}>
                    {account && (
                      <AccountInfo
                        badge={{ color: "info", label: "informasi akun" }}
                        name={{ value: account.name ?? "" }}
                        specifications={[
                          { label: { name: "Tipe Akun", value: account.UserType.name ?? "-" } },
                          // { label: { name: "Username", value: account.username ?? "-" } },
                          { label: { name: "ID Loket", value: account.id.toString().padStart(6, 0) } },
                          { label: { name: "Email", value: account.email ?? "-" } },
                          { label: { name: "No Telp", value: account.telp ?? "-" } },
                        ]}
                        action={{
                          type: "internal",
                          route: "/",
                          color: "dark",
                          label: "informasi",
                        }}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}
                  >
                    <MKTypography variant="h5" color="dark">
                      Rekening Penyetoran
                    </MKTypography>
                    <Stack>
                      {bank.map((item) => (
                        <ImageBox
                          key={uuidv4()}
                          src={getImage(item.name)}
                          content={
                            <>
                              <MKTypography variant="body1">{item.name.toUpperCase()}</MKTypography>
                              <MKTypography variant="body2">
                                {item.accountNumber} {item.accountName}
                              </MKTypography>
                            </>
                          }
                        />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </MKBox>
          </Grid>
        </Grid>
      </Container>

      {/* <MKBox pt={{ xs: 0, lg: 3 }} pb={3}>
        <SimpleFooter company={{ href: "#", name: "yujuism" }} links={[]} />
      </MKBox> */}
    </>
  );
}

export default Dashboard;
