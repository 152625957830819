import PropTypes from "prop-types";

// react-countup component
import CountUp from "react-countup";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { fnumber } from "helper/Helper"

function DisplayNumber({ title, tvariant, nvariant, children, color, ...rest }) {
  return (
    <MKBox
      textAlign="center"
      lineHeight={1}
      bgColor="white"
      padding={1}
      ml={1}
      variant={color === "white" ? "contained" : "gradient"}
      borderRadius="xl"
    >
      {title && (
        <MKTypography variant={tvariant} mt={2} mb={1} textTransform="uppercase">
          {title}
        </MKTypography>
      )}
      <MKTypography variant={nvariant} color={color} textGradient>
        {/* <CountUp end={children} duration={1} prefix="Rp " separator="." {...rest} /> */}
        {fnumber(children)}
      </MKTypography>
    </MKBox>
  );
}

// Setting default props for the DefaultCounterCard
DisplayNumber.defaultProps = {
  color: "info",
  tvariant: "h5",
  nvariant: "h3",
};

// Typechecking props for the DefaultCounterCard
DisplayNumber.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  tvariant: PropTypes.string,
  nvariant: PropTypes.string,
};

export default DisplayNumber;
