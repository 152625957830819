const _ = require("lodash");

module.exports = (obj) => {
  let detailIdx = -1;
  const arr = Object.keys(obj).map((key, idx) => {
    if (key === "info") return [obj[key].value];
    else if (key === "details") {
      detailIdx = idx;
      const details = obj[key];
      return details.map((item, index) => {
        return Object.keys(item).map((key1) => {
          return [item[key1].caption + " " + (index + 1), item[key1].value];
        });
      });
    } else return [obj[key].caption, obj[key].value];
  });
  if (obj.details) return flatten(arr, detailIdx);
  else return arr;
};
function flatten(arr, detailIdx) {
  const detailArr = _.flattenDepth(arr[detailIdx], 1);

  detailArr.map((item, index) => {
    if (index === 0) {
      arr.splice(detailIdx, 1, item);
    } else arr.splice(detailIdx + index, 0, item);
  });

  return arr;
}
