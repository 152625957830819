import React from "react"
import textLogo from "assets/img/logo.png"

export default class ComponentToPrint extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			title : this.props.title,
			body : this.props.body,
		}
		this.myRef = React.createRef()
	}
	setContent = (title,body)=>{
		this.setState({title,body})
	}
	render(){
		return (
			<div style={{
				position:'absolute',left:'-5000px',top:'-5000px',
				textAlign:'left', padding:'50px 0px 0px 50px',
				// visibility:'hidden'
				color:'#000',
				
			}} ref={this.myRef}>
				<div style={{
					width:400,
				}}>
					<h5 style={{fontWeight:'bold'}}>{this.state.title}</h5>
					<div style={{
						borderStyle:'solid',
						borderWidth:1,
						borderRadius:10,
						padding:'10px 10px 10px 10px',
						marginBottom:20,
					}}>
						{this.state.body}
					</div>
						<div
						style={{
							borderRadius: 30,
							color:'#fff',
							background:"linear-gradient(60deg, #e5e5e5, #fff)",
							height:60,
							width:80,
						}}
					><img src={textLogo} alt="PayFren" style={{width:90}} /></div>
				</div>
			
			</div>
		)
	}
}