import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { Autocomplete, Checkbox } from "@mui/material";
import { CheckBox, CreditScore, DeleteForever, PageviewRounded } from "@mui/icons-material";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

import MKInput from "components/MKInput";

import DisplayNumber from "contents/components/DisplayNumber";

import SimpleFooter from "examples/Footers/SimpleFooter";
import Counters from "contents/components/Counters";

import axios from "axios";
import Config from "config";
import { createRef, Component } from "react";
import { Navigate } from "react-router-dom";
import secureStorage from "libraries/secureStorage";
import _ from "lodash";
import Table from "examples/Tables/Table";
import Item from "contents/Transaction/components/Item";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";
import FNumber from "contents/components/Number";
import Remark from "contents/Transaction/components/Remark";

import Receipt from "contents/components/Receipt";
import PaymentConfirm from "contents/components/PaymentConfirm";
import PrepaidNominals from "contents/components/PrepaidNominals";
import AdminNominals from "contents/components/AdminNominals";
import Reprint from "contents/components/Reprint";
import receiptToArray from "libraries/receiptToArray";
import { fnumber } from "helper/Helper";
import print from "helper/printer";
import moment from "moment";

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: null,
      allProducts: [],
      products: [],
      categories: [],
      customerCode: "",
      product: null,
      category: null,

      adminNominal: 0,
      price: 0,
      isConfirm: false,
      denom: null,
      productTypeId: null,

      totalAmount: 0,
      changeAmount: 0,
      paidAmount: 0,

      categoryOpen: false,
      productOpen: false,
      rows: [],
      rowLength: 0,
      receiptTitle: "",
      receiptBody: "",
      nominal: 0,
      isProcessing: false,

      buttonCheckDisabled: false,
      buttonPurchaseDisabled: false,
      redirect: null,
    };
    this.customerCodeRef = createRef();
    this.productRef = createRef();
    this.categoryRef = createRef();
    this.modalRef = createRef();
    this.purchaseRef = createRef();
    this.confirmRef = createRef();
    this.prepaidNominalsRef = createRef();
    this.adminNominalsRef = createRef();
    this.reprintRef = createRef();

    this.columns = [
      { name: "no", align: "center" },
      { name: "produk", align: "left" },
      { name: "nama", align: "center" },
      { name: "status", align: "center" },
      { name: "blth", align: "center" },
      { name: "denda", align: "right" },
      { name: "rptag", align: "right" },
      { name: "admin", align: "right" },
      { name: "total", align: "right" },
      { name: "keterangan", align: "center" },
    ];
  }

  componentDidMount() {
    const account = secureStorage.getItem("account");
    if (!account) this.setState({ redirect: "/login" });
    else {
      document.addEventListener("keydown", this._handleKeyDown);
      // savedTrx.forEach((item, idx) => this.setState({ ["checkPrint" + idx]: true }));

      const savedTrx = secureStorage.getItem(`savedTrx${account.id}`) ?? [];
      this.setState({
        account,
        rows: savedTrx,
        totalAmount: this.setTotal(savedTrx),
        buttonPurchaseDisabled: !_.some(savedTrx, { checked: true }),
      });
      this.loadProduct();
    }
  }

  getAdminSelected = (productId) => {
    let type = productId === 14 ? "Post" : productId === 15 ? "Pre" : "Non";

    const adminSelected = secureStorage.getItem(`adminNominal${type}`);
    console.log({ adminSelected });
    if (adminSelected) {
      const nominal = parseInt(adminSelected.label);
      this.setState({
        adminNominal: nominal,
      });
    } else {
      this.setState({ adminNominal: 0 });
    }
  };

  _handleKeyDown = (e) => {
    // console.log(e.altKey)
    // console.log(e.key)
    if (e.altKey) {
      switch (e.key) {
        case "i":
          this.customerCodeRef.current.focus();
          break;
        case "p":
          this.productRef.current.focus();
          this.setState({ productOpen: true });
          break;
        case "k":
          this.categoryRef.current.focus();
          this.setState({ categoryOpen: true });
          break;
        case "b":
          this.purchaseRef.current.click();
        default:
      }
    }
  };

  loadProduct = () => {
    axios
      .post(`${Config.EndPoint}/product/all`)
      .then((r) => {
        const products = r.data;

        const categories = _.uniqBy(
          products.map((item) => ({
            id: item.ProductCategory.id,
            label: item.ProductCategory.name,
          })),
          ({ id }) => id
        );
        // console.log(
        //   _.filter(
        //     products.map((item) => item.name),
        //     (val, i, iteratee) => _.includes(iteratee, val, i + 1)
        //   )
        // );
        // console.log(_.sortBy(products, "name"));
        this.setState({ products, categories, allProducts: products });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  filterProduct = (e, value) => {
    console.log({ value });
    if (value)
      this.setState({
        products: this.state.allProducts.filter((item) => item.ProductCategory.id === value.id),
      });
    else this.setState({ products: this.state.allProducts });
  };

  selectProduct = (value) => {
    if (!value)
      return this.setState({ product: null, productTypeId: null, productCategoryId: null });
    this.setState({ product: value });
    const products = this.state.products;
    const product = _.find(products, { id: value.id });

    if (product) {
      const productCategory = product && product.ProductCategory;
      const productTypeId = productCategory && productCategory.productTypeId;

      if (productTypeId === 2) {
        this.setState({
          isProcessing: true,
          buttonCheckDisabled: true,
          buttonPurchaseDisabled: true,
        });
        axios
          .post(`${Config.EndPoint}/product/item/price`, { productId: value.id })
          .then((response) => {
            const price = response.data.price;
            this.setState({
              price,
              denom: product.denom,
              isProcessing: false,
              productTypeId,
              buttonCheckDisabled: false,
              buttonPurchaseDisabled: false,
              productCategoryId: productCategory.id,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              isProcessing: false,
              productCategoryId: productCategory.id,
              buttonPurchaseDisabled: false,
              buttonCheckDisabled: false,
            });
          });
      } else {
        if ([14, 15, 984].includes(product.id)) {
          this.getAdminSelected(product.id);
        }

        const AdminNominals = product.AdminNominals.sort((a, b) => a.nominal - b.nominal);
        this.adminNominalsRef.current.setData({ AdminNominals });
        this.setState({ productTypeId: 1, denom: null, productCategoryId: productCategory.id });
      }
    }
  };

  getReceiptTitle = () => {
    const products = this.state.products;
    const product = _.find(products, { id: this.state.product?.id });
    const productCategory = product && product.ProductCategory ? product.ProductCategory : null;
    console.log({ productCategory });
    if ([2, 10].includes(productCategory.id)) return `PEMBELIAN E-WALLET`;
    else if ([7, 14].includes(productCategory.id)) return "PEMBELIAN PAKET DATA";
    else return "PEMBELIAN PULSA";
  };

  handleInquiry = (e) => {
    // const key = e.key
    const account = this.state.account;
    if (["nominal", "button"].includes(e) || e.key === "Enter") {
      const {
        customerCode,
        product,
        nominal,
        rows,
        totalAmount,
        productTypeId,
        productCategoryId,
      } = this.state;

      if (product) {
        if (
          customerCode &&
          _.findIndex(this.state.rows, (item) => item.customerCode === customerCode) === -1 &&
          !this.state.isProcessing
        ) {
          if (productTypeId === 2) {
            console.log("[SHOW PRICE]");
            this.setState({
              isConfirm: true,
              buttonPurchaseDisabled: false,
              receiptTitle: this.getReceiptTitle(),
              receiptBody: {
                phone: { caption: "NO HP", value: customerCode },
                item: { caption: "ITEM", value: product.label },
                denom: { caption: "DENOM", value: this.state.denom },
                amount: { caption: "RP BAYAR", value: this.state.price },
              },
            });

            this.modalRef.current.setShow(true);
          } else if (product.id === 15 && !nominal) {
            // show modal denom
            this.prepaidNominalsRef.current.setShow(true);
          } else if ([14, 15, 984].includes(product.id) && !this.state.adminNominal) {
            // show modal adminNominal
            this.adminNominalsRef.current.setShow(true, product.id);
          } else if (customerCode && product) {
            // send inquiry
            this.setState({
              isProcessing: true,
              buttonCheckDisabled: true,
              buttonPurchaseDisabled: true,
            });
            const adminNominal = [14, 15, 984].includes(product.id)
              ? this.state.adminNominal
              : undefined;

            const payload = {
              customerCode,
              productId: product.id,
              adminNominal,
              nominal: nominal ? nominal : undefined,
              month: product.id === 539 ? 1 : undefined,
            };

            console.log({ payload });
            axios
              .post(`${Config.EndPoint}/request/inquiry`, payload)
              .then((r) => {
                // const resTimeStamp = moment()
                // const timeLapse = `${parseFloat(resTimeStamp.diff(reqTimeStamp, 'milliseconds')) / 1000} s`
                // console.log({ reqTimeStamp, resTimeStamp, timeLapse })

                let data = r.data;
                // console.log({ response: data })
                const receiptBody = data.receiptBody;
                const customerName = receiptBody && receiptBody.name ? receiptBody.name.value : "-";

                const savedTrx = secureStorage.getItem(`savedTrx${account.id}`) ?? [];

                data.trxType = 1;
                data.productTypeId = productTypeId;
                data.productCategoryId = productCategoryId;
                // data.isPurchase = false;
                data.checked = true;
                data.customerName = customerName;
                // data.number = savedTrx.length + 1

                savedTrx.push(data);
                secureStorage.setItem(`savedTrx${account.id}`, savedTrx);

                this.getAdminSelected(product.id);
                this.setState({
                  nominal: 0,
                  // adminNominal: 0,

                  buttonCheckDisabled: false,
                  buttonPurchaseDisabled: false,
                  isProcessing: false,
                  customerCode: "",
                  totalAmount: this.setTotal(savedTrx),
                  rows: [...this.state.rows, data],
                  // ["checkPrint" + this.state.rows.length]: true,
                });
              })
              .catch((err) => {
                // console.log(err.response);
                let errData = {};
                if (err.response) {
                  const receiptBody =
                    err.response.data && err.response.data.receiptBody
                      ? err.response.data.receiptBody
                      : null;

                  errData = {
                    receiptTitle:
                      err.response.data && err.response.data.receiptTitle
                        ? err.response.data.receiptTitle
                        : "TRANSAKSI GAGAL",
                    receiptBody: receiptBody
                      ? Array.isArray(receiptBody)
                        ? receiptBody[0]
                        : [[receiptBody.message]]
                      : [["Server Internal Error"]],
                  };
                } else {
                  errData = {
                    receiptTitle: "TRANSAKSI GAGAL",
                    receiptBody: [["Server Internal Error"]],
                  };
                }

                console.log({ errData });
                this.getAdminSelected(product.id);
                this.setState(
                  {
                    nominal: 0,
                    // adminNominal: 0,
                    isProcessing: false,
                    isConfirm: false,
                    buttonCheckDisabled: false,
                    buttonPurchaseDisabled: false,
                    ...errData,
                  },
                  () => this.modalRef.current.setShow(true)
                );
              });
          }
        } else if (!customerCode && rows.length > 0)
          this.confirmRef.current.setShow(true, totalAmount);
      } else {
        // this.getAdminSelected(product.id);
        this.setState({
          nominal: 0,
          // adminNominal: 0,
          isConfirm: false,
          isProcessing: false,
          buttonCheckDisabled: false,
          buttonPurchaseDisabled: false,
          receiptTitle: "Peringatan",
          receiptBody: [["Produk belum dipilih"]],
        });

        this.modalRef.current.setShow(true);
      }
    }
  };

  handlePurchase = async (paidAmount) => {
    const account = this.state.account;
    this.setState({ buttonPurchaseDisabled: true });
    const rows = this.state.rows;
    // let totalAmount = 0
    let savedTrx = secureStorage.getItem(`savedTrx${account.id}`) ?? [];

    let totalAmount = 0;
    let resetTotal = this.state.totalAmount;
    for (const item of rows) {
      if (item.trxType === 1 && item.checked) {
        const { productTypeId } = item;
        const price = productTypeId === 2 ? { price: item.nominal } : {};
        const payload = {
          ...item,
          ...price,
        };

        // const idx = _.findIndex(savedTrx, { salesId: item.salesId });
        const idx = _.findIndex(savedTrx, { customerCode: item.customerCode });
        const amount =
          productTypeId === 1
            ? parseInt(item.adminNominal) * parseInt(item.qty) + parseInt(item.nominal)
            : parseInt(item.nominal);
        await axios
          .post(`${Config.EndPoint}/request/purchase`, payload)
          .then((res) => {
            const data = res.data;

            savedTrx.splice(idx, 1, {
              ...data,
              trxType: 2,
              checked: false,
              productTypeId: item.productTypeId,
              productCategoryId: item.productCategoryId,
            });

            totalAmount += amount;
            resetTotal -= amount;
            // secureStorage.setItem(`savedTrx${account.id}`, savedTrx);

            // console.log({ payload, purchase: data, savedTrx })
            // totalAmount = _.sumBy(savedTrx, ({ adminNominal, qty, nominal, statusId }) =>
            //   statusId === 4 ? (parseInt(adminNominal) * parseInt(qty)) + parseInt(nominal) : 0
            // );

            if (data.statusId === 4) {
              print({
                title: data.receiptTitle,
                body: data.receiptBody,
                productId: data.productId,
                productName: data.productName,
                productCategoryId: item.productCategoryId,
                filename: `${data.productName}_${data.customerCode}_${moment(
                  data.receiptBody.date
                ).format("YYMMDDHHmm")}.pdf`,
              });
            }
          })
          .catch((err) => {
            console.log({ errResp: err.response });

            let errData = { ...item, trxType: 2, statusId: 2, checked: false };
            if (err.response && err.response.data) {
              const receiptBody =
                err.response.data && err.response.data.receiptBody
                  ? err.response.data.receiptBody
                  : null;

              errData = {
                ...errData,
                receiptTitle:
                  err.response.data && err.response.data.receiptTitle
                    ? err.response.data.receiptTitle
                    : "TRANSAKSI GAGAL",
                receiptBody: receiptBody
                  ? Array.isArray(receiptBody)
                    ? { message: receiptBody[0][0] }
                    : { message: receiptBody.message }
                  : { message: "Server Internal Error" },
              };
            } else {
              errData = {
                ...errData,
                receiptTitle: "TRANSAKSI GAGAL",
                receiptBody: { message: "Server Internal Error" },
              };
            }

            console.log({ errData });
            savedTrx.splice(idx, 1, errData);
            secureStorage.setItem(`savedTrx${account.id}`, savedTrx);
          });
      }
    }

    // console.log({ savedTrx });
    secureStorage.setItem(`savedTrx${account.id}`, savedTrx);
    // totalAmount = this.setTotal(savedTrx, true);
    // this.setState({
    //   // totalAmount: totalAmount ? totalAmount : this.state.totalAmount,
    //   // totalAmount,
    //   rows: [...savedTrx],
    //   buttonPurchaseDisabled: true,
    //   //     ['checkPrint'+this.state.rows.length]:true,
    // });
    console.log({
      paidAmount,
      changeAmount: paidAmount - totalAmount, // ? totalAmount : this.state.totalAmount,
      totalAmount, // : totalAmount ? totalAmount : this.state.totalAmount
      resetTotal,
    });
    this.setState({
      rows: [...savedTrx],
      buttonPurchaseDisabled: true,
      paidAmount,
      changeAmount: paidAmount - totalAmount,
      totalAmount: resetTotal,
    });
  };

  getStatus = (row) => {
    if (row.statusId === 2) {
      return <MKBadge color="error" container badgeContent="gagal" size="xs" variant="gradient" />;
    } else if (row.statusId === 1) {
      return (
        <MKBadge color="warning" container badgeContent="pending" size="xs" variant="gradient" />
      );
    } else {
      if (row.trxType === 1) {
        return (
          <MKBadge
            container
            color="warning"
            badgeContent="Belum Lunas"
            size="xs"
            variant="gradient"
          />
        );
      } else {
        return (
          <MKBadge container color="success" badgeContent="Lunas" size="xs" variant="gradient" />
        );
      }
    }
  };

  handleViewDetail = (sale) => {
    // console.log({ sale });
    this.setState(
      {
        isConfirm: false,
        receiptTitle: sale.receiptTitle,
        receiptBody: sale.receiptBody,
      },
      () => this.modalRef.current.setShow(true)
    );
  };

  makeRow = (data, idx) => {
    const account = this.state.account;
    const {
      productName,
      adminNominal,
      statusId,
      nominal,
      qty,
      totalAmount,
      receiptBody,
      customerCode,
      customerName,
      productCategoryId,
      productTypeId,
    } = data;

    // console.log({ data });
    // // console.log(receiptBody[2][1])
    // // const color = data.trxType === 1 ? "warning" : "success";
    // // this.setState({['checkPrint'+idx]:true})

    const name = customerName
      ? customerName
      : [1, 4].includes(statusId) && receiptBody
      ? Array.isArray(receiptBody)
        ? receiptBody[1][1]
        : receiptBody.name && receiptBody.name.value
        ? receiptBody.name.value
        : "-"
      : "-";

    let remark = "-";
    let penalty = 0;
    if (receiptBody) {
      // set amount penalty from receiptBody
      penalty = receiptBody.penalty && receiptBody.penalty.value ? receiptBody.penalty.value : 0;

      if ([2].includes(statusId)) {
        remark = Array.isArray(receiptBody) ? receiptBody[0][0] : receiptBody.message;
      } else {
        remark = (
          <MKButton
            size="small"
            iconOnly
            circular
            color="info"
            onClick={(e) => {
              this.handleViewDetail(data);
            }}
          >
            <PageviewRounded />
          </MKButton>
        );
      }
    }

    const admin = productTypeId === 1 ? fnumber(parseInt(adminNominal) * parseInt(qty)) : 0;
    const total =
      productTypeId === 1
        ? fnumber(parseInt(nominal) + parseInt(adminNominal) * parseInt(qty))
        : fnumber(nominal);
    return {
      no: (
        <MKTypography variant="caption" color="secondary" fontWeight="medium">
          <MKBox display="flex" alignItems="center">
            <Checkbox
              // checked={
              //   this.state["checkPrint" + idx] !== undefined ? this.state["checkPrint" + idx] : true
              // }
              disabled={data.trxType === 1 ? false : true}
              checked={data.checked}
              onChange={(e) => {
                // console.log(this.state['checkPrint'+idx],e.target.checked)
                // this.setState({ ["checkPrint" + idx]: e.target.checked });

                let savedTrx = secureStorage.getItem(`savedTrx${account.id}`) ?? [];
                // console.log({ first: savedTrx, data, idx })
                savedTrx.splice(idx, 1, { ...data, checked: e.target.checked });
                // savedTrx[idx] = { ...data, checked: e.target.checked }

                // console.log({ last: savedTrx, data, idx })
                secureStorage.setItem(`savedTrx${account.id}`, savedTrx);

                this.setState({
                  buttonPurchaseDisabled: !_.some(savedTrx, { checked: true }),
                  rows: [...savedTrx],
                  totalAmount: this.setTotal(savedTrx),
                  paidAmount: 0,
                  changeAmount: 0,
                });
              }}
            />
            {idx + 1}
          </MKBox>
        </MKTypography>
      ),
      produk: <Item name={productName} category={productCategoryId} customerCode={customerCode} />,
      // nama: <Remark>{receiptBody && receiptBody.name ? receiptBody.name.value : "-"}</Remark>,
      nama: <Remark>{name}</Remark>,
      status: this.getStatus(data),
      blth: qty,
      denda: (
        <MKTypography variant="subtitle" color="secondary" fontWeight="medium">
          {fnumber(parseInt(penalty))}
        </MKTypography>
      ),
      rptag: (
        <MKTypography variant="subtitle" color="secondary" fontWeight="medium">
          {fnumber(nominal)}
        </MKTypography>
      ),
      admin: (
        <MKTypography variant="subtitle" color="secondary" fontWeight="medium">
          {admin}
        </MKTypography>
      ),
      total: (
        <MKTypography variant="subtitle" color="secondary" fontWeight="medium">
          {total}
        </MKTypography>
      ),
      keterangan: remark,
    };
  };

  // Count total amount
  // filter data not checked

  setTotal = (rows, isPurchase) => {
    console.log("SUMMRY TOTAL");
    console.log({ rows, isPurchase });
    // const totalAmount = _.sumBy(
    //   _.reject(rows, (r) => !r.checked),
    //   ({ statusId, adminNominal, qty, nominal }) => {
    //     return isPurchase
    //       ? [4].includes(statusId)
    //         ? parseInt(adminNominal) * parseInt(qty) + parseInt(nominal)
    //         : 0
    //       : parseInt(adminNominal) * parseInt(qty) + parseInt(nominal);
    //   }
    // );

    const totalAmount = _.sumBy(
      _.reject(rows, (r) => r.checked === false),
      ({ statusId, adminNominal, qty, nominal, productTypeId }) => {
        return productTypeId === 1
          ? parseInt(adminNominal) * parseInt(qty) + parseInt(nominal)
          : parseInt(nominal);
      }
    );

    return totalAmount;
  };

  render() {
    const account = this.state.account;

    if (this.state.redirect) return <Navigate to={this.state.redirect} />;
    return (
      <>
        <PaymentConfirm ref={this.confirmRef} onConfirm={this.handlePurchase} />
        <PrepaidNominals
          ref={this.prepaidNominalsRef}
          onConfirm={(nominal) => {
            this.setState({ nominal }, () => {
              this.handleInquiry("nominal");
            });
          }}
        />
        <AdminNominals
          ref={this.adminNominalsRef}
          onConfirm={(adminNominal) => {
            this.setState({ adminNominal }, () => {
              this.handleInquiry("nominal");
            });
          }}
        />
        <Reprint
          ref={this.reprintRef}
          onConfirm={(item) => {
            // console.log(item);
            print({
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
              title: item.receiptTitle,
              body: item.receiptBody,
              productId: item.productId,
              productName: item.productName,
              productCategoryId: item.productCategoryId,
              filename: `${item.productName}_${item.customerCode}_${moment(item.createdAt).format(
                "YYMMDDHHmm"
              )}.pdf`,
            });
          }}
        />
        <Receipt
          ref={this.modalRef}
          isConfirm={this.state.isConfirm}
          title={this.state.receiptTitle}
          body={this.state.receiptBody}
          onAdded={(receiptBody) => {
            const savedTrx = secureStorage.getItem(`savedTrx${account.id}`) ?? [];
            const {
              customerCode,
              product,
              price,
              rows,
              totalAmount,
              productTypeId,
              productCategoryId,
            } = this.state;
            const data = {
              trxType: 1,
              customerCode,
              customerName: "-",
              adminNominal: 0,
              nominal: price,
              productId: product.id,
              productName: product.label,
              qty: 1,
              receiptTitle: "PEMBELIAN PULSA",
              receiptBody,
              statusId: 4,
              productTypeId,
              productCategoryId,
              checked: true,
              // isPurchase: false,
              // number: savedTrx.length + 1
            };

            savedTrx.push(data);
            secureStorage.setItem(`savedTrx${account.id}`, savedTrx);

            this.setState({
              isProcessing: false,
              // price: 0,
              adminNominal: 0,
              customerCode: "",
              // product: null,
              totalAmount: this.setTotal(savedTrx),
              rows: [...this.state.rows, data],
              // ["checkPrint" + this.state.rows.length]: true,
            });
          }}
        />
        {/* <Counters /> */}
        <MKBox
          component="header"
          minHeight="100vh"
          width="100%"
          mb={3}
          // mt={{xs:-20,md:-35}}
          sx={{
            display: "grid",
            // placeItems: "center",
          }}
          style={{ outline: "none" }}
        >
          <Container>
            <Grid
              container
              // style={{backgroundColor:'#000'}}
              spacing={3}
              justifyContent="center"
              pt={{ xs: 5, lg: 1 }}
              mt={0}
            >
              <Grid item xs={12} lg={12} xl={12} mt={8}>
                <MKBox
                  display="flex"
                  // flexDirection="column"
                  justifyContent="center"
                  // alignItems="center"
                  // px={2}
                  mb={{ xs: 8, md: 5 }}
                >
                  {/* SELECT CATEGORY */}
                  <MKBox my={2} mx={{ xs: 2, md: 0 }} width="50%">
                    <Autocomplete
                      open={this.state.categoryOpen}
                      onOpen={() => this.setState({ categoryOpen: true })}
                      onClose={() => this.setState({ categoryOpen: false })}
                      // defaultValue="Washington"
                      options={this.state.categories}
                      renderOption={(rest, item) => {
                        rest.key = item.id;
                        return (
                          <span
                            style={{
                              color: "#000",
                              opacity: 1,
                              fontWeight: "bold",
                            }}
                            {...rest}
                          >
                            {item.label}
                          </span>
                        );
                      }}
                      // sx={{ width: 300 }}
                      fullWidth
                      onChange={this.filterProduct}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MKInput
                          sx={{
                            input: {
                              "&::placeholder": { color: "#000", opacity: 1 },
                              fontWeight: "medium",
                            },
                          }}
                          {...params}
                          inputRef={this.categoryRef}
                          variant="outlined"
                          size="small"
                          placeholder="KATEGORI"
                        />
                      )}
                    />
                  </MKBox>
                  {/* SELECT PRODUCT */}
                  <MKBox
                    my={2}
                    mx={{ xs: 2, md: 2 }}
                    //  mt={{ xs: 2, md: 0 }}
                    mb={{ xs: 2, md: 2 }}
                    mr={{ xs: 2, md: 0 }}
                    width="50%"
                  >
                    <Autocomplete
                      open={this.state.productOpen}
                      onOpen={() => this.setState({ productOpen: true })}
                      onClose={() => this.setState({ productOpen: false })}
                      // onKeyDown={this.handleInquiry}
                      options={this.state.products.map((item) => {
                        return {
                          label: item.name,
                          id: item.id,
                        };
                      })}
                      renderOption={(params, item) => {
                        params.key = item.id;
                        return (
                          <span
                            style={{
                              color: "#000",
                              opacity: 1,
                              fontWeight: "bold",
                            }}
                            {...params}
                          >
                            {item.label}
                          </span>
                        );
                      }}
                      value={this.state.product}
                      onChange={(e, value) => value !== -1 && this.selectProduct(value)}
                      // sx={{ width: 300 }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      fullWidth
                      renderInput={(params) => (
                        <MKInput
                          sx={{
                            input: {
                              "&::placeholder": { color: "#000", opacity: 1 },
                              fontWeight: "medium",
                            },
                          }}
                          onClick={() => this.state.products.length === 0 && this.loadProduct()}
                          {...params}
                          inputRef={this.productRef}
                          variant="outlined"
                          size="small"
                          placeholder="PRODUK"
                        />
                      )}
                    />
                  </MKBox>
                </MKBox>
                {/* FORM INPUT & BUTTON */}
                <MKBox
                  display="flex"
                  justifyContent="right"
                  flexDirection={{ xs: "column", md: "row" }}
                  mb={2}
                  mt={-5}
                  ml={{ xs: 1, md: 0 }}
                >
                  <MKBox mt={{ xs: 1 }} width={400}>
                    <MKInput
                      value={this.state.customerCode}
                      fullWidth
                      label="IDPEL/NOMETER/NOTELP"
                      size="small"
                      placeholder="IDPEL/NO METER/NO TELP"
                      type="tel"
                      inputRef={this.customerCodeRef}
                      onKeyDown={this.handleInquiry}
                      onChange={(e) => this.setState({ customerCode: e.target.value })}
                    />
                  </MKBox>
                  <MKBox mt={{ xs: 1 }} ml={1}>
                    <MKButton
                      disabled={this.state.buttonCheckDisabled}
                      size="medium"
                      onClick={() => this.handleInquiry("button")}
                      color="info"
                      variant="gradient"
                    >
                      {/* <Icon color="inherit">clear</Icon> */}
                      cek tag
                    </MKButton>
                  </MKBox>
                </MKBox>

                {/* TABLE LISt */}
                <MKBox mt={0} mb={3} ml={{ xs: 1, md: 0 }} mr={{ xs: 1, md: 0 }}>
                  <Table columns={this.columns} rows={this.state.rows.map(this.makeRow)} />
                </MKBox>

                {/* FOOTER && BUTTON */}
                <Tooltip title="Clear" placement="right">
                  <MKButton
                    size="small"
                    circular
                    onClick={() => {
                      secureStorage.removeItem(`savedTrx${account.id}`);
                      this.setState({
                        totalAmount: 0,
                        paidAmount: 0,
                        changeAmount: 0,
                        rows: [],
                      });
                    }}
                    color="secondary"
                    variant="gradient"
                  >
                    <Icon color="inherit">clear</Icon>
                    hapus
                  </MKButton>
                </Tooltip>
                <MKBox
                  display="flex"
                  justifyContent="right"
                  flexDirection={{ xs: "column", md: "row" }}
                  // mr={5}
                  // mt={-5}
                  // mb={2}
                >
                  <MKBox
                    display="flex"
                    flexDirection={{ xs: "row", md: "column" }}
                    justifyContent="center"
                    // alignItems="right"
                  >
                    <MKBox my={{ xs: 0, md: 1 }} mx={{ xs: 2, md: 0 }}>
                      {this.state.buttonPurchaseDisabled ? (
                        <MKButton
                          color="info"
                          name="purchase"
                          variant="gradient"
                          fullWidth
                          size="large"
                          ref={this.purchaseRef}
                          disabled={this.state.buttonPurchaseDisabled}
                          onClick={() =>
                            this.confirmRef.current.setShow(true, this.state.totalAmount)
                          }
                        >
                          BAYAR
                        </MKButton>
                      ) : (
                        <Tooltip title="Bayar" placement="right">
                          <MKButton
                            color="info"
                            name="purchase"
                            variant="gradient"
                            fullWidth
                            size="large"
                            ref={this.purchaseRef}
                            disabled={this.state.buttonPurchaseDisabled}
                            onClick={() =>
                              this.confirmRef.current.setShow(true, this.state.totalAmount)
                            }
                          >
                            BAYAR
                          </MKButton>
                        </Tooltip>
                      )}
                    </MKBox>
                    <Tooltip title="Cetak" placement="right">
                      <MKButton
                        // iconOnly
                        color="primary"
                        size="large"
                        variant="gradient"
                        onClick={() => {
                          this.reprintRef.current.setShow(true);
                        }}
                      >
                        <Icon color="inherit">print</Icon>
                        cetak ulang
                      </MKButton>
                    </Tooltip>
                  </MKBox>
                  <DisplayNumber title="uang bayar" color="warning" tvariant="h6" nvariant="h5">
                    {this.state.paidAmount}
                  </DisplayNumber>
                  <DisplayNumber title="uang kembali" color="error" tvariant="h6" nvariant="h5">
                    {this.state.changeAmount}
                  </DisplayNumber>
                  <DisplayNumber title="total harga" color="success">
                    {this.state.totalAmount}
                  </DisplayNumber>
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        {/* <MKBox pt={{ xs: 0, lg: 3 }} pb={3}>
          <SimpleFooter company={{ href: "#", name: "yujuism" }} links={[]} />
        </MKBox> */}
      </>
    );
  }
}

export default Transaction;
