import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

// Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";

import CircularProgress from "@mui/material/CircularProgress";

import MKBox from "components/MKBox";

import Loaded from "contents/components/Loaded";

import _ from "lodash";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: "20px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "20px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#e8f4f7",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#cbe3ee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: "#f9fafb",
  },
  stcikFooter: {
    position: "sticky",
    bottom: 0,
    zIndex: 2,
    fontWeight: "bold",
    fontSize: "15px",
    padding: "20px",
    backgroundColor: "#f9fafb",
  },
});

function CustomTable(props) {
  const classes = useStyles();

  const [sortable, setSortable] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [isSorting, setSorting] = useState(false);
  const [preview, setPreview] = useState(true);

  const {
    tableHead,
    tableFoot,
    tableData,
    rawData,
    tableHeaderColor,
    tableFooterColor,
    hover,
    colorsColls,
    coloredColls,
    striped,
    textAlignForCells,
    textAlignCells,
    textAlignForHeaders,
    textAlignHeaders,
    textAlignForFooters,
    textAlignFooters,
    fontWeightCells,
    fontWeightForCells,
    isLoading,
  } = props;

  const renderColumns = tableHead.map((column, index) => {
    const textAlign = textAlignForHeaders.includes(index)
      ? { textAlign: textAlignHeaders[textAlignForHeaders.indexOf(index)] }
      : {};
    return (
      <MKBox
        component="th"
        p={2}
        key={`column-${index}`}
        // sx={({ typography: { size, fontWeightBold }, borders: { borderWidth, borderColor } }) => ({
        //   fontSize: "12px",
        //   fontWeight: "bold",
        //   borderBottom: `${borderWidth[1]} solid ${borderColor}`,
        //   ...textAlign,
        // })}

        sx={({ typography: { size, fontWeightBold }, borders: { borderWidth, borderColor } }) => ({
          color: "#fff",
          fontSize: size.xs,
          fontWeight: fontWeightBold,
          borderBottom: `${borderWidth[1]} solid ${borderColor}`,
          ...textAlign,
        })}
      >
        {typeof column === "string" ? column.toUpperCase() : column}
      </MKBox>
    );
  });

  const renderRows = tableData.map((rows, key) => {
    const rowKey = `row-${key}`;
    return (
      <StyledTableRow hover tabIndex={-1} key={rowKey}>
        {tableHead.map((column, idx) => {
          const value = rows[idx];
          const textAlign = textAlignForCells.includes(idx)
            ? { textAlign: textAlignCells[textAlignForCells.indexOf(idx)] }
            : {};
          const fontWeight =
            fontWeightForCells && fontWeightForCells.includes(idx)
              ? { fontWeight: fontWeightCells[fontWeightForCells.indexOf(idx)] }
              : {};
          return (
            <MKBox
              component="td"
              key={idx}
              sx={{
                fontSize: "12px",
                padding: "8px 8px!important",
                ...textAlign,
                ...fontWeight,
              }}
            >
              {value}
            </MKBox>
          );
        })}
      </StyledTableRow>
    );
  });

  return (
    <MKBox width="100%" sx={{ overflow: "hidden", marginTop: "5px" }}>
      <TableContainer
        sx={{
          maxHeight: 530,
        }}
      >
        {isLoading ? (
          <MKBox display="flex" justifyContent="center">
            <CircularProgress color="inherit" />
          </MKBox>
        ) : (
          <Table stickyHeader aria-label="customized sticky table">
            {tableHead != undefined ? (
              <MKBox component="thead">
                <TableRow
                  style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 10,
                    // backgroundColor: "#fff",
                    minWidth: 50,
                    // color : '#000',
                  }}
                  className={classes.stickyHeader}
                  sx={({ palette: { socialMediaColors, white }, functions: { rgba } }) => ({
                    backgroundColor: `${rgba(socialMediaColors.instagram.main, 0.8)} !important`,
                    backdropFilter: `saturate(200%) blur(30px)`,
                  })}
                >
                  {renderColumns}
                </TableRow>
              </MKBox>
            ) : (
              <></>
            )}
            {preview && tableData ? <TableBody>{renderRows}</TableBody> : <></>}
            {tableFoot != undefined ? (
              <MKBox component="tfoot">
                <TableRow className={classes.stcikFooter}>
                  {tableFoot.map((prop, key) => {
                    const textAlign =
                      textAlignForFooters.indexOf(key) !== -1
                        ? { textAlign: textAlignFooters[textAlignForFooters.indexOf(key)] }
                        : {};
                    const fontWeight = textAlignForFooters.includes(key) ? { fontWeight: 500 } : {};
                    return prop ? (
                      <MKBox
                        component="th"
                        p={2}
                        key={`column-${key}`}
                        sx={({
                          typography: { size, fontWeightBold },
                          borders: { borderWidth, borderColor },
                        }) => ({
                          fontSize: "12px",
                          borderBottom: `${borderWidth[1]} solid ${borderColor}`,
                          ...fontWeight,
                          ...textAlign,
                        })}
                      >
                        {prop}
                      </MKBox>
                    ) : (
                      <></>
                    );
                  })}
                </TableRow>
              </MKBox>
            ) : (
              <></>
            )}
          </Table>
        )}
      </TableContainer>
    </MKBox>
  );
}

CustomTable.defaultProps = {
  togglePreview: false,
  tableHeaderColor: "gray",
  tableFooterColor: "gray",
  hover: "false",
  colorsColls: [],
  coloredColls: [],
  striped: false,
  textAlignForCells: [],
  textAlignCells: [],
  textAlignForHeaders: [],
  textAlignHeaders: [],
  textAlignForFooters: [],
  textAlignFooters: [],
};

CustomTable.propTypes = {
  togglePreview: PropTypes.bool,
  onSort: PropTypes.func,
  sortedColumn: PropTypes.object,
  sortable: PropTypes.bool,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.array,
  tableData: PropTypes.array,
  tableFoot: PropTypes.arrayOf(PropTypes.string),
  hover: PropTypes.string,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  colorsColls: PropTypes.array,
  textAlignForCells: PropTypes.arrayOf(PropTypes.number),
  textAlignCells: PropTypes.arrayOf(PropTypes.string),
  textAlignForHeaders: PropTypes.arrayOf(PropTypes.number),
  textAlignHeaders: PropTypes.arrayOf(PropTypes.string),
  textAlignForFooters: PropTypes.arrayOf(PropTypes.number),
  textAlignFooters: PropTypes.arrayOf(PropTypes.string),
  striped: PropTypes.bool,
};

export default CustomTable;
