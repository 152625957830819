import React, { createRef } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from "@mui/icons-material/Search";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

import Notif from "contents/components/Notif";

import secureStorage from "libraries/secureStorage";
import Config from "config";

import _ from "lodash";
import axios from "axios";

class AdminNominal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // State Notif
      isOpen: false,
      colorNotif: "success",
      messageNotif: "",

      admPost: [],
      admPre: [],
      admNon: [],

      selectedPost: null,
      selectedPre: "",
      selectedNon: "",

      isLoading: true,
      admPostOpen: false,
      admPreOpen: false,
      admNonOpen: false,
    };
  }

  componentDidMount() {
    this.getValues("Post");
    this.getValues("Pre");
    this.getValues("Non");

    this.loadAdminNominal("Pre", 15);
    this.loadAdminNominal("Post", 14);
    this.loadAdminNominal("Non", 984);
  }

  loadAdminNominal = (type, productId) => {
    axios
      .post(Config.EndPoint + "/product/admin", {
        productId,
      })
      .then((res) => {
        const data = res.data.map((item) => ({
          label: item.nominal.toString(),
          id: item.id,
        }));

        this.setState({
          ["adm" + type]: data,
        });
      })
      .catch((err) => {});
  };

  getValues = (type) => {
    const adminSelected = secureStorage.getItem(`adminNominal${type}`);
    this.setState({
      [`selected${type}`]: adminSelected === null ? "" : adminSelected,
    });
  };

  handleSelect = (type, value) => {
    this.setState({
      [`selected${type}`]: value,
    });
    if (!value) {
      secureStorage.removeItem(`adminNominal${type}`);
    } else {
      // const adminNominals = this.state[`adm${type}`];
      // const find = adminNominals.find((item) => parseInt(item.label) === parseInt(value.label));
      secureStorage.setItem(`adminNominal${type}`, value);
    }
  };

  render() {
    return (
      <Container>
        <MKBox component="section" width="100%" py={12}>
          <Notif
            open={this.state.isOpen}
            color={this.state.colorNotif}
            message={this.state.messageNotif}
          />

          <MKBox
            p={3}
            coloredShadow="secondary"
            borderRadius="5px"
            mb={3}
            bgColor="white"
            shadow="xl"
          >
            {/* TITLE HEADER */}
            <Grid container item xs={12} mt={2} md={12} mx="auto" justifyContent="center">
              <MKBox width="100%" display="flex" justifyContent="center">
                <MKTypography
                  mb={1}
                  variant="h5"
                  color="text"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  Pengaturan Defaut Admin
                </MKTypography>
              </MKBox>
            </Grid>

            <Grid container spacing={2} mt={1} justifyContent="flex-start">
              {/* PLN POSTPAID */}
              <Grid item xs={4} md={4} lg={4}>
                <Autocomplete
                  open={this.state.admPostOpen}
                  onOpen={() => this.setState({ admPostOpen: true })}
                  onClose={() => this.setState({ admPostOpen: false })}
                  options={this.state.admPost}
                  value={this.state.selectedPost}
                  renderOption={(rest, item) => {
                    rest.key = item.id;
                    return (
                      <span
                        style={{
                          color: "#000",
                          opacity: 1,
                          fontWeight: "bold",
                        }}
                        {...rest}
                      >
                        {item.label}
                      </span>
                    );
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value) => this.handleSelect("Post", value)}
                  renderInput={(params) => (
                    <MKInput
                      sx={{
                        input: {
                          "&::placeholder": { color: "#000", opacity: 1 },
                          fontWeight: "medium",
                        },
                      }}
                      {...params}
                      variant="outlined"
                      size="small"
                      label="PLN POSTPAID"
                      placeholder="PLN POSTPAID"
                    />
                  )}
                  fullWidth
                />
              </Grid>
              {/* PLN PREPAID */}
              <Grid item xs={4} md={4} lg={4}>
                <Autocomplete
                  open={this.state.admPreOpen}
                  onOpen={() => this.setState({ admPreOpen: true })}
                  onClose={() => this.setState({ admPreOpen: false })}
                  options={this.state.admPre}
                  value={this.state.selectedPre}
                  renderOption={(rest, item) => {
                    rest.key = item.id;
                    return (
                      <span
                        style={{
                          color: "#000",
                          opacity: 1,
                          fontWeight: "bold",
                        }}
                        {...rest}
                      >
                        {item.label}
                      </span>
                    );
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value) => this.handleSelect("Pre", value)}
                  renderInput={(params) => (
                    <MKInput
                      sx={{
                        input: {
                          "&::placeholder": { color: "#000", opacity: 1 },
                          fontWeight: "medium",
                        },
                      }}
                      {...params}
                      variant="outlined"
                      size="small"
                      label="PLN PREPAID"
                      placeholder="PLN PREPAID"
                    />
                  )}
                  fullWidth
                />
              </Grid>
              {/* PLN NONTAGLIS */}
              <Grid item xs={4} md={4} lg={4}>
                <Autocomplete
                  open={this.state.admNonOpen}
                  onOpen={() => this.setState({ admNonOpen: true })}
                  onClose={() => this.setState({ admNonOpen: false })}
                  options={this.state.admNon}
                  value={this.state.selectedNon}
                  renderOption={(rest, item) => {
                    rest.key = item.id;
                    return (
                      <span
                        style={{
                          color: "#000",
                          opacity: 1,
                          fontWeight: "bold",
                        }}
                        {...rest}
                      >
                        {item.label}
                      </span>
                    );
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value) => this.handleSelect("Non", value)}
                  renderInput={(params) => (
                    <MKInput
                      sx={{
                        input: {
                          "&::placeholder": { color: "#000", opacity: 1 },
                          fontWeight: "medium",
                        },
                      }}
                      {...params}
                      variant="outlined"
                      size="small"
                      label="PLN NONTAGLIS"
                      placeholder="PLN NONTAGLIS"
                    />
                  )}
                  fullWidth
                />
              </Grid>
            </Grid>
          </MKBox>
        </MKBox>
      </Container>
    );
  }
}

export default AdminNominal;
