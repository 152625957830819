import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";

import IconButton from "@mui/material/IconButton";
import Snack from "@mui/material/SnackbarContent";
import Icon from "@mui/material/Icon";
import Grow from "@mui/material/Grow";

import Close from "@mui/icons-material/Close";

class SnackbarContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  componentDidMount() {
    this.setState({ show: true });
  }

  closeAlert = () => {
    this.setState({ show: false });
  };

  render() {
    const { message, color, close, icon } = this.props;

    let snackIcon = null;
    //let iIcon = this.props.icon
    switch (typeof icon) {
      case "object":
        snackIcon = <this.props.icon fontSize="large" />;
        break;
      case "string":
        snackIcon = <Icon fontSize="large">{this.props.icon}</Icon>;
        break;
      default:
        snackIcon = null;
        break;
    }
    return (
      <Grow in={this.state.show} mountOnEnter unmountOnExit onExited={this.handleExited}>
        <MKBox component="section" width="100%">
          <MKAlert color={color} dismissible>
            {snackIcon}
            &nbsp;
            {message}
          </MKAlert>
        </MKBox>
      </Grow>
    );
  }
}

export default SnackbarContent;
