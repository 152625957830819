import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import MKButton from "components/MKButton";

export default function Remark({ children }) {
  return (
    <MKBox display="flex" flexDirection="column">
      <MKTypography variant="caption" color="secondary">
        {/* {children!=='-'?children:<MKButton>detail</MKButton>} */}
        {children}
      </MKTypography>
    </MKBox>
  );
}

// Typechecking props for the Remark
Remark.defaultProps = {
  children: '-'
}
Remark.propTypes = {
  children: PropTypes.any,
};