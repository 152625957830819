import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

import Loaded from "contents/components/Loaded";
import Notif from "contents/components/Notif";

import axios from "axios";
import config from "config";

function ChangePassword() {
  const [isLoading, setIsloading] = useState(false);
  // State Notif
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState("success");
  const [message, setMessage] = useState("");

  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const showNotif = ({ message, color }) => {
    setMessage(message);
    setColor(color);
    setIsOpen(true);
    setTimeout(() => setIsOpen(false), 2000);
  };

  const handleChange = (e) => {
    switch (e.target.id) {
      case "oldPass":
        setOldPass(e.target.value);
        break;
      case "newPass":
        setNewPass(e.target.value);
        break;
      case "confirmPass":
        setConfirmPass(e.target.value);
        break;
    }
  };

  const handleSubmit = () => {
    if (!oldPass && !newPass && !confirmPass) {
      showNotif({
        message: `Semua field harus diisi !`,
        color: "error",
      });
    } else if (newPass !== confirmPass) {
      showNotif({
        color: "error",
        message: "Password dan Re-type passwod harus sama !",
      });
    } else {
      setIsloading(true);
      axios
        .post(`${config.EndPoint}/user/changepass`, { oldPass, newPass })
        .then((r) => {
          setIsloading(false);
          showNotif({
            color: "success",
            message: r.data,
          });
        })
        .catch((err) => {
          setIsloading(false);
          if (err.response) {
            showNotif({
              color: "error",
              message: err.response.data,
            });
          }
        });
    }
  };

  return (
    <Container>
      <MKBox component="section" width="100%" py={12}>
        {/* LOADED */}
        <Loaded open={isLoading} />
        {/* ALERT TOAST */}
        <Notif open={isOpen} color={color} message={message} />

        <MKBox
          p={3}
          coloredShadow="secondary"
          borderRadius="5px"
          mb={3}
          bgColor="white"
          shadow="xl"
        >
          <Grid container item xs={12} mt={2} md={12} mx="auto" justifyContent="center">
            <MKBox width="100%" display="flex" justifyContent="center">
              <MKTypography
                mb={1}
                variant="h5"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
              >
                Ganti Password
              </MKTypography>
            </MKBox>
          </Grid>

          <Grid container spacing={2} mt={1} justifyContent="center">
            <Grid item xs={8} md={8}>
              <MKInput
                variant="standard"
                type="password"
                label="Passwrod Lama"
                id="oldPass"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={8} md={8}>
              <MKInput
                variant="standard"
                type="password"
                label="Passwrod Baru"
                id="newPass"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={8} md={8}>
              <MKInput
                variant="standard"
                type="password"
                label="Konfirmasi Passwrod"
                id="confirmPass"
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={8} md={8}>
              <MKBox display="flex" justifyContent="center">
                <MKButton
                  type="button"
                  variant="gradient"
                  color="info"
                  onClick={handleSubmit}
                  sx={{ marginRight: "5px" }}
                >
                  <Icon color="inherit" sx={{ mr: 2 }}>
                    payment
                  </Icon>
                  Submit
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
    </Container>
  );
}

export default ChangePassword;
