import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

import MKInput from "components/MKInput";

import axios from "axios";
import Config from "config";
import {
  createRef,
  Component,
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import secureStorage from "libraries/secureStorage";
import _ from "lodash";
import Table from "examples/Tables/Table";
// import CollectiveTable from "contents/components/TableCollective";
import SimpleTable from "contents/components/TableCollective/Table";
// import Item from "contents/Transaction/components/Item";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";
// import FNumber from "contents/components/Number";
// import Remark from "contents/Transaction/components/Remark";

import Receipt from "contents/components/Receipt";
// import PaymentConfirm from "contents/components/PaymentConfirm";
import { fnumber } from "helper/Helper";
import { CheckBox, CreditScore, DeleteForever, PageviewRounded } from "@mui/icons-material";

import readXlsx from "read-excel-file";

// import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css";
import Modal from "contents/components/Confirmation";
import AdminNominals from "contents/components/AdminNominals";

import print from "helper/printer";
import Confirmation from "contents/components/Confirmation";

import moment from "moment";
import "assets/locales/in-id";

const InitCollectives = forwardRef((props, ref) => {
  const [collectives, setCollectives] = useState([]);
  const [collectiveSelectedIndex, setCollectiveSelectedIndex] = useState(-1);
  const [selectedBatchSales, setSelectedBatchSales] = useState([]);
  const confirmRef = useRef();

  useImperativeHandle(ref, () => ({
    setListCollectives(item) {
      // console.log(item);
      setCollectives(item);
    },
  }));
  return (
    <MKBox width="100%" display="flex">
      <MKBox width="20%" sx={{ border: 1, borderRadius: 1 }}>
        <List
          component="nav"
          aria-label="secondary mailbox folder"
          sx={{ maxHeight: 600, overflow: "auto" }}
        >
          {collectives.map((row, index) => (
            <ListItemButton
              key={index}
              selected={collectiveSelectedIndex === index}
              onClick={(e) => {
                // this.setState({collectiveSelectedIndex:index,selectedBatchSales:row.BatchSales})
                setCollectiveSelectedIndex(index);
                setSelectedBatchSales(row.BatchSales);
                // this.initCollections({collective:row})
              }}
            >
              <ListItemText primary={row.name} disableTypography sx={{ fontSize: 14 }} />
            </ListItemButton>
          ))}
        </List>
      </MKBox>
      <MKBox width="80%" ml={1}>
        <Table
          columns={[
            // this.initCollections({collective:props.collectives[collectiveSelectedIndex]})
            { header: "History Tanggal", name: "tanggal", align: "center" },
            { name: "pilih", align: "center" },
          ]}
          rows={selectedBatchSales.map((batch, idx) => ({
            tanggal: (
              <MKTypography variant="button" fontWeight="bold">
                {moment(batch.createdAt).format("dddd, DD MMMM YYYY HH:mm:ss")}
              </MKTypography>
            ),
            pilih: (
              <MKButton
                color="info"
                variant="gradient"
                onClick={(e) =>
                  props.onChange &&
                  props.onChange({ collective: collectives[collectiveSelectedIndex], batch })
                }
              >
                buka
              </MKButton>
            ),
          }))}
        />
        {collectiveSelectedIndex !== -1 && (
          <>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <MKBox mt={2} mb={2}>
                  <MKButton
                    color="info"
                    variant="gradient"
                    onClick={(e) =>
                      props.onChange &&
                      props.onChange({ collective: collectives[collectiveSelectedIndex] })
                    }
                  >
                    transaksi baru
                  </MKButton>
                </MKBox>
              </Grid>
              <MKBox mt={2} mb={2}>
                <MKButton
                  color="error"
                  variant="gradient"
                  onClick={(e) => {
                    confirmRef.current.setShow(true);
                  }}
                >
                  hapus daftar kolektif
                </MKButton>
              </MKBox>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </>
        )}
      </MKBox>
      <Confirmation
        ref={confirmRef}
        title="Hapus Daftar Kolektif"
        body={
          <MKTypography>
            {collectiveSelectedIndex !== -1 ? collectives[collectiveSelectedIndex].name : ""}
          </MKTypography>
        }
        confirm="Hapus"
        onConfirm={() => {
          props.onDelete({ collective: collectives[collectiveSelectedIndex] });
          setCollectiveSelectedIndex(-1);
        }}
      />
    </MKBox>
  );
});

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allProducts: [],
      products: [],
      categories: [],
      customerCode: "",
      product: null,
      category: null,

      totalAmount: 0,
      changeAmount: 0,
      paidAmount: 0,

      categoryOpen: false,
      productOpen: false,
      rows: [],
      rowLength: 0,
      receiptTitle: "",
      receiptBody: "",

      filename: "",
      tags: [],
      inputTag: "",
      tagInputDisabled: false,
      fileCodes: [],
      btnSaveNameDisabled: true,
      btnSavedGroupDisabled: false,
      btnSaveDisabled: true,
      btnSaveFileDisabled: true,
      btnInquiryDisabled: true,
      btnPurchaseDisabled: true,
      btnPrintDisabled: true,

      isEditingName: false,

      prevCount: 0,
      prevName: "",

      collectiveName: "",
      collectives: [],

      selectedCollective: "",
      selectedBatch: "",
      collections: [],
      selectedBatchSales: [],

      totalTagihan: 0,
      totalQty: 0,
      totalAdmin: 0,
      totalBayar: 0,

      adminCollective: "",
      adminNominals: [],

      modalShow: false,
      modalTitle: "",
      modalBody: "",
      modalIsreceipt: false,

      printOpen: false,
      startNum: 1,
      endNum: 0,
      printSettings: false,
      sheetNum: 15,
      feedNum: 5,

      purchaseModal: false,
      check: {},

      printListModal: false,
      selectedPrinter: "",
      dotmatrix: "",
      printers: [],
      printListPayload: "",
    };
    this.customerCodeRef = createRef();
    this.productRef = createRef();
    this.categoryRef = createRef();
    this.purchaseRef = createRef();
    this.confirmRef = createRef();
    this.modalRef = createRef();
    this.modalCollectivesRef = createRef();
    this.modalPrintListRef = createRef();
    this.modalSettingRef = createRef();
    this.modalPrintRef = createRef();
    this.adminNominalsRef = createRef();

    this.initCollectivesRef = createRef();

    this.fileRef = createRef();
    // no idel nama proses status rptag blth admin total keterangan hapus
    this.columns = [
      { name: "no", align: "center" },
      { name: "idpel", align: "left" },
      { name: "nama", align: "center" },
      { name: "proses", align: "center" },
      { name: "status", align: "center" },
      { name: "rptag", align: "right" },
      { name: "blth", align: "center" },
      { name: "admin", align: "right" },
      { name: "total", align: "right" },
      { name: "keterangan", align: "center" },
      { name: "hapus", align: "center" },
    ];
  }

  componentDidMount() {
    moment.locale("in-id");
    // secureStorage.removeItem('savedTrx')
    // document.addEventListener("keydown", this._handleKeyDown);
    // const savedTrx = secureStorage.getItem('savedTrx')??[]
    // savedTrx.forEach((item,idx)=>this.setState({['checkPrint'+idx]:true}))
    // this.setState({
    //   rows :savedTrx,
    //   totalAmount : _.sumBy(savedTrx,({adminNominal,qty,nominal})=>(adminNominal*qty)+nominal)
    // })
    const printSettings = secureStorage.getItem("printSettings");
    printSettings && this.setState({ ...printSettings });
    // this.loadAdminNonminal();
    this.loadPrinter();
    this.loadProduct();
  }

  _handleKeyDown = (e) => {
    if (e.altKey) {
      switch (e.key) {
        case "i":
          this.customerCodeRef.current.focus();
          break;
        case "p":
          this.productRef.current.focus();
          this.setState({ productOpen: true });
          break;
        case "k":
          this.categoryRef.current.focus();
          this.setState({ categoryOpen: true });
          break;
        case "b":
          this.purchaseRef.current.click();
        default:
      }
    }
  };

  loadProduct = () => {
    axios
      .post(`${Config.EndPoint}/product/all`, { productTypeId: 1 })
      // .post(`${Config.EndPoint}/product/dropdown`, { productCategoryId: 4 })
      .then((r) => {
        const products = r.data.filter((item) => ![15].includes(item.id));
        const categories = _.uniqBy(
          products.map((item) => ({
            label: item.ProductCategory.name,
            id: item.ProductCategory.id,
          })),
          ({ id }) => id
        );
        this.setState({ products, categories, allProducts: products });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  filterProduct = (e, value) => {
    if (value)
      this.setState({
        category: value,
        product: null,
        products: this.state.allProducts.filter((item) => item.ProductCategory.id === value.id),
      });
    else this.setState({ products: this.state.allProducts, category: null });
  };
  loadPrinter = () => {
    let selectedPrinter = secureStorage.getItem("printer");
    const dotmatrix = secureStorage.getItem("dotmatrix");
    // const isElectron = navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1;
    // selectedPrinter = selectedPrinter ?? "deskjet";
    selectedPrinter = !selectedPrinter
      ? "deskjet"
      : ["thermal", "deskjet"]
      ? "deskjet"
      : selectedPrinter;
    const printers = dotmatrix === "online" ? [{ name: "dotmatrix", label: "DOT MATRIX" }] : [];
    printers.push({ name: "deskjet", label: "DESKJET/INKJET/LASERJET" });
    printers.push({ name: "pdf", label: "PDF" });
    this.setState({ selectedPrinter, printers });
  };

  processSingle = async (row, index, collections) => {
    // for (const row of nextBatch) {
    this.setState({
      ["loading" + row.id]: true,
    });
    const payload = {
      batch: this.state.selectedBatch,
      collective: this.state.selectedCollective,
      adminNominal: this.state.adminCollective,
      isIncluded: this.state.check[row.id],
      productId: this.state.product?.id,
      collection: row,
    };
    const startTime = moment();
    await axios
      .post(`${Config.EndPoint}/collective/inquiry`, payload)
      .then((res) => {
        //kene iki lemot
        // const collections = this.state.collections.map((item) =>
        //   item.id === row.id ? { ...item, ...res.data.collection } : item
        // );
        const endTime = moment();
        console.log({
          startTime: startTime.format("dddd, DD MMMM YYYY HH:mm:ss"),
          endTime: endTime.format("dddd, DD MMMM YYYY HH:mm:ss"),
          timeLapse: endTime.diff(startTime, "milliseconds"),
        });
        collections[index] = res.data.collection;
        this.setState({
          [`loading${row.id}`]: false,
          collections,
          ...this.setTotal(collections),
          selectedBatch: !this.state.selectedBatch ? res.data.batch : this.state.selectedBatch,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.setState({
            [`loading${row.id}`]: false,
            selectedBatch: !this.state.selectedBatch
              ? err.response.data.batch
              : this.state.selectedBatch,
          });
        }
      });
    // }
  };

  processBatch = async (nextBatch) => {
    let stateLoading = nextBatch.reduce((prev, curr) => {
      prev[`loading${curr.id}`] = !curr.BatchSalesDts ? true : false;
      return prev;
    }, {});

    this.setState({ ...stateLoading });
    let collections = this.state.collections;

    // console.log({ collections })
    for (const row of nextBatch) {
      if (!row.BatchSalesDts) {
        const productId = this.state.product?.id;

        const adminNominal = [14, 15, 984].includes(productId)
          ? { adminNominal: this.state.adminCollective }
          : {};

        const payload = {
          batch: this.state.selectedBatch,
          collective: this.state.selectedCollective,
          // adminNominal: this.state.adminCollective,
          ...adminNominal,
          isIncluded: this.state.check[row.id],
          productId,
          collection: row,
        };

        // console.log({ payload });
        await axios
          .post(`${Config.EndPoint}/collective/inquiry`, payload)
          .then((res) => {
            const data = res.data;
            collections = collections.map((item) =>
              item.id === row.id ? { ...item, ...data.collection } : item
            );
            this.setState({
              selectedBatch: !this.state.selectedBatch ? data.batch : this.state.selectedBatch,
            });
          })
          .catch((err) => {
            console.log(err.response);
            let remark = err.response.statusText;
            collections = collections.map((item) =>
              item.id === row.id ? { ...item, remark } : item
            );
            if (err.response) {
              this.setState({
                selectedBatch: !this.state.selectedBatch
                  ? err.response.data.batch
                  : this.state.selectedBatch,
              });
            }
          });
      }
    }

    const closeLoading = nextBatch.reduce((prev, curr) => {
      prev[`loading${curr.id}`] = !curr.BatchSalesDts ? false : true;
      return prev;
    }, {});

    this.setState({
      ...closeLoading,
      collections,
      ...this.setTotal(collections),
    });
  };

  handleInquiry = async (e) => {
    this.setState({
      btnInquiryDisabled: true,
      btnPurchaseDisabled: true,
      btnPrintDisabled: true,
    });

    const batchSize = 5;
    const collections = this.state.collections; //.filter((item) => !item.BatchSalesDts);

    // // PROCESS SINGLE REQUEST
    // for (const item of collections) {
    //   await this.processSingle(item);
    // }
    for (let index = 0; index < collections.length; index++) {
      if (!collections[index].BatchSalesDts) {
        await this.processSingle(collections[index], index, collections);
      }
    }

    // PROCESS MULTI REQUEST
    // for (let index = 0; index < collections.length; index += batchSize) {
    //   const nextBatch = collections.slice(index, index + batchSize);

    //   await this.processBatch(nextBatch);
    // }

    this.setState({
      // btnInquiryDisabled: false,
      btnPurchaseDisabled: false,
      btnPrintDisabled: false,
    });
  };
  handlePurchase = async (e) => {
    this.setState({
      // btnInquiryDisabled: true,
      btnPurchaseDisabled: true,
      btnPrintDisabled: true,
      purchaseModal: false,
    });
    let collections = this.state.collections;
    // console.log({ collections })
    for (let index = 0; index < collections.length; index++) {
      // console.log(collections[index].BatchSalesDts, collections[index].BatchSalesDts[0].TrSale.requestTypeI, collections[index].BatchSalesDts[0].TrSale.statusId)
      let row = collections[index];

      // console.log({ row, checked: this.state.check[row.id] })
      if (
        row.BatchSalesDts &&
        row.BatchSalesDts[0].TrSale &&
        row.BatchSalesDts[0].TrSale.requestTypeId === 1 &&
        row.BatchSalesDts[0].TrSale.statusId === 4 &&
        this.state.check[row.id]
      ) {
        this.setState({ ["loading" + row.id]: true });
        const payload = {
          collection: row,
        };

        await axios
          .post(`${Config.EndPoint}/collective/purchase`, payload)
          .then((res) => {
            // console.log(res.data)
            collections[index] = res.data.collection;
            // console.log({ collections, checkeds: this.state.check, setTotal: this.setTotal(collections) })
            this.setState({
              ["loading" + row.id]: false,
              collections: collections,
              ...this.setTotal(collections),
            });
          })
          .catch((err) => {
            delete row.BatchSalesDts;
            row = { ...row, remark: "Server Internal Error" };
            collections = collections.map((item) => (item.id === row.id ? row : item));

            this.setState({
              ["loading" + row.id]: false,
              collections,
            });
            console.log(err);
          });
      }
    }
    this.setState({
      // btnInquiryDisabled: false,
      btnPurchaseDisabled: false,
      btnPrintDisabled: false,
      purchaseModal: false,
    });
  };
  handlePrintDialog = (e) => {
    this.setState({
      printOpen: true,
      startNum: 1,
      endNum: this.state.collections.length,
    });
  };
  handlePrint = (e) => {
    const printer = secureStorage.getItem("printer");
    // console.log({ printer });
    if (!["dotmatrix", "deskjet"].includes(printer)) {
      alert("Printer belum support untuk cetak kolektif, selain dotmatix dan deskjet");
    } else {
      const startNum = this.state.startNum;
      const endNum = this.state.endNum;
      if (startNum !== "" && endNum !== "") {
        this.setState({ printOpen: false });
        // secureStorage.removeItem('printBatch')
        const printBatch = null; // secureStorage.getItem("printBatch");

        const options = {
          feedNum: this.state.feedNum,
          sheetNum: this.state.sheetNum,
        };
        if (
          printBatch &&
          printBatch.collectiveId === this.state.selectedCollective.id &&
          printBatch.batchId === this.state.selectedBatch.id
        ) {
          const data = printBatch.data.filter(
            (item, index) => index >= startNum - 1 && index <= endNum - 1
          );

          // console.log({ startNum, endNum, data });
          print(data, options);
        } else {
          const collections = this.state.collections.map((item) => item.id);
          // console.log({collections,batch:this.state.selectedBatch})

          axios
            .post(`${Config.EndPoint}/collective/detail/all`, {
              collections,
              batch: this.state.selectedBatch,
            })
            .then((res) => {
              secureStorage.setItem("printBatch", {
                data: res.data,
                collectiveId: this.state.selectedCollective.id,
                batchId: this.state.selectedBatch.id,
              });

              const data = res.data.filter(
                (item, index) => index >= startNum - 1 && index <= endNum - 1
              );

              print(data, options);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  };
  handleViewDetail = (salesId) => {
    axios
      .post(`${Config.EndPoint}/collective/detail`, { salesId })
      .then((res) => {
        //console.log(res)

        this.setState({
          modalShow: true,
          modalIsreceipt: true,
          modalTitle: res.data.receiptTitle,
          modalBody: res.data.receiptBody,
        });
        this.modalRef.current.setShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleModal = (params) => {
    if (params.action === "print") {
      print({ title: this.state.modalTitle, body: this.state.modalBody });
    } else if (params.action === "deleteCollective") {
      this.removeCollective(params.collective);
    }
  };
  removeCollective = (collective) => {
    this.setState({
      collectiveName: "",
      selectedCollective: "",
      selectedBatch: "",
      collections: [],
      btnSavedGroupDisabled: false,
      btnPrintDisabled: true,
      btnPurchaseDisabled: true,
      modalTitle: "",
      modalBody: "",
      modalShow: false,
    });
    axios
      .post(`${Config.EndPoint}/collective/remove`, { collective })
      .then((res) => {
        const collectives = this.state.collectives.filter((item) => item.id !== collective.id);
        this.setState({ collectives });
        this.initCollectivesRef.current.setListCollectives(collectives);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleRemoveCollection = (collection, index) => {
    const collections = this.state.collections;
    axios
      .post(`${Config.EndPoint}/collective/collection/remove`, { collection })
      .then((res) => {
        //console.log(res)
        collections.splice(index, 1);
        this.setState({ collections: collections });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleChangeName = (e) => {
    if (this.state.isEditingName) {
      axios
        .post(`${Config.EndPoint}/collective/changename`, {
          collectiveName: this.state.collectiveName,
          collective: this.state.selectedCollective,
        })
        .then((res) => {
          this.setState({ isEditingName: false });
        })
        .catch((err) => {
          this.errorMessage(err);
        });
    } else {
      this.setState({ isEditingName: true });
    }
  };
  handleSaveTags = (e) => {
    this.setState({
      btnSaveFileDisabled: true,
      btnSaveDisabled: true,
    });
    const newCustomerCodes = this.state.tags.length > 0 ? this.state.tags : this.state.fileCodes;
    const collCustomerCodes = this.state.collections.map((item) => item.customerCode);
    const customerCodes = newCustomerCodes.filter((tag, index) => !collCustomerCodes.includes(tag));

    axios
      .post(`${Config.EndPoint}/collective/collection/save`, {
        customerCodes,
        collective: this.state.selectedCollective,
        collectiveName: this.state.collectiveName,
        batch: this.state.selectedBatch,
      })
      .then((res) => {
        this.setState({
          tags: [],

          //data
          collections: res.data.collections,
          selectedCollective: res.data.collective,
          collectiveName: res.data.collective.name,
          ...this.setTotal(res.data.collections),
          //button enabled/disable
          // btnInquiryDisabled: this.state.adminCollective ? false : true,
          btnInquiryDisabled: false,
          btnPurchaseDisabled: false,
          btnPrintDisabled: false,
        });
        // this.filSaved()
      })
      .catch((err) => {});
  };
  handleTags = (regularTags) => {
    if (
      this.state.collections.length === 0 ||
      (this.state.collections.length > 0 && !this.state.collections[0].BatchSalesDts)
    ) {
      const collCustomerCodes = this.state.collections.map((item) => item.customerCode);
      const tags = regularTags
        .filter(
          (d) =>
            d !== "" &&
            // !isNaN(d) &&
            d.toString().length >= 10 &&
            d.toString().length <= 12
        )
        .filter((tag, index) => !collCustomerCodes.includes(tag));

      let btnSaveDisabled = true;
      if (tags.length > 0) btnSaveDisabled = false;

      this.setState({
        tags: [...new Set(this.state.tags.concat(tags))],
        btnSaveDisabled,
        inputTag: "",
      });
    }
  };

  initSavedGroup = (e) => {
    this.setState({ btnSavedGroupDisabled: true });
    axios
      .post(`${Config.EndPoint}/collective/get`)
      .then((res) => {
        this.modalCollectivesRef.current.setShow(true);
        this.initCollectivesRef.current.setListCollectives(res.data);
        this.setState({
          tags: [],
          collectives: res.data,
          btnSaveFileDisabled: true,
          btnSaveDisabled: true,
          // modalShow: true,
          // modalTitle: "DAFTAR KOLEKTIF",
          // modalBody: "initCollectives",
        });
      })
      .catch((err) => {});
  };
  initCollections = (params) => {
    this.setState({ modalShow: false });
    axios
      .post(`${Config.EndPoint}/collective/collection/get`, {
        batch: params.batch,
        collective: params.collective,
      })
      .then((res) => {
        this.setState({
          modalShow: false,
          collections: res.data.collections,
          selectedCollective: res.data.collective,
          collectiveName: res.data.collective.name,
          selectedBatch: params.batch ? params.batch : null,
          ...this.setTotal(res.data.collections, true),
          //button enabled/disable
          // btnInquiryDisabled: this.state.adminCollective ? false : true,
          btnInquiryDisabled: false,
          btnPurchaseDisabled: false,
          btnPrintDisabled: false,
          btnSavedGroupDisabled: false,
        });
      })
      .catch((err) => {});
  };

  changeTotal(batchDt, checked) {
    let totalTagihan = this.state.totalTagihan;
    let totalQty = this.state.totalQty;
    let totalAdmin = this.state.totalAdmin;
    let totalBayar = this.state.totalBayar;

    const sale = batchDt.TrSale;
    const admin = parseInt(batchDt.adminNominal);
    const amount = parseInt(sale.amount);
    const qty = parseInt(sale.qty);

    if (checked) {
      totalQty += qty;
      totalTagihan += amount;
      totalAdmin += admin * qty;
      totalBayar += amount + admin * qty;
    } else {
      totalQty -= qty;
      totalTagihan -= amount;
      totalAdmin -= admin * qty;
      totalBayar -= amount + admin * qty;
    }
    this.setState({ totalQty, totalTagihan, totalAdmin, totalBayar });
  }

  setTotal(collections, isNew) {
    let totalTagihan = 0;
    let totalQty = 0;
    let totalAdmin = 0;
    let totalBayar = 0;
    // console.log({ collections });
    const check = isNew ? {} : this.state.check;
    // console.log({ check })
    collections.map((item, index) => {
      //console.log(item)
      if (item.BatchSalesDts && item.BatchSalesDts.length > 0 && item.BatchSalesDts[0].TrSale) {
        // console.log('PURCHASE')
        const batchSale = item.BatchSalesDts[0];
        if (batchSale.isIncluded) {
          const sale = batchSale.TrSale;

          const admin = sale && sale.statusId !== 2 ? parseInt(batchSale.adminNominal) : 0;
          const qty = sale.statusId === 4 && sale && sale.qty ? parseInt(sale.qty) : 0;
          const amount = sale.statusId === 4 && sale && sale.amount ? parseInt(sale.amount) : 0;
          // const qty = sale && sale.statusId !== 2 ? parseInt(sale.qty) : 0;
          // const amount = sale && sale.statusId !== 2 ? parseInt(sale.amount) : 0;

          totalQty += qty;
          totalTagihan += amount;
          totalAdmin += admin * qty;
          totalBayar += amount + admin * qty;
        }
        check[item.id] = batchSale.isIncluded;
      } else if (item.BatchSalesDts) {
        check[item.id] = item.BatchSalesDts[0].isIncluded;
      } else {
        if (check[item.id] === undefined) check[item.id] = true;
        if (item.remark !== null) check[item.id] = false; // set false jika response inquiry statusCode 500
      }
      return item;
    });

    const result = { totalTagihan, totalQty, totalAdmin, totalBayar, check };
    // console.log({ result });
    return result;
  }
  handleReset = () => {
    this.setState({
      selectedBatch: "",
      collections: [],
      collectiveName: "",
      selectedCollective: "",

      fileCodes: [],
      filename: "",
      tags: [],
      inputTag: "",
      tagInputDisabled: false,
      btnSaveDisabled: true,
      btnSaveFileDisabled: true,

      product: null,
      adminCollective: "",

      // btnInquiryDisabled: this.state.adminCollective ? false : true,
      btnInquiryDisabled: false,
      btnPurchaseDisabled: true,
      btnPrintDisabled: true,
      btnSavedGroupDisabled: false,

      totalTagihan: 0,
      totalQty: 0,
      totalAdmin: 0,
      totalBayar: 0,
      check: {},
    });
  };

  getStatus = (row) => {
    const batchDt = row.BatchSalesDts;
    if (!batchDt) {
      return (
        <MKBadge
          size="xs"
          variant="gradient"
          color={row.remark === null ? "info" : "error"}
          badgeContent={row.remark === null ? "READY" : "error"}
        />
      );
    } else {
      if (batchDt[0].TrSale) {
        if (batchDt[0].TrSale.statusId === 2) {
          return <MKBadge color="error" badgeContent="gagal" size="xs" variant="gradient" />;
        } else if (batchDt[0].TrSale.statusId === 1) {
          return <MKBadge color="warning" badgeContent="pending" size="xs" variant="gradient" />;
        } else {
          if (batchDt[0].TrSale.requestTypeId === 1) {
            return <MKBadge color="info" badgeContent="inquiry" size="xs" variant="gradient" />;
          } else {
            return <MKBadge color="success" badgeContent="sukses" size="xs" variant="gradient" />;
          }
        }
      } else {
        return <MKBadge color="secondary" badgeContent="SKIP" size="xs" variant="gradient" />;
      }
    }
    // return
  };

  errorMessage(err) {
    let title;
    let message;
    if (err.response) {
      title = err.response.statusText;
      message = err.response.data;
    } else {
      title = "Error";
      message = "Koneksi terputus";
    }
    this.setState({
      modalShow: true,
      modalTitle: title,
      modalBody: message,
    });
  }

  render() {
    return (
      <>
        <AdminNominals
          ref={this.adminNominalsRef}
          onConfirm={(adminNominal) => {
            this.setState({ adminCollective: adminNominal }, () => {
              this.handleInquiry();
            });
          }}
        />
        <Modal
          ref={this.modalCollectivesRef}
          fullScreen
          isConfirm={false}
          onClose={() => this.setState({ btnSavedGroupDisabled: false })}
          onConfirm={() => {
            this.setState({ btnSavedGroupDisabled: false });
          }}
          title="DAFTAR KOLEKTIF"
          body={
            <InitCollectives
              ref={this.initCollectivesRef}
              // collectives={this.state.collectives}
              onChange={({ collective, batch }) => {
                this.setState({
                  btnSavedGroupDisabled: false,
                  btnInquiryDisabled: false,
                  btnPurchaseDisabled: false,
                });
                this.initCollections({ collective, batch });
                this.modalCollectivesRef.current.setShow(false);
              }}
              onDelete={(params) => {
                this.setState({ btnSavedGroupDisabled: false });
                this.handleModal({ ...params, action: "deleteCollective" });
                // this.modalRef.current.setShow(false);
              }}
            />
          }
          transition="slide"
        />
        <Receipt
          ref={this.modalRef}
          isPrice={false}
          title={this.state.modalTitle}
          body={this.state.modalBody}
        />
        {/* <Modal
          ref={this.modalRef}
          fullScreen
          onConfirm={() => { }}
          title={this.state.modalTitle}
          body={this.state.modalBody}
          transition="slide"
        /> */}
        <Modal
          ref={this.modalPrintListRef}
          title="CETAK LIST"
          onConfirm={this.handlePrintList}
          body={
            <>
              {/* <p>Pilih printer</p>
              { 
                this.state.printers.map((item,index)=>(
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal} key={index}>
                  <FormControl classes={{label: classes.label}} label={item.label}
                  style={{color:'black'}}	
                    control={
                      <Radio checked={this.state.selectedPrinter === item.name} value={item.name} name="printer" aria-label="A"
                        // disabled={item.name==='dotmatrix' ? this.state.dotmatrix==="online"?false:true:false}
                        icon={<FiberManualRecord className={classes.radioUnchecked} />} checkedIcon={ <FiberManualRecord className={classes.radioChecked} />}
                        classes={{ checked: classes.radio,root: classes.radioRoot}}
                        onChange={(e)=>this.setState({selectedPrinter:e.target.value})}
                      />
                    }
                  />
                </div>
              ))} */}
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Pilih Printer</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={this.state.selectedPrinter}
                  onChange={(e) => this.setState({ selectedPrinter: e.target.value })}
                  name="controlled-radio-buttons-group"
                >
                  {this.state.printers.map((item, index) => (
                    <FormControlLabel
                      value={item.name}
                      control={<Radio />}
                      label={item.label}
                      key={index}
                    />
                  ))}

                  {/* <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                </RadioGroup>
              </FormControl>
            </>
          }
        />
        {/* <PaymentConfirm ref={this.confirmRef} onConfirm={this.handlePurchase} /> */}
        <Modal
          ref={this.modalSettingRef}
          // fullScreen
          onConfirm={() => {
            secureStorage.setItem("printSettings", {
              feedNum: this.state.feedNum,
              sheetNum: this.state.sheetNum,
            });
          }}
          title="Pengaturan Printer"
          confirm="simpan"
          body={
            <>
              <MKInput
                fullWidth
                label="Lembar #"
                size="small"
                value={this.state.sheetNum}
                placeholder="Jumlah Lembar"
                onChange={(e) => this.setState({ sheetNum: e.target.value })}
              />
              <MKInput
                style={{
                  marginTop: 20,
                }}
                fullWidth
                label="Feed #"
                size="small"
                value={this.state.feedNum}
                placeholder="Jumlah Feed"
                onChange={(e) => this.setState({ feedNum: e.target.value })}
              />
            </>
          }
        />
        <Modal
          ref={this.modalPrintRef}
          // fullScreen
          onConfirm={this.handlePrint}
          title="CETAK KOLEKTIF"
          confirm="cetak"
          body={
            <>
              <MKInput
                fullWidth
                label="Dari #"
                size="small"
                type="number"
                value={this.state.startNum}
                placeholder="Dari Nomor"
                onChange={(e) => {
                  if (e.target.value !== "") {
                    let val = parseInt(e.target.value);
                    if (val < 1) val = 1;
                    else if (val > this.state.endNum) val = this.state.endNum;
                    this.setState({ startNum: val });
                  } else this.setState({ startNum: "" });
                }}
              />
              <MKInput
                style={{
                  marginTop: 20,
                }}
                fullWidth
                label="Sampai #"
                size="small"
                type="number"
                value={this.state.endNum}
                placeholder="Sampai Nomor"
                onChange={(e) => {
                  if (e.target.value !== "") {
                    let val = parseInt(e.target.value);
                    if (val > this.state.collections.length) val = this.state.collections.length;
                    else if (val < this.state.startNum) val = this.state.startNum;
                    this.setState({ endNum: val });
                  } else this.setState({ endNum: "" });
                }}
              />
            </>
          }
        />
        {/* <Receipt ref={this.modalRef} title={this.state.receiptTitle} body={this.state.receiptBody} /> */}
        {/* <Counters /> */}
        <MKBox
          component="header"
          minHeight="100vh"
          width="100%"
          mb={3}
          // mt={{xs:-20,md:-35}}
          sx={{
            display: "grid",
            // placeItems: "center",
          }}
          style={{ outline: "none" }}
        >
          <Container>
            <Grid
              container
              // style={{backgroundColor:'#000'}}
              spacing={3}
              justifyContent="center"
              pt={{ xs: 5, lg: 1 }}
              mt={0}
            >
              {/* FORM FILTER */}
              <Grid item xs={12} lg={12} xl={12} mt={8} justifyContent="center">
                <MKBox
                  display="flex"
                  // flexDirection="column"
                  justifyContent="center"
                  // alignItems="center"
                  // px={2}
                  // mb={{ xs: 2, md: 2 }}
                >
                  {/* CATEGORY */}
                  <MKBox my={2} mx={{ xs: 2, md: 0 }} width="50%">
                    <Autocomplete
                      open={this.state.categoryOpen}
                      value={this.state.category}
                      onOpen={() => this.setState({ categoryOpen: true })}
                      onClose={() => this.setState({ categoryOpen: false })}
                      // defaultValue="Washington"
                      options={this.state.categories}
                      renderOption={(rest, item) => {
                        rest.key = item.id;
                        return (
                          <span
                            style={{
                              color: "#000",
                              opacity: 1,
                              fontWeight: "bold",
                            }}
                            {...rest}
                          >
                            {item.label}
                          </span>
                        );
                      }}
                      fullWidth
                      onChange={this.filterProduct}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MKInput
                          sx={{
                            input: {
                              "&::placeholder": { color: "#000", opacity: 1 },
                              fontWeight: "medium",
                            },
                          }}
                          {...params}
                          inputRef={this.categoryRef}
                          variant="outlined"
                          size="small"
                          placeholder="KATEGORI"
                        />
                      )}
                    />
                  </MKBox>
                  {/* PRODUCT */}
                  <MKBox
                    my={2}
                    mx={{ xs: 2, md: 2 }}
                    //  mt={{ xs: 2, md: 0 }}
                    // mb={{ xs: 2, md: 2 }}
                    mr={{ xs: 2, md: 0 }}
                    width="50%"
                  >
                    <Autocomplete
                      value={this.state.product}
                      open={this.state.productOpen}
                      onOpen={() => this.setState({ productOpen: true })}
                      onClose={() => this.setState({ productOpen: false })}
                      // onKeyDown={this.handleInquiry}
                      options={this.state.products.map((item) => {
                        return {
                          label: item.name,
                          id: item.id,
                        };
                      })}
                      renderOption={(rest, item) => {
                        rest.key = item.id;
                        return (
                          <span
                            style={{
                              color: "#000",
                              opacity: 1,
                              fontWeight: "bold",
                            }}
                            {...rest}
                          >
                            {item.label}
                          </span>
                        );
                      }}
                      onChange={(e, value) => {
                        // value !== -1 && this.setState({ product: value })
                        if (value) {
                          const product = _.find(this.state.products, { id: value.id });
                          const AdminNominals = product
                            ? product.AdminNominals.sort((a, b) => a.nominal - b.nominal)
                            : [];

                          this.setState({ product: value });
                          this.adminNominalsRef.current.setData({ AdminNominals });
                        } else this.setState({ product: null });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      fullWidth
                      renderInput={(params) => (
                        <MKInput
                          sx={{
                            input: {
                              "&::placeholder": { color: "#000", opacity: 1 },
                              fontWeight: "medium",
                            },
                          }}
                          onClick={() => this.state.products.length === 0 && this.loadProduct()}
                          {...params}
                          inputRef={this.productRef}
                          size="small"
                          variant="outlined"
                          placeholder="PRODUK"
                        />
                      )}
                    />
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={12} xl={12} mt={-4} justifyContent="center">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <MKBox mt={{ xs: 1 }} display="flex" mr={1}>
                      <MKInput
                        fullWidth
                        label="NAMA DAFTAR KOLEKTIF"
                        size="small"
                        value={this.state.collectiveName}
                        placeholder="NAMA DAFTAR KOLEKTIF"
                        inputProps={{
                          readOnly: this.state.selectedCollective
                            ? this.state.isEditingName
                              ? false
                              : true
                            : false,
                        }}
                        onChange={(e) => this.setState({ collectiveName: e.target.value })}
                      />
                      {this.state.selectedCollective && (
                        <Tooltip
                          title={this.state.isEditingName ? "Simpan" : "Ubah"}
                          placement="right"
                        >
                          <MKButton
                            iconOnly
                            circular
                            color={this.state.isEditingName ? "primary" : "info"}
                            size="small"
                            variant="gradient"
                            onClick={this.handleChangeName}
                          >
                            <Icon color="inherit">
                              {this.state.isEditingName ? "save" : "edit"}
                            </Icon>
                          </MKButton>
                        </Tooltip>
                      )}

                      <Tooltip title="List Kolektif" placement="right" style={{ marginLeft: 20 }}>
                        <MKButton
                          // iconOnly
                          color="info"
                          size="small"
                          variant="gradient"
                          disabled={this.state.btnSavedGroupDisabled}
                          onClick={this.initSavedGroup}
                        >
                          <Icon color="inherit">list</Icon>
                          daftar
                        </MKButton>
                      </Tooltip>
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKBox mt={{ xs: 1 }} display="flex">
                      <input
                        type="file"
                        name="fileInput"
                        ref={this.fileRef}
                        onChange={(e) => {
                          if (e.target.files.length === 1) {
                            const file = e.target.files[0];
                            const filename = file.name;
                            const ext = filename.split(".")[1];
                            if (
                              this.state.collections.length === 0 ||
                              (this.state.collections.length > 0 &&
                                !this.state.collections[0].BatchSalesDts)
                            ) {
                              if (["xlsx", "xls"].indexOf(ext) + 1) {
                                //console.log(ext)
                                readXlsx(file).then((rows) => {
                                  const arr = [
                                    ...new Set(
                                      [].concat.apply([], rows).filter(
                                        (d) => d !== null && !isNaN(d)
                                        // d.toString().length >= 11 &&
                                        // d.toString().length <= 12
                                      )
                                    ),
                                  ];
                                  this.setState({
                                    btnSaveFileDisabled: false,
                                    fileCodes: arr,
                                    filename,
                                  });
                                });
                              } else if (ext === "txt") {
                                const fileReader = new FileReader();
                                fileReader.onloadend = (f) => {
                                  const content = fileReader.result;
                                  const arr = [
                                    ...new Set(
                                      content
                                        .split(
                                          new RegExp(
                                            [
                                              ",",
                                              ";",
                                              "\\(",
                                              "\\)",
                                              "\\*",
                                              "/",
                                              ":",
                                              "\\?",
                                              "\n",
                                              "\r",
                                            ].join("|")
                                          )
                                        )
                                        .map((d) => d.trim())
                                        .filter(
                                          (d) => d !== "" && !isNaN(d)
                                          // d.toString().length >= 11 &&
                                          // d.toString().length <= 12
                                        )
                                    ),
                                  ];
                                  this.setState({
                                    btnSaveFileDisabled: false,
                                    fileCodes: arr,
                                    filename,
                                  });
                                };
                                fileReader.readAsText(file);
                              } else if (ext === "pdf") {
                                const pdfExtract = new PDFExtract();
                                const options = {}; /* see below */
                                pdfExtract
                                  .extract(file, options)
                                  .then((data) => console.log(data))
                                  .catch((err) => console.log(err));
                              }
                            }
                          }
                        }}
                        hidden
                      />
                      <MKInput
                        fullWidth
                        value={this.state.filename}
                        label="SALIN DARI FILE"
                        size="small"
                        placeholder="Pilih file"
                        readOnly
                        onClick={() => {
                          this.fileRef.current.click();
                        }}
                      />

                      <MKButton
                        style={{ marginLeft: 20 }}
                        // iconOnly
                        color="info"
                        size="small"
                        variant="gradient"
                        disabled={this.state.btnSaveFileDisabled}
                        onClick={this.handleSaveTags}
                      >
                        <Icon color="inherit">add</Icon>
                        tambahkan
                      </MKButton>
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>
              {/* INPUT IDPEL */}
              <Grid item xs={12} lg={12} xl={12} mt={-3} justifyContent="center">
                <MKBox mt={{ xs: 2 }} display="flex" width="100%" style={{ flexWrap: "wrap" }}>
                  {this.state.tags.map((item, index) => (
                    <Chip
                      label={item}
                      key={index}
                      color="info"
                      size="small"
                      onDelete={() => {
                        let tags = this.state.tags;
                        tags.splice(tags.indexOf(item), 1);
                        this.setState({ tags: [...tags] });
                      }}
                    />
                  ))}
                </MKBox>
                <MKBox display="flex">
                  <MKInput
                    fullWidth
                    value={this.state.inputTag}
                    name="inputTag"
                    label="IDPEL"
                    size="small"
                    placeholder="Copy paste atau masukkan IDPEL kemudian tekan Enter"
                    onChange={(e) => {
                      this.setState({ inputTag: e.target.value }, () => {
                        // console.log(this.state.tagInputDisabled)
                        this.state.tagInputDisabled &&
                          this.setState({ inputTag: "", tagInputDisabled: false });
                      });
                    }}
                    onPaste={(e) => {
                      const product = this.state.product;
                      this.setState({ tagInputDisabled: true }, () => {
                        const data = e.clipboardData.getData("Text");
                        const separators = [
                          "\\|",
                          ",",
                          ";",
                          "\\(",
                          "\\)",
                          "\\*",
                          "/",
                          ":",
                          "\\?",
                          "\n",
                          "\r",
                        ];
                        const tags = [
                          ...new Set(
                            data
                              .split(new RegExp(separators.join("|")))
                              .map((d) => d.trim())
                              .filter(
                                (d) => d !== null && !isNaN(d)
                                // d.toString().length >= 10 &&
                                // d.toString().length <= 12
                              )
                          ),
                        ];
                        this.handleTags(tags);
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.handleTags([this.state.inputTag]);
                      }
                    }}
                    type="tel"
                  />

                  <MKButton
                    style={{ marginLeft: 20 }}
                    // iconOnly
                    color="info"
                    size="small"
                    variant="gradient"
                    disabled={this.state.btnSaveDisabled}
                    onClick={this.handleSaveTags}
                  >
                    <Icon color="inherit">add</Icon>
                    tambahkan
                  </MKButton>
                </MKBox>
              </Grid>
              {/* TABLE LIST */}
              <Grid item xs={12} lg={12} xl={12} mt={-1} justifyContent="center">
                <MKBox mt={0} mb={3}>
                  {/* <TableOne /> */}
                  <Grid container item xs={12} lg={12}>
                    <SimpleTable
                      columns={[
                        { name: "no", align: "center" },
                        { name: "idpel", align: "left" },
                        { name: "nama", align: "center" },
                        { name: "proses", align: "center" },
                        { name: "status", align: "center" },
                        { name: "rptag", align: "right" },
                        { name: "blth", align: "center" },
                        { name: "admin", align: "right" },
                        { name: "total", align: "right" },
                        { name: "keterangan", align: "center" },
                        { name: "hapus", align: "center" },
                      ]}
                      footer={[
                        { value: "-", align: "center" },
                        { value: "-", align: "left" },
                        { value: "-", align: "center" },
                        { value: "-", align: "center" },
                        { value: "TOTAL", align: "center" },
                        { value: fnumber(this.state.totalTagihan), align: "right" },
                        { value: fnumber(this.state.totalQty), align: "center" },
                        { value: fnumber(this.state.totalAdmin), align: "right" },
                        { value: fnumber(this.state.totalBayar), align: "right" },
                        { value: "-", align: "center" },
                        { value: "-", align: "center" },
                      ]}
                      rows={this.state.collections.map((row, index) => {
                        const saleDts =
                          row.BatchSalesDts && row.BatchSalesDts.length > 0 && row.BatchSalesDts[0];
                        // const sale = row.BatchSalesDts && row.BatchSalesDts[0].TrSale;
                        const sale = saleDts && saleDts.TrSale;

                        const receiptBody =
                          sale && [1, 2, 4].includes(sale.statusId)
                            ? typeof sale.receiptBody === "object"
                              ? sale.receiptBody
                              : JSON.parse(sale.receiptBody)
                            : false;
                        const name = row.customerName
                          ? row.customerName
                          : receiptBody && [1, 4].includes(sale.statusId)
                          ? Array.isArray(receiptBody)
                            ? receiptBody[1][1]
                            : receiptBody.name.value
                          : "-";

                        let remark = "-";
                        if (receiptBody) {
                          if ([2].includes(sale.statusId)) {
                            remark = Array.isArray(receiptBody)
                              ? receiptBody[0][0]
                              : receiptBody.message;
                          } else {
                            remark = (
                              <MKButton
                                size="small"
                                iconOnly
                                circular
                                color="info"
                                disabled={!row.BatchSalesDts ? true : false}
                                onClick={(e) => {
                                  this.handleViewDetail(sale ? sale.id : null);
                                }}
                              >
                                <PageviewRounded />
                              </MKButton>
                            );
                          }
                        } else remark = row.remark;
                        let checkedDisabled = false;
                        if (sale && sale.statusId === 2) checkedDisabled = true;
                        // if (row.BatchSalesDts && !row.BatchSalesDts[0].isIncluded && !sale)
                        if (saleDts && !saleDts.isIncluded && !sale) checkedDisabled = true;

                        const qty = sale && sale.qty ? sale.qty : 1;
                        const amount = sale && sale.amount ? sale.amount : "0";
                        const adminNominal =
                          saleDts && saleDts.adminNominal ? saleDts.adminNominal : 0;
                        const totalAdmin = adminNominal * qty;

                        return {
                          no: index + 1,
                          idpel: row.customerCode,
                          nama: name,
                          proses: (
                            <Checkbox
                              disabled={checkedDisabled}
                              // checked={this.state.check[index]}
                              // id={"check" + index}
                              checked={this.state.check[row.id]}
                              id={`check${row.id}`}
                              onClick={(e) => {
                                const checked = e.target.checked;
                                const check = this.state.check;
                                check[row.id] = checked;

                                this.setState({ check });
                                if (
                                  row.BatchSalesDts &&
                                  sale &&
                                  sale.requestTypeId === 1 &&
                                  sale.statusId === 4
                                ) {
                                  this.changeTotal(saleDts, checked);
                                  axios
                                    .post(`${Config.EndPoint}/collective/included`, {
                                      batchDtId: saleDts && saleDts.id,
                                      isIncluded: checked,
                                    })
                                    .then((res) => {
                                      // saleDts.isIncluded = checked;
                                      if (saleDts) saleDts.isIncluded = checked;
                                      const collections = this.state.collections.map((item) =>
                                        item.id === row.id ? row : item
                                      );

                                      console.log({ collections });
                                      this.setState({ collections });
                                    })
                                    .catch((err) => {
                                      check[row.id] = !checked;
                                      this.changeTotal(saleDts, !checked);
                                      this.setState({ check });
                                    });
                                }
                              }}
                            />
                          ),
                          status: this.state["loading" + row.id] ? (
                            <MKBadge
                              color="warning"
                              badgeContent="loading"
                              size="xs"
                              variant="gradient"
                            />
                          ) : (
                            // this.getStatus(row.BatchSalesDts)
                            this.getStatus(row)
                          ),
                          rptag: sale && sale.statusId !== 2 ? fnumber(amount) : "0",
                          blth: sale && sale.statusId !== 2 && sale.qty ? fnumber(sale.qty) : "0",
                          admin: sale && sale.statusId !== 2 ? fnumber(totalAdmin) : "0",
                          total:
                            sale && sale.statusId !== 2
                              ? fnumber(parseInt(amount) + totalAdmin)
                              : "0",
                          keterangan: remark,
                          hapus: (
                            <MKButton
                              size="small"
                              iconOnly
                              circular
                              color="error"
                              onClick={(e) => this.handleRemoveCollection(row, index)}
                            >
                              <DeleteForever />
                            </MKButton>
                          ),
                        };
                      })}
                    />
                  </Grid>
                </MKBox>
                <MKBox
                  display="flex"
                  // justifyContent="right"
                  justifyContent="space-between"
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  <MKBox width="30%">
                    <MKButton
                      style={{ marginRight: 20 }}
                      color="error"
                      size="medium"
                      variant="gradient"
                      onClick={this.handleReset}
                    >
                      reset
                    </MKButton>
                  </MKBox>
                  <MKBox width="70%" display="flex" justifyContent="right">
                    <MKButton
                      // iconOnly
                      style={{ marginRight: 20 }}
                      color="info"
                      size="medium"
                      variant="gradient"
                      disabled={this.state.btnInquiryDisabled}
                      onClick={() => {
                        // this.handleInquiry
                        if (this.state.product) {
                          this.adminNominalsRef.current.setShow(true, this.state.product.id);
                        }
                      }}
                    >
                      <CheckBox />
                      cek tagihan
                    </MKButton>
                    <MKButton
                      // iconOnly
                      style={{ marginRight: 20 }}
                      color="info"
                      size="medium"
                      variant="gradient"
                      disabled={this.state.btnPrintDisabled}
                      onClick={this.printListModal}
                    >
                      <Icon color="inherit">print</Icon>
                      cetak list
                    </MKButton>
                    <MKButton
                      // iconOnly
                      style={{ marginRight: 20 }}
                      color="info"
                      size="medium"
                      variant="gradient"
                      disabled={this.state.btnPurchaseDisabled}
                      onClick={this.handlePurchase}
                    >
                      <CreditScore />
                      bayar
                    </MKButton>
                    <MKButton
                      // iconOnly
                      style={{ marginRight: 20 }}
                      color="info"
                      size="medium"
                      variant="gradient"
                      // disabled={this.state.btnSaveDisabled}
                      onClick={() => this.modalSettingRef.current.setShow(true)}
                    >
                      <Icon color="inherit">settings</Icon>
                      pengaturan
                    </MKButton>
                    <MKButton
                      // iconOnly
                      color="info"
                      size="medium"
                      variant="gradient"
                      // disabled={this.state.btnSaveDisabled}
                      onClick={() => {
                        this.setState({
                          startNum: 1,
                          endNum: this.state.collections.length,
                        });
                        this.modalPrintRef.current.setShow(true);
                      }}
                    >
                      <Icon color="inherit">print</Icon>
                      cetak
                    </MKButton>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        {/* <MKBox pt={{ xs: 0, lg: 3 }} pb={3}>
          <SimpleFooter company={{ href: "#", name: "yujuism" }} links={[]} />
        </MKBox> */}
      </>
    );
  }
  handlePrintList = async () => {
    if (this.state.collections.length > 0 && this.state.printListPayload) {
      // console.log({ selectedPrinter: this.state.selectedPrinter, printListPayload: this.state.printListPayload });

      const selectedPrinter = this.state.selectedPrinter;
      switch (selectedPrinter) {
        case "pdf":
          // this.toPdf()
          const { savePdf } = require("libraries/pdf");
          savePdf(this.state.printListPayload, "PdfPrintList");
          break;
        case "deskjet":
          const { getUrl } = require("libraries/pdf");
          print.list({
            url: await getUrl(this.state.printListPayload, "PdfPrintList"),
            ...this.state.printListPayload,
            printer: selectedPrinter,
          });
          break;
        default:
          const { data, header, footer } = this.state.printListPayload;
          header.pop();
          footer.pop();
          print.list({
            ...this.state.printListPayload,
            data: data.map((item) => delete item.remark && item),
            header,
            footer,
            printer: selectedPrinter,
          });
      }
      this.setState({ printListPayload: "", printListModal: false });
      this.modalPrintListRef.current.setShow(false);
    }
  };
  printListModal = (e) => {
    if (this.state.collections.length > 0) {
      // console.log("test");
      const data = this.state.collections
        // .filter(item => this.state.check[item.id]) // filter is checked
        .map((item, index) => {
          console.log(item);
          // const sale =
          //   item.BatchSalesDts && item.BatchSalesDts.length > 0 && item.BatchSalesDts[0].TrSale;
          const saleDts =
            item.BatchSalesDts && item.BatchSalesDts.length > 0 && item.BatchSalesDts[0];
          const sale = saleDts && saleDts.TrSale;
          const receiptBody =
            sale && [1, 2, 4].includes(sale.statusId) && JSON.parse(sale.receiptBody);
          const name = item.customerName
            ? item.customerName
            : receiptBody && [1, 4].includes(sale.statusId)
            ? Array.isArray(receiptBody)
              ? receiptBody[1][1]
              : receiptBody.name.value
            : "-";
          let remark = "-";
          if (receiptBody) {
            if ([2].includes(sale.statusId))
              remark = Array.isArray(receiptBody) ? receiptBody[0][0] : receiptBody.message;
          }

          const qty = sale && sale.qty ? sale.qty : 0;
          const adminNominal = saleDts && saleDts.adminNominal ? saleDts.adminNominal : 0;
          const totalAdmin = adminNominal * qty;

          return {
            no: fnumber(index + 1),
            customerCode: item.customerCode,
            name,
            check: this.state.check[item.id] ? "YA" : "TIDAK",
            amount: sale && sale.statusId !== 2 ? fnumber(sale.amount) : "0",
            qty: sale && sale.statusId !== 2 && sale.qty ? fnumber(sale.qty) : "0",
            admin: sale && sale.statusId !== 2 ? fnumber(totalAdmin) : "0",
            bayar: sale && sale.statusId !== 2 ? fnumber(parseInt(sale.amount) + totalAdmin) : "0",
            remark,
          };
        });
      const header = [
        "#",
        "IDPEL",
        "NAMA",
        "PROSES",
        "TAGIHAN",
        "QTY",
        "ADMIN",
        "BAYAR",
        "KETERANGAN",
      ];
      const footer = [
        "-",
        "-",
        "-",
        "TOTAL",
        fnumber(this.state.totalTagihan),
        fnumber(this.state.totalQty),
        fnumber(this.state.totalAdmin),
        fnumber(this.state.totalBayar),
        "-",
      ];
      const align = {
        index: [3, 4, 5, 6, 7],
        align: ["center", "right", "right", "right", "right"],
      };
      // const totalTagihan = fnumber(this.state.totalTagihan)
      // const totalQty = fnumber(this.state.totalQty)
      // const totalAdmin = fnumber(this.state.totalAdmin)
      // const totalBayar = fnumber(this.state.totalBayar)
      const title = "DAFTAR TAGIHAN LISTRIK " + this.state.collectiveName;
      const filename = `${title.replace(/\s/g, "_")}_${moment().format("YYMMDDHHmm")}`;
      const printListPayload = { title, data, header, filename, footer, align };
      // console.log(printListPayload)
      // this.printListRef.current.setData(printListPayload)
      this.setState({ printListModal: true, printListPayload });
      this.modalPrintListRef.current.setShow(true);
    }
  };
}

export default Transaction;
