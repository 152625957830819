import React from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

export default class ModalNotif extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      title: props.title ? props.title : "",
      message: props.message ? props.message : "",
      bgColor: "",
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({ show: !this.state.show });
  }

  setShow(data) {
    this.setState({
      show: true,
      bgColor: data.color,
      title: data.title,
      message: data.message,
    });
  }

  render() {
    return (
      <Modal
        open={this.state.show}
        onClose={this.toggleModal}
        sx={{ display: "grid", placeItems: "center" }}
      >
        <Slide direction="down" in={this.state.show} timeout={100}>
          <MKBox
            position="relative"
            width="300px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            variant="gradient"
            bgColor={this.state.bgColor}
            shadow="sm"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" py={3} px={2}>
              <MKTypography variant="h6" color="white">
                {this.state.title}
              </MKTypography>
              <CloseIcon
                color="white"
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={this.toggleModal}
              />
            </MKBox>
            <Divider light sx={{ my: 0 }} />
            <MKBox p={6} textAlign="center" color="white">
              <MKTypography variant="h4" color="white" mt={3} mb={1}>
                {this.state.message}
              </MKTypography>
            </MKBox>
            <Divider light sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" py={2} px={1.5}>
              <MKButton variant="text" color="white" onClick={this.toggleModal}>
                close
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>
    );
  }
}
