import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKDatePicker from "components/MKDatePicker";

import moment from "moment";
import _ from "lodash";

import { useTranslation } from "react-i18next";

function GSearchActions({ onSubmit, handleChange }) {
  const [hiddenYear, setHiddenYear] = useState(true);
  const [hiddenMonth, setHiddenMonth] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [viewBy, setViewBy] = useState(3);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState(moment());
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [items, setItems] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    let today = new Date();
    setMonth(today.getMonth() + 1);
    loadYear();
    loadMonth();
  }, []);

  const loadYear = () => {
    const startYear = 2020;
    const endYear = moment().year();
    let _years = [];
    for (let y = startYear; y <= endYear; y++) {
      _years.push(y);
    }
    setYears(_years);
    setYear(moment().year());
    setSubmitDisabled(false);
  };

  const loadMonth = () => {
    const _months = moment.months().map((item, idx) => ({ id: idx + 1, name: t(item) }));
    setMonths(_months);
  };

  const onChange = (params) => {
    let startDate = moment();
    let endDate = moment();
    const _year = params && params.year ? params.year : year;
    const _viewBy = params && params.viewBy ? params.viewBy : viewBy;
    const _month = params && params.month ? params.month : month;
    const _date = params && params.date ? params.date : date;
    const _fromDate = params && params.fromDate ? params.fromDate : fromDate;
    const _toDate = params && params.toDate ? params.toDate : toDate;

    switch (_viewBy) {
      case 3:
        startDate = _date.clone().startOf("day");
        endDate = _date.clone().endOf("day");
        break;
      case 1:
        startDate = moment({ y: _year, M: _month }).startOf("month");
        endDate = moment({ y: _year, M: _month }).endOf("month");
        break;
      case 4:
        startDate = _fromDate.startOf("day");
        endDate = _toDate.endOf("day");
        break;
      case 5:
        startDate = _fromDate;
        endDate = _toDate;
        break;
      default:
        startDate = moment({ y: _year }).startOf("year");
        endDate = moment({ y: _year }).endOf("year");
        break;
    }

    handleChange && handleChange({ startDate, endDate });
  };

  const handleDateChange = (_date, target) => {
    if (target === "date") {
      setDate(_date);
      onChange({ date: _date });
    } else if (target === "fromDate") {
      setFromDate(_date);
      onChange({ fromDate: _date });
    } else if (target === "toDate") {
      setToDate(_date);
      onChange({ toDate: _date });
    }
  };

  const handleChangeDropdown = (name, newValue) => {
    if (name === "viewBy") {
      setViewBy(newValue);
      onChange({ viewBy: newValue });
      switch (newValue) {
        case 1:
          setHiddenMonth(false);
          setHiddenYear(false);
          break;
        case 2:
          setHiddenMonth(true);
          setHiddenYear(false);
          break;
        case 3:
        case 4:
          setHiddenMonth(true);
          setHiddenYear(true);
          break;
        default:
          setHiddenMonth(true);
          setHiddenYear(true);
          break;
      }
    } else if (name === "month") {
      setMonth(newValue);
      onChange({ month: newValue });
      if (newValue === "") setSubmitDisabled(true);
      if (year === "") setSubmitDisabled(false);
    } else if (name === "year") {
      setYear(newValue);
      onChange({ year: newValue });
      if ((newValue === "" || month === "") && viewBy === 1) setSubmitDisabled(true);
      else setSubmitDisabled(false);
    }
  };

  const handleSubmit = () => {
    setSubmitDisabled(true)
    let startDate = moment();
    let endDate = moment();
    const _year = year;
    let _month = month;

    if (viewBy === 3) {
      startDate = date.clone().startOf("day");
      endDate = date.clone().endOf("day");
    } else if (viewBy === 1) {
      startDate = moment({ y: year, M: _month - 1 }).startOf("month");
      endDate = moment({ y: year, M: _month - 1 }).endOf("month");
    } else if (viewBy === 4) {
      startDate = fromDate.startOf("day");
      endDate = toDate.endOf("day");
    } else if (viewBy === 5) {
      startDate = fromDate;
      endDate = toDate;
    } else {
      startDate = moment({ y: year }).startOf("year");
      endDate = moment({ y: year }).endOf("year");
    }

    let params = {
      startDate,
      endDate,
    };

    onSubmit(params);
    setSubmitDisabled(false)
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={6} sm={3} md={3} lg={3} xl={3} px={1}>
        <Autocomplete
          value={viewBy}
          options={Items.map((key) => key.id)}
          getOptionLabel={(option) => {
            if (!option) return;
            const opt = option.name ? { ...option } : { id: option };
            const value = _.find(Items, { ...opt });
            return value?.name;
          }}
          onChange={(e, newValue) => handleChangeDropdown("viewBy", newValue)}
          renderInput={(params) => (
            <MKInput {...params} label="Lihat Berdasarkan" variant="standard" fullWidth />
          )}
          fullWidth
        />
      </Grid>
      {[4].includes(viewBy) ? (
        <Grid item xs={6} sm={3} md={3} lg={3} xl={3} px={1}>
          <MKDatePicker
            defaultValue={moment().format("YYYY-MM-DD")}
            options={{
              minDate: "2020-01-01",
              enableTime: false,
            }}
            onChange={(dates, str) => handleDateChange(moment(str), "fromDate")}
            input={{
              label: "Dari",
              variant: "standard",
              placeholder: "From Date",
              fullWidth: true,
            }}
          />
        </Grid>
      ) : (
        <></>
      )}
      {[5].includes(viewBy) ? (
        <Grid item xs={6} sm={3} md={3} lg={3} xl={3} px={1}>
          <MKDatePicker
            defaultValue={moment().format("YYYY-MM-DD HH:mm")}
            options={{
              minDate: "2020-01-01",
              enableTime: true,
            }}
            onChange={(dates, str) => handleDateChange(moment(str), "fromDate")}
            input={{
              label: "Dari",
              variant: "standard",
              placeholder: "From Date",
              fullWidth: true,
            }}
          />
        </Grid>
      ) : (
        <></>
      )}
      {!hiddenYear ? (
        <Grid item xs={6} sm={3} md={3} lg={3} xl={3} px={1}>
          <Autocomplete
            value={year}
            options={years}
            getOptionLabel={(option) => {
              if (!option) return;
              return option.toString();
            }}
            onChange={(e, newValue) => {
              handleChangeDropdown("year", newValue);
            }}
            renderInput={(params) => (
              <MKInput {...params} label="Tahun" variant="standard" fullWidth />
            )}
            fullWidth
          />
        </Grid>
      ) : (
        <></>
      )}
      {viewBy === 3 ? (
        <Grid item xs={6} sm={3} md={3} lg={3} xl={3} px={1}>
          <MKDatePicker
            defaultValue={moment().format("YYYY-MM-DD")}
            options={{
              minDate: "2020-01-01",
              enableTime: false,
            }}
            onChange={(dates, str) => handleDateChange(moment(str), "date")}
            input={{
              label: "Tanggal",
              variant: "standard",
              placeholder: "Tanggal",
            }}
            fullWidth
          />
        </Grid>
      ) : (
        <></>
      )}
      {[4].includes(viewBy) ? (
        <Grid item xs={6} sm={3} md={3} lg={3} xl={3} px={1}>
          <MKDatePicker
            defaultValue={moment().format("YYYY-MM-DD")}
            options={{
              minDate: "2020-01-01",
              enableTime: false,
            }}
            onChange={(dates, str) => handleDateChange(moment(str), "toDate")}
            input={{
              label: "Sampai",
              variant: "standard",
              placeholder: "To Date",
              fullWidth: true,
            }}
          />
        </Grid>
      ) : (
        <></>
      )}
      {[5].includes(viewBy) ? (
        <Grid item xs={6} sm={3} md={3} lg={3} xl={3} px={1}>
          <MKDatePicker
            defaultValue={moment().format("YYYY-MM-DD HH:mm")}
            options={{
              minDate: "2020-01-01",
              enableTime: true,
            }}
            onChange={(dates, str) => handleDateChange(moment(str), "toDate")}
            input={{
              label: "Sampai",
              variant: "standard",
              placeholder: "To Date",
              fullWidth: true,
            }}
          />
        </Grid>
      ) : (
        <></>
      )}
      {!hiddenMonth ? (
        <Grid item xs={6} sm={3} md={3} lg={3} xl={3} px={1}>
          <Autocomplete
            value={month}
            options={months}
            getOptionLabel={(option) => {
              if (!option) return;
              if (option.name) {
                return option?.name;
              } else {
                const value = _.find(months, { id: option });
                return value?.name;
              }
            }}
            onChange={(e, newValue) => {
              newValue && newValue.id && handleChangeDropdown("month", newValue.id);
            }}
            renderInput={(params) => (
              <MKInput {...params} label="Bulan" variant="standard" fullWidth />
            )}
            fullWidth
          />
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} px={1}>
        <MKButton
          color="info"
          onClick={handleSubmit}
          disabled={submitDisabled}
          variant="gradient"
          fullWidth
        >
          Tampilkan
        </MKButton>
      </Grid>
    </Grid>
  );
}

GSearchActions.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
};

export default GSearchActions;

const Items = [
  { id: 1, name: "Bulan" },
  { id: 2, name: "Tahun" },
  { id: 3, name: "Tanggal" },
  { id: 4, name: "Dari - S.d (Tanggal)" },
  { id: 5, name: "Dari - S.d (Tanggal & Waktu)" },
];
