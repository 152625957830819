const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function register(config) {
  // console.log(process.env.REACT_APP_ENV)
  if (process.env.REACT_APP_ENV === "production" && "serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    // console.log(process.env.REACT_APP_ENV);
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }
    // console.log({ publicUrl });
    window.addEventListener("load", () => {
      const swUrl = `https://${publicUrl.hostname}/sw.js`;

      if (isLocalhost) {
        checkValidServiceWorker(`http://${publicUrl.hostname}:${publicUrl.port}/sw.js`, config);
        navigator.serviceWorker.ready.then(() => {
          console.log(
            "This web app is being served cache-first by a service " +
              "worker. To learn more, visit https://bit.ly/CRA-PWA"
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
        navigator.serviceWorker.ready.then(() => {});
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (!event.data) {
      return;
    }

    switch (event.data) {
      case "reloadWindow":
        window.location.reload();
        break;
      default:
        // NOOP
        break;
    }
  });

  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      if (!navigator.serviceWorker.controller) {
        return;
      }
      registration.addEventListener("updatefound", () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.addEventListener("statechange", () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              if (config && config.onUpdate) {
                registration.waiting.postMessage("skipWaiting");
                config.onUpdate(registration.waiting);
              }
            } else {
              console.log("Content is cached for offline use.");

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        });
      });
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        console.log("Service worker found. Proceed as normal.");
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log("No internet connection found. App is running in offline mode.");
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
