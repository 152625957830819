import loader from "./loader";
import moment from "moment";
import secureStorage from "libraries/secureStorage";
import getinfo from "helper/getinfo";
// import cutStr from "helper/receipt/cutstr";

import _ from "lodash";

const receipt = (payload) => {
  const printer = secureStorage.getItem("printer");
  const user = secureStorage.getItem("account");
  const info = secureStorage.getItem("domainInfo");
  const title = `${payload.title.padStart(105, " ")}\r\n`;
  const body = payload.body;
  const details = body && body.details ? body.details : [];

  // console.log({ payload })
  const date = body.date && body.date.value ? body.date.value : payload.createdAt;

  // CUT STR
  // const fullName = cutStr(body.name.caption, body.name.value, 18);

  const leftPage = [
    ["TGL", moment(date).format("DD/MM/YY HH:mm")],
    [body.subscriberNo.caption, body.subscriberNo.value],
    [body.name.caption, body.name.value],
    ["BLTH", body.period.value],
    ["RP TAG", body.amount.value],
    ["ADMIN", body.admin.value],
    ["BAYAR", body.total.value],
  ];

  for (let i = leftPage.length; i < 11; i++) leftPage.push([""]);
  const middlePage = [
    ["TANGGAL", moment(date).format("DD/MM/YY HH:mm")],
    [body.subscriberNo.caption, body.subscriberNo.value],
    [body.name.caption, body.name.value],
    ["TRF/DAYA", body.segmentPower.value],
    [body.referenceNo.caption, body.referenceNo.value.toString().substring(0, 15)],
    // ["JMLTAG", body.qty.value],
    [""],

    // ["PLN menyatakan struk ini sbg bukti pemebayaran yg sah"],
    ...getinfo(body.info.value),
  ];

  printer === "dotmatrix"
    ? middlePage.push([`${String.fromCharCode(27)}4Loket : ${user.name}`])
    : middlePage.push([`Loket : ${user.name}`]);
  // printer === "dotmatrix"
  //   ? middlePage.push([`Powered by ${payload.appName}${String.fromCharCode(27)}5`])
  //   : middlePage.push([`Powered by ${payload.appName}`]);

  for (let i = middlePage.length; i < 11; i++) middlePage.push([""]);

  const standMeter = body.standMeter.value;
  const standAwal = standMeter.split("-")[0];
  const standAkhir = standMeter.split("-")[1];

  // const penalty = _.sumBy(details, ({ penalty }) => parseInt(penalty));
  // const sPenalty = penalty > 0 ? [["DENDA", penalty]] : [];

  const rightPage = [
    ["BLTH", body.period.value],
    [
      body.standMeter.caption,
      `${parseInt(standAwal ? standAwal : 0)}-${parseInt(standAkhir ? standAkhir : 0)}`,
    ],
    // ...sPenalty,
    [body.amount.caption, body.amount.value],
    [body.admin.caption, body.admin.value],
    [body.total.caption, body.total.value],
  ];

  for (let i = rightPage.length; i < 11; i++) rightPage.push([""]);
  // console.log({title,leftPage,middlePage,rightPage,payload})

  // return loader(title, leftPage, middlePage, rightPage, payload);
  if (printer === "dotmatrix") return loader(title, leftPage, middlePage, rightPage, payload);
  else return { title, leftPage, middlePage, rightPage, payload };
};
export default receipt;
