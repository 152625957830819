import moment from "moment";
import loader from "./loader";
import secureStorage from "libraries/secureStorage";
import getinfo from "helper/getinfo";
import cutStr from "helper/receipt/cutstr";

const receipt = (payload) => {
  const printer = secureStorage.getItem("printer");
  const user = secureStorage.getItem("account");
  const info = secureStorage.getItem("domainInfo");
  const title = `${payload.title.padStart(105, " ")}\r\n`;
  const body = payload.body;

  let address = body.address.value.split(", ");
  address = address.slice(0, 2).join(", ");
  //   const penalty = body.penalty.value ? parseInt(body.penalty.value) : 0;
  const penalty =
    body.penalty && body.penalty.value
      ? [[body.penalty.caption, parseInt(body.penalty.value)]]
      : [];
  const date = body.date && body.date.value ? body.date.value : payload.createdAt;

  const period = body.period && body.period.value ? [["PERIODE", body.period.value]] : [];
  const leftPage = [
    ["TGL", moment(date).format("DD/MM/YY HH:mm")],
    ["NO PJK", body.customerNo.value],
    ["NAMA", body.name.value],
    ["ALMT", body.address.value],
    ["RP TAG", body.amount.value],
    ["ADMIN", body.admin.value],
    ["BAYAR", body.total.value],
  ];

  for (let i = leftPage.length; i < 11; i++) leftPage.push([""]);

  const middlePage = [
    ["TANGGAL", moment(date).format("DD/MM/YY HH:mm")],
    ["NO PJK", body.customerNo.value],
    ["NAMA", body.name.value],
    ["ALMT", body.address.value],
    [body.district.caption, body.district.value],
    ...period,
    ...getinfo(body.info.value),
  ];

  printer === "dotmatrix"
    ? middlePage.push([`${String.fromCharCode(27)}4Loket : ${user.name}`])
    : middlePage.push([`Loket : ${user.name}`]);
  printer === "dotmatrix"
    ? middlePage.push([`Powered by ${payload.appName}${String.fromCharCode(27)}5`])
    : middlePage.push([`Powered by ${payload.appName}`]);

  for (let i = middlePage.length; i < 11; i++) middlePage.push([""]);

  const rightPage = [
    ["TANGGAL", moment(date).format("DD/MM/YY HH:mm")],
    [body.referenceNo.caption, body.referenceNo.value.toString().substring(0, 20)],
    ...penalty,
    [body.amount.caption, body.amount.value],
    [body.admin.caption, body.admin.value],
    [body.total.caption, body.total.value],
  ];

  for (let i = rightPage.length; i < 11; i++) rightPage.push([""]);

  // return loader(title, leftPage, middlePage, rightPage, payload);
  if (printer === "dotmatrix") return loader(title, leftPage, middlePage, rightPage, payload);
  else return { title, leftPage, middlePage, rightPage, payload };
};

export default receipt;
