const ThreeColumns = {
  container: {
    fontFamily: "SpaceMono",
    // textAlign:'center',
    alignItems: "center",
    height: "100%",
    // justifyContent: 'centre',
  },
  title: {
    width: "100%",
    margin: 10,
    // marginTop:10,
    // textAlign:'center',
    fontSize: 10,
    fontWeight: 500,
    textAlign: "center",
    // padding: 20,
  },
  body: {
    height: "25%",
    display: "table",
    padding: 5,
    width: "auto",
    // marginLeft: 25,
    paddingLeft: 35,
    // borderStyle: BORDER_STYLE,
    // borderColor: BORDER_COLOR,
    // borderWidth: 1,
    // borderRadius:10,
    borderBottom: "1px dashed #000",
    fontSize: 9,
  },
  row: {
    width: "100%",
    flexDirection: "row",
  },
  receipt: {
    width: "100%",
  },
  single: {
    width: 100 + "%",
  },
  double: {
    width: 100 + "%",
    margin: "auto",
    flexDirection: "row",
  },
  token: {
    width: 100 + "%",
    margin: "auto",
    // fontWeight: 500,
    fontWeight: "bold",
    fontSize: 11,
  },

  left: {
    width: 30 + "%",
    margin: "auto",
    flexDirection: "row",
  },
  middle: {
    width: 34 + "%",
    margin: "auto",
    flexDirection: "row",
  },
  right: {
    width: 40 + "%",
    margin: "auto",
    flexDirection: "row",
  },
  captionLeft: {
    width: "20%",
  },
  valueLeft: {
    width: "80%",
  },
  caption: {
    width: "25%",
  },
  value: {
    width: "75%",
  },
  footerLeft: {
    width: "26%",
  },
  footerRight: {
    width: "74%",
  },
  number: {
    textAlign: "right",
    width: 50 + "%",
    marginRight: 50,
  },
  logo: {
    marginTop: 10,
    width: 97,
    height: 97,
  },
};
export default ThreeColumns;
