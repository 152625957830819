import React from "react";
import { Navigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { Autocomplete, TextField } from "@mui/material";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

import Loaded from "contents/components/Loaded";
import GSearchActions from "contents/components/GSearchActions";
import CustomTable from "contents/components/CustomTable";
import Pagination from "contents/components/Pagination";
import SnackbarContent from "contents/components/Snackbar";

import secureStorage from "libraries/secureStorage";
import { fnumber } from "helper/Helper";

import _ from "lodash";
import axios from "axios";
import Config from "config";
import moment from "moment";

import { withTranslation } from "react-i18next";
import { makeStyles, withStyles } from "@mui/styles";
import { compose } from "redux";

const styles = {
  root: {
    "& .MuiInputBase-formControl": {
      fontSize: "15px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  },
  styleRek: {
    "& .MuiInputBase-formControl": {
      fontSize: "23px",
      fontWeight: "bold",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
};

class BillPayment extends React.Component {
  constructor(props) {
    super();
    this.state = {
      amount: "",
      status: "",

      bill: "",
      unique: "345",
      uniqueAmount: "2.000.345",

      accountNumber: "",

      bank: [],
      selectedBank: "",

      toolTipOpen: false,

      alert: false,
      error: [],
      success: [],

      showModal: false,
      modalTitle: "",
      modalMessage: "",

      buttonDisabled: true,
      splitButtonDisabled: true,

      splitAmount: "",
      remainingAmount: "",

      isLoading: false,
    };
  }

  componentDidMount() {
    this.initBill();
    this.initBank();
  }

  initBank() {
    axios
      .post(`${Config.EndPoint}/bill/bank`)
      .then((res) => {
        this.setState({ bank: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  initBill() {
    axios
      .post(`${Config.EndPoint}/bill/status`)
      .then((res) => {
        const bill = res.data;
        if (bill.Splitted) {
          bill.Splitted = _.sortBy(bill.Splitted, "id");
        }
        // console.log(bill);
        this.setState({
          bill,
          status: bill.statusId,
          uniqueAmount: bill.uniqueAmount,
          unique: bill.unique,
          // accountNumber : `${ticket.Bank.name} ${ticket.Bank.accountNumber} a.n. ${ticket.Bank.accountName}`,
        });
        this.setRemainingAmount(bill);
        // if(res.data.statusId!==2)
        // 	this.setState({buttonDisabled:false})
      })
      .catch((err) => {
        //console.log(err)
      });
  }

  setRemainingAmount(bill) {
    if (bill) {
      let remainingAmount = bill.amount;
      bill.Splitted.map((split) => {
        remainingAmount -= split.amount;
        return split;
      });
      if (remainingAmount === 0) this.setState({ splitButtonDisabled: true });
      this.setState({ remainingAmount });
    }
  }

  handleInput = (e) => {
    if (this.state.buttonDisabled) this.setState({ buttonDisabled: false });

    const valStr = e.target.value.replace(/\./g, "");
    let value = isNaN(valStr) || valStr === "" ? 0 : parseInt(valStr);
    if (value < 0) value = 0;
    this.setState({ amount: fnumber(value) });
    if (!value || value < 1000 || !this.state.selectedBank) this.setState({ buttonDisabled: true });
  };

  handleCopy = (e) => {
    this.input.select();
    document.execCommand("copy");
    this.setState({ toolTipOpen: true });
  };

  getDueDate = (bill, format) => {
    const user = secureStorage.getItem("account");
    let dueDate;
    if (bill) {
      if (moment(bill.createdAt).weekday() === 6)
        dueDate = moment().startOf("day").add(2, "days").add(11, "hours").add(30, "minutes");
      else if (moment(bill.createdAt).weekday() === 0)
        dueDate = moment().startOf("day").add(1, "days").add(11, "hours").add(30, "minutes");
      else if (bill.cycle === 2) dueDate = moment(bill.createdAt).add(3, "hours");
      else dueDate = moment(bill.createdAt).startOf("day").add(11, "hours").add(30, "minutes");
      if (user.userTypeId === 4) dueDate = dueDate.subtract(30, "minutes");

      return dueDate.format(format);
    } else return "No Active Bill Available";
  };

  submit = (bill) => {
    const bank = this.state["selectedBank" + bill.id];
    const payload = {
      bank,
      bill,
    };
    axios
      .post(`${Config.EndPoint}/bill/payment`, payload)
      .then((res) => {
        const bill = res.data;
        this.setState({
          buttonDisabled: true,
          status: bill.statusId,
          bill,
        });
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  getForm(bill, index) {
    const { classes } = this.props;
    const borderStyle = bill.parentId
      ? {
        borderStyle: "solid",
        padding: "10px 10px 10px 10px",
        borderRadius: 10,
        marginTop: 20,
        borderColor: "#AEAEAE",
        borderWidth: 1,
      }
      : {};
    return (
      <div key={"nominalTransfer" + bill.id} style={{ ...borderStyle }}>
        {!bill.Splitted && (
          <MKBadge badgeContent={`Transfer ${index + 1}`} color="info" container size="lg" />
        )}
        {!bill.Bank && (
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                options={this.state.bank}
                value={
                  this.state["selectedBank" + bill.id] ? this.state["selectedBank" + bill.id] : ""
                }
                onChange={(e, bank) => {
                  let buttonDisabled = false;
                  this.setState({
                    ["buttonDisabled" + bill.id]: buttonDisabled,
                    ["selectedBank" + bill.id]: bank,
                    ["accountNumber" +
                      bill.id]: `${bank.name} ${bank.accountNumber} a.n. ${bank.accountName}`,
                  });
                }}
                getOptionLabel={(option) => option && option.name}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                fullWidth
                renderInput={(params) => <MKInput {...params} variant="standard" label="BANK" />}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={12}>
            <MKInput
              variant="standard"
              type="tel"
              label={"Kode Unik"}
              id={`uniqueCode${bill.id}`}
              className={classes.root}
              value={bill.unique}
              readOnly
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={12}>
            <MKInput
              variant="standard"
              type="tel"
              label="Nominal Transfer"
              id={"uniqueAmount" + bill.id}
              className={classes.root}
              value={fnumber(bill.uniqueAmount)}
              readOnly
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={12}>
            <MKInput
              variant="standard"
              type="tel"
              label={"Rekening"}
              id={"accountNumber" + bill.id}
              className={classes.root}
              value={
                this.state["accountNumber" + bill.id]
                  ? this.state["accountNumber" + bill.id]
                  : bill.Bank
                    ? `${bill.Bank.name} ${bill.Bank.accountNumber} a.n. ${bill.Bank.accountName}`
                    : ""
              }
              readOnly
              fullWidth
            />
          </Grid>
        </Grid>

        {bill.parentId && bill.statusId === 1 && (
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={12}>
              <MKBox display="flex" justifyContent="center">
                <MKButton
                  type="button"
                  variant="gradient"
                  onClick={(e) => {
                    this.submit(bill);
                  }}
                  disabled={
                    this.state["buttonDisabled" + bill.id] !== undefined
                      ? this.state["buttonDisabled" + bill.id]
                      : true
                  }
                  color="info"
                >
                  Request Pembayaran
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        )}

        {bill.parentId && bill.statusId !== 1 && (
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={12}>
              <MKInput
                variant="standard"
                type="tel"
                label="Status"
                id={"status" + bill.id}
                className={classes.root}
                value={bill.BillStatus.name}
                readOnly
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const user = secureStorage.getItem("account");
    if (!user) return <Navigate to="/" />;

    return (
      <>
        <MKBox wdith="100%" component="section" py={12} mx={2}>
          <Loaded open={this.state.isLoading} />

          <Card sx={{ padding: "25px", marginTop: "10px" }}>
            <Grid container justifyContent="center">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xl={2} lg={2} md={2} sm={12} xs={12} mb={{ xs: 1, md: 0 }}>
                    <MKBox
                      color="rgba(0,0,0,.6)"
                      bgColor="#e8e8e8"
                      borderRadius="lg"
                      shadow="lg"
                      textAlign="center"
                      justifyContent="center"
                      opacity={1}
                      p={1}
                    >
                      TAGIHAN
                    </MKBox>
                  </Grid>
                  <Grid item xl={10} lg={10} md={10} sm={12} xs={12}></Grid>
                </Grid>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justifyContent="center" justifyItems="center">
                  <Grid item xs={12} sm={12} md={8} mb={{ xs: 1, md: 0 }}>
                    <input
                      style={{ position: "absolute", left: "-5000px", top: "-5000px" }}
                      defaultValue={this.state.uniqueAmount}
                      ref={(input) => (this.input = input)}
                    />
                    {!this.state.bill ? (
                      <div>
                        <MKBadge
                          badgeContent={"Tagihan tidak tersedia"}
                          color="info"
                          container
                          size="lg"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {this.state.bill && (
                      <div>
                        <SnackbarContent
                          message={
                            [1, 7, 3].indexOf(this.state.status) !== -1 ? (
                              <span style={{ color: "#fff" }}>
                                Jatuh tempo tagihan ini sampai dengan jam{" "}
                                <b style={{ color: "#0000FF" }}>
                                  {this.getDueDate(this.state.bill, "HH:mm")}
                                </b>{" "}
                                pada hari dan tanggal yg sama. Jika pembayaran melewati batas waktu
                                yang telah ditentukan maka sistem akan memblokir akun secara
                                otomatis. Silahkan transfer sebesar{" "}
                                <b style={{ color: "#FF0000" }}>Nominal Transfer</b> untuk
                                mempercepat verifikasi pembayaran.
                              </span>
                            ) : this.state.status === 2 ? (
                              <span style={{ color: "#fff" }}>
                                Sistem sedang melakukan verifikasi, buka halaman Bill History untuk
                                melihat status pembayaran.
                              </span>
                            ) : this.state.status === 6 ? (
                              <span style={{ color: "#fff" }}>
                                Mohon untuk melunasi tagihan, akun telah terblokir secara otomatis
                                oleh sistem. Akun akan terbuka secara otomatis setelah tagihan
                                dilunasi. Silahkan hubungi bagian administrasi untuk keterangan
                                lebih lanjut.
                              </span>
                            ) : null
                          }
                          close
                          color="warning"
                          icon="info_outline"
                        />

                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <MKInput
                              variant="standard"
                              type="tel"
                              placeholder="Bill Amount"
                              label={"Jumlah Tagihan"}
                              id="dueDate"
                              className={classes.root}
                              value={
                                this.state.bill
                                  ? fnumber(this.state.bill.amount)
                                  : "No Active Bill Available"
                              }
                              readOnly
                              fullWidth
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={1}>
                          <Grid item xs={12} md={12}>
                            <MKInput
                              variant="standard"
                              type="tel"
                              label={"Tanggal Jatuh Tempo"}
                              id="dueDate"
                              className={classes.root}
                              value={this.getDueDate(this.state.bill, "LLLL")}
                              readOnly
                              fullWidth
                            />
                          </Grid>
                        </Grid>

                        {this.state.bill && this.state.bill.Splitted.length > 0 && (
                          <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} md={12}>
                              <MKInput
                                variant="standard"
                                type="tel"
                                label={"Sisa Tagihan"}
                                placeholder="Remaining Amount"
                                id="remainingAmount"
                                className={classes.root}
                                value={fnumber(this.state.remainingAmount)}
                                readOnly
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        )}
                        {this.state.bill && this.state.bill.Splitted.length === 0
                          ? this.getForm(this.state.bill)
                          : this.state.bill.Splitted.map((splitted, index) =>
                            this.getForm(splitted, index)
                          )}
                      </div>
                    )}
                    {this.state.bill &&
                      this.state.bill.Splitted.length === 0 &&
                      [1, 6].indexOf(this.state.status) !== -1 && (
                        <Grid container spacing={2} mt={1}>
                          <Grid item xs={12} md={12}>
                            <div style={{ textAlign: "center" }}>
                              <MKButton
                                type="button"
                                variant="gradient"
                                onClick={(e) => {
                                  this.submit(this.state.bill);
                                }}
                                disabled={
                                  this.state["buttonDisabled" + this.state.bill.id] !== undefined
                                    ? this.state["buttonDisabled" + this.state.bill.id]
                                    : true
                                }
                                color="info"
                              >
                                Request Pembayaran
                              </MKButton>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} mb={{ xs: 1, md: 0 }} mt={1}>
                    <div>
                      <MKBadge badgeContent={"Bagi Tagihan"} color="info" container size="lg" />
                    </div>

                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          variant="standard"
                          type="tel"
                          label={"Rupiah Terbagi"}
                          placeholder="Split Amount"
                          id={`splitAmount`}
                          className={classes.root}
                          value={fnumber(this.state.splitAmount)}
                          onChange={(e) => {
                            let val = e.target.value.replace(/\./g, "");
                            val = !val || isNaN(val) ? 0 : parseInt(val);
                            if (val > this.state.remainingAmount) {
                              val = 0;
                            }
                            let splitButtonDisabled = true;
                            if (val !== 0) {
                              splitButtonDisabled = false;
                            }
                            this.setState({ splitButtonDisabled, splitAmount: val });
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          variant="standard"
                          type="tel"
                          label={"Sisa Tagihan"}
                          placeholder="Split Amount"
                          id="remainingAmount"
                          className={classes.root}
                          value={fnumber(this.state.remainingAmount)}
                          readOnly
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={12} md={12}>
                        <div style={{ textAlign: "center" }}>
                          <MKButton
                            type="button"
                            variant="gradient"
                            onClick={() => {
                              if (this.state.splitAmount) {
                                const payload = {
                                  splitAmount: this.state.splitAmount,
                                  bill: this.state.bill,
                                };
                                axios
                                  .post(`${Config.EndPoint}/bill/split`, payload)
                                  .then((res) => {
                                    this.setState({
                                      bill: res.data,
                                      splitAmount: 0,
                                      splitButtonDisabled: true,
                                    });
                                    this.setRemainingAmount(res.data);
                                  })
                                  .catch((err) => {
                                    // console.log(err)
                                  });
                              }
                            }}
                            disabled={this.state.splitButtonDisabled}
                            color="info"
                          >
                            Bagi Tagihan
                          </MKButton>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </MKBox>
      </>
    );
  }
}

export default compose(withStyles(styles))(BillPayment);
