import React from 'react'
import { Navigate } from 'react-router-dom'

// @mui material components
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Modal from "@mui/material/Modal"
import Zoom from '@mui/material/Zoom'
import Autocomplete from '@mui/material/Autocomplete'
import Divider from "@mui/material/Divider"

// @mui icons
import CloseIcon from "@mui/icons-material/Close"

import MKBox from 'components/MKBox'
import MKInput from 'components/MKInput'
import MKButton from 'components/MKButton'
import MKAlert from 'components/MKAlert'
import MKTypography from 'components/MKTypography'

import Config from 'config'
import axios from 'axios'
import Resource from "libraries/resource"
import Provinces from "helper/Provinces"
import * as EmailValidator from 'email-validator'

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg"

import withRouter from 'contents/Register/withRouter'

class Register extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,

			invitationId: '',
			fullname: '',
			username: '',
			email: '',
			phone: '',
			address: '',
			province: null,
			password: '',
			repassword: '',
			alert: false,
			error: [],
			success: [],

			redirect: false,
			isInviationValid: null,

			showModal: false,
			modalTitle: '',
			modalMessage: '',

			usernameTimeout: '',
			emailTimeout: '',
			phoneTimeout: '',

			usernameErr: '',
			emailErr: '',
			phoneErr: '',

			appName: ''
		}

	}

	componentDidMount() {
		const invitationId = this.props.params?.invitationId
		if (invitationId) {
			this.setState({ invitationId })
			this.isInvitationValid()
		}

		window.scrollTo(0, 0);
		document.body.scrollTop = 0;

		window.location.hostname !== 'payfren.id' && Resource.getInfo().then(info => this.setState({ appName: info.appName })).catch(err => console.log(err))
	}

	toggleModal = () => this.setState({ showModal: !this.state.showModal })

	isInvitationValid = () => {
		if (!this.state.invitationId) {
			axios.post(`${Config.EndPoint}/user/invitation/isvalid`, {
				invitationId: this.props.params.invitationId,
			})
				.then(res => {
					this.setState({ isInviationValid: res.data })
				})
				.catch(err => {
					console.log('ERR', err)
				})
		}
	}

	checkAvailability = (params) => {
		const payload = {
			username: params.username && params.username,
			email: params.email && params.email,
			phone: params.phone && params.phone,
		}

		axios.post(Config.EndPoint + '/user/availability', payload)
			.then(res => {
				const data = res.data
				const target = Object.keys(data)[0]
				this.setState({
					[target + 'Err']: '',
					success: { ...this.state.success, [target]: true },
					error: { ...this.state.error, [target]: false },
				})
			}).catch(err => {
				console.log(err)
				if (err.response) {
					const data = err.response.data
					const target = Object.keys(data)[0]
					this.setState({
						[target + 'Err']: data[target],
						success: { ...this.state.success, [target]: false },
						error: { ...this.state.error, [target]: true },
					})
				}
			});
	}

	handleInput = (e) => {
		const id = e.target.id
		switch (id) {
			case "username":
				const username = e.target.value
				this.state.usernameTimeout && clearTimeout(this.state.usernameTimeout)
				this.state.usernameTimeout = setTimeout(() => {
					username && this.checkAvailability({ username })
				}, 1200)
				this.setState({
					username,
					success: { ...this.state.success, [e.target.id]: false },
					error: { ...this.state.error, [e.target.id]: false },
				})
				break
			case "fullname":
				this.setState({ fullname: e.target.value })
				break
			case "phone":
				const phone = e.target.value
				this.state.phoneTimeout && clearTimeout(this.state.phoneTimeout)
				this.state.phoneTimeout = setTimeout(() => {
					phone && this.checkAvailability({ phone })
				}, 1200)
				this.setState({
					phone,
					success: { ...this.state.success, [e.target.id]: false },
					error: { ...this.state.error, [e.target.id]: false }
				})
				break
			case "email":
				const email = e.target.value
				this.state.emailTimeout && clearTimeout(this.state.emailTimeout)
				this.state.emailTimeout = setTimeout(() => {
					email && this.checkAvailability({ email })
				}, 1500)
				this.setState({
					email,
					success: { ...this.state.success, [e.target.id]: false },
					error: { ...this.state.error, [e.target.id]: false }
				})
				break
			case "address":
				this.setState({ address: e.target.value })
				break
			case "password":
				this.setState({ password: e.target.value })
				break
			case "repassword":
				this.setState({ repassword: e.target.value })
				if (e.target.value === this.state.password) {
					this.setState({
						success: { ...this.state.success, [e.target.id]: true },
						error: { ...this.state.error, [e.target.id]: false }
					})
				}
				else if (e.target.value !== this.state.password) {
					this.setState({
						success: { ...this.state.success, [e.target.id]: false },
						error: { ...this.state.error, [e.target.id]: true }
					})
				}
				else {
					this.setState({
						success: { ...this.state.success, [e.target.id]: false },
						error: { ...this.state.error, [e.target.id]: true }
					})
				}
				break
			default:
		}
	}

	handleBlur = (e) => {
		const { id, value } = e.target
		if (value) {
			if (
				(id === 'username' && (value.indexOf(' ') !== -1 || this.state.usernameErr)) ||
				(id === 'email' && (!EmailValidator.validate(e.target.value) || this.state.emailErr)) ||
				(id === 'phone' && (isNaN(e.target.value) || this.state.phoneErr))
			) {
				this.setState({
					success: { ...this.state.success, [e.target.id]: false },
					error: { ...this.state.error, [e.target.id]: true }
				})
			}
			else {
				this.setState({
					success: { ...this.state.success, [e.target.id]: true },
					error: { ...this.state.error, [e.target.id]: false }
				})
			}
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleSignUp = () => {
		let error = this.state.error
		if (!EmailValidator.validate(this.state.email))
			error.email = true
		if (!this.state.repassword)
			error.repassword = true
		this.setState({ error: { ...error } })

		if (
			!error.username &&
			!error.email &&
			!error.phone &&
			!this.state.usernameErr &&
			!this.state.emailErr &&
			!this.state.phoneErr &&
			!error.fullname &&
			!error.address &&
			!error.password &&
			!error.repassword &&
			this.state.province
		) {
			this.setState({ alert: false })
			this.register()
		}
		else {
			this.setState({ alert: true })
		}
	}

	register = () => {
		const payload = {
			username: this.state.username,
			fullname: this.state.fullname,
			phone: this.state.phone,
			email: this.state.email,
			address: this.state.address,
			province: Provinces[this.state.province],
			password: this.state.password,
			invitationId: this.state.invitationId,
		}

		axios.post(`${Config.EndPoint}/user/register`, payload)
			.then(res => {
				this.setState({
					showModal: true,
					modalTitle: 'Sukses',
					modalMessage: res.data
				})
			})
			.catch(err => {
				if (err.response) {
					this.setState({
						showModal: true,
						modalTitle: 'Gagal',
						modalMessage: err.response.data
					})
				}
				else {
					this.setState({
						showModal: true,
						modalTitle: 'Gagal',
						modalMessage: 'Koneksi jaringan terputus'
					})
				}
			})
	}

	handleRedirect = (e) => {
		if (this.state.modalTitle === 'Sukses') {
			this.setState({
				redirect: true,
				showModal: false
			})
		}
		else {
			this.setState({ showModal: false })
		}
	}

	render() {
		if (this.state.isInviationValid === false || this.state.redirect) {
			return <Navigate to="/" />
		}

		const hostname = window.location.hostname
		return (
			<>
				<MKBox
					position="absolute"
					top={0}
					left={0}
					zIndex={1}
					width="100%"
					minHeight="100vh"
					sx={{
						backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
							bgImage &&
							`${linearGradient(
								rgba(gradients.dark.main, 0.6),
								rgba(gradients.dark.state, 0.6)
							)}, url(${bgImage})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
					}}
				/>
				<MKBox px={1} width="100%" height="100%" mx="auto" position="relative" zIndex={2}>
					<Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
						<Grid item xs={11} sm={9} md={8} lg={8} xl={8} xxl={7}>
							<Card sx={{
								marginTop: '10px',
								marginBotton: '10px'
							}}>
								<MKBox p={3}>
									<Grid container spacing={2} mt={1}>
										<Grid item xs={12} md={12}>
											<center>
												<h2>Register {hostname !== 'payfren.id' ? this.state.appName : 'Payfren'}</h2>
											</center>
										</Grid>
										<Grid item xs={12} md={12}>
											<MKInput
												variant="standard"
												label="Username"
												id="username"
												value={this.state.username}
												onChange={this.handleInput}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.username : false}
												error={this.state.error ? this.state.error.username : false}
												fullWidth
											/>
											<small style={{ color: 'red' }}>{this.state.usernameErr}</small>
										</Grid>
										<Grid item xs={12} md={12}>
											<MKInput
												id="email"
												variant="standard"
												label="Email"
												placeholder="contoh : john@payfren.id"
												value={this.state.email}
												onChange={this.handleInput}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.email : false}
												error={this.state.error ? this.state.error.email : false}
												fullWidth
											/>
											<small style={{ color: 'red' }}>{this.state.emailErr}</small>
										</Grid>
										<Grid item xs={12} md={12}>
											<MKInput
												variant="standard"
												label="No Handphone"
												id="phone"
												placeholder="contoh : 081123321456"
												value={this.state.phone}
												onChange={this.handleInput}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.phone : false}
												error={this.state.error ? this.state.error.phone : false}
												fullWidth
											/>
											<small style={{ color: 'red' }}>{this.state.phoneErr}</small>
										</Grid>
										<Grid item xs={12} md={12}>
											<MKInput
												variant="standard"
												label="Nama Lengkap"
												placeholder="contoh : Eren Jaeger"
												id="fullname"
												value={this.state.fullname}
												onChange={this.handleInput}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.fullname : false}
												error={this.state.error ? this.state.error.fullname : false}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<MKInput
												variant="standard"
												label="Alamat Lengkap"
												placeholder="contoh : Jl. Jenderal Sudirman No. 99 Jagakarsa"
												id="address"
												value={this.state.address}
												onChange={this.handleInput}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.address : false}
												error={this.state.error ? this.state.error.address : false}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<Autocomplete
												value={this.state.province}
												options={Provinces}
												getOptionLabel={(option) => {
													if (!option) return;
													if (option.name) {
														return option?.name;
													} else {
														const prov = Provinces[option];
														return prov?.name;
													}
												}}
												onChange={(e, newValue) => {
													if (newValue) {
														const index = Provinces.findIndex((key) => key.name === newValue.name);
														this.setState({
															province: index
														})
													}
												}}
												renderInput={(params) => (
													<MKInput {...params} label="PROVINSI" variant="standard" fullWidth />
												)}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<MKInput
												variant="standard"
												label="Password"
												placeholder="Password..."
												id="password"
												type="password"
												value={this.state.password}
												onChange={this.handleInput}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.password : false}
												error={this.state.error ? this.state.error.password : false}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											<MKInput
												variant="standard"
												label="Password"
												placeholder="Re-type Password..."
												id="repassword"
												type="password"
												value={this.state.repassword}
												onChange={this.handleInput}
												success={this.state.success ? this.state.success.repassword : false}
												error={this.state.error ? this.state.error.repassword : false}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={12}>
											{
												this.state.alert ?
													<MKAlert color="warning">
														Semua kolom harus diisi
													</MKAlert> : null
											}
											<MKBox display="flex" justifyContent="center">
												<MKButton
													type="button"
													variant="gradient"
													color="info"
													onClick={this.handleSignUp}
													disabled={
														!this.state.error.username &&
															!this.state.error.phone &&
															!this.state.error.email &&
															!this.state.usernameErr &&
															!this.state.emailErr &&
															!this.state.phoneErr &&
															!this.state.error.fullname &&
															!this.state.error.address &&
															!this.state.error.password &&
															!this.state.error.repassword &&
															this.state.province ?
															// this.state.checked.length >1 ?
															false : true
													}
												>
													SUBMIT
												</MKButton>
											</MKBox>
										</Grid>
									</Grid>
								</MKBox>
							</Card>

						</Grid>
					</Grid>

					<Modal
						keepMounted
						disableAutoFocus={false}
						closeAfterTransition={true}
						sx={{ display: "grid", placeItems: "center" }}
						onClose={() => this.setState({ showModal: false })}
						open={this.state.showModal}
					>
						<Zoom in={this.state.showModal}>
							<MKBox
								position="relative"
								width="500px"
								display="flex"
								flexDirection="column"
								borderRadius="xl"
								bgColor="white"
								shadow="xl"
								style={{ outline: "none" }}
							>
								<MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
									<MKTypography variant="h5">{this.state.modalTitle}</MKTypography>
									<CloseIcon
										fontSize="medium"
										sx={{ cursor: "pointer" }}
										onClick={() => this.setState({ showModal: false })}
									/>
								</MKBox>
								<Divider sx={{ my: 0 }} />
								<MKBox pl={5}>{this.state.modalMessage}</MKBox>
								<Divider sx={{ my: 0 }} />
								<MKBox display="flex" justifyContent="flex-end" p={1.5}>
									<MKButton variant="gradient" color="success" onClick={this.handleRedirect}>
										OK
									</MKButton>
								</MKBox>

							</MKBox>
						</Zoom>
					</Modal>
				</MKBox>
			</>
		)
	}
}

export default withRouter(Register)