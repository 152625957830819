import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @material-ui/icons
import GetAppIcon from "@mui/icons-material/GetApp";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import axios from "axios";
import Config from "config";
import Resource from "libraries/resource";

function Download() {
  const handleClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Container>
      <MKBox component="section" width="100%" py={12}>
        <MKBox
          p={3}
          coloredShadow="secondary"
          borderRadius="5px"
          mb={3}
          bgColor="white"
          shadow="xl"
        >
          <Grid container item xs={12} mt={2} md={12} mx="auto" justifyContent="center">
            <MKBox width="100%" display="flex" justifyContent="center">
              <MKTypography
                mb={1}
                variant="h5"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
              >
                Download For Windows
              </MKTypography>
            </MKBox>
          </Grid>
          <Divider sx={{ my: 0 }} />
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MKBox display="flex" justifyContent="center">
                <MKButton
                  type="button"
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    handleClick(
                      "https://www.mediafire.com/file/ox8s5tdd4mqhyu6/PrinterPlugin.rar/file"
                    );
                  }}
                  sx={{ marginRight: "5px" }}
                >
                  <GetAppIcon />
                  Download PluginPrinter
                </MKButton>
              </MKBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MKBox display="flex" justifyContent="center">
                <MKButton
                  type="button"
                  variant="gradient"
                  color="info"
                  onClick={() =>
                    handleClick(
                      "https://www.mediafire.com/file/6ies4aszqhe2jhc/jre-7u76-windows-32.exe/file"
                    )
                  }
                  sx={{ marginRight: "5px" }}
                >
                  <GetAppIcon />
                  Download Java 32-bit
                </MKButton>
              </MKBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MKBox display="flex" justifyContent="center">
                <MKButton
                  type="button"
                  variant="gradient"
                  color="info"
                  onClick={() =>
                    handleClick(
                      "https://www.mediafire.com/file/kxp2mxiwdofqv4g/jre-7u76-windows-64.exe/file"
                    )
                  }
                  sx={{ marginRight: "5px" }}
                >
                  <GetAppIcon />
                  Download Java 64-bit
                </MKButton>
              </MKBox>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12}>
              <MKBox display="flex" justifyContent="center">
                <MKButton
                  type="button"
                  variant="gradient"
                  color="info"
                  onClick={loadDownloadLink}
                  sx={{ marginRight: "5px" }}
                >
                  <GetAppIcon />
                  Download {info && info.appName}-win-ia32
                </MKButton>
              </MKBox>
            </Grid> */}
          </Grid>
        </MKBox>
      </MKBox>
    </Container>
  );
}

export default Download;
