import { useState, useEffect, useRef } from "react";
// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";

// @mui icons
import LockIcon from "@mui/icons-material/Lock";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import axios from "axios";
import Config from "config";
import secureStorage from "libraries/secureStorage";
import ReCAPTCHA from "react-google-recaptcha";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import ModalNotif from "contents/components/ModalNotif";

function ResetPassword() {
  const modalNotifRef = useRef();

  const navigate = useNavigate();
  const params = useParams();

  const [alert, setAlert] = useState(false);
  // const [color, setColor] = useState("")
  // const [message, setMessage] = useState("")

  const [confirmCode, setConfirmCode] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const [success, setSuccess] = useState({});
  const [error, setError] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (!isValid || redirect) navigate("/");

    params.confirmCode && isConfirmCodeValid(params.confirmCode);
  }, []);

  useEffect(() => {
    if (!isValid || redirect) {
      navigate("/");
    }
  }, [redirect, isValid]);

  const isConfirmCodeValid = (code) => {
    axios
      .post(Config.EndPoint + "/user/reset/isvalid", {
        confirmCode: code,
      })
      .then((res) => {
        setConfirmCode(code);
        setIsValid(true);
      })
      .catch((error) => {
        // console.log(error)
        setIsValid(false);
      });
  };

  const handleInput = (e) => {
    const id = e.target.id;
    setAlert(false);
    let isError = { ...error };
    let isSuccess = { ...success };
    switch (id) {
      case "password":
        setPassword(e.target.value);
        setSuccess({ ...success, password: true });
        setError({ ...error, password: false });
        break;
      case "repassword":
        setRePassword(e.target.value);
        if (e.target.value === password) {
          isSuccess = { ...isSuccess, repassword: true };
          isError = { ...isError, repassword: false };
        } else {
          isError = { ...isError, repassword: true };
          isSuccess = { ...isSuccess, repassword: false };
        }

        setSuccess(isSuccess);
        setError(isError);
        break;
    }
  };

  const handleBlur = (e) => {
    let isSuccess = { ...success };
    let isError = { ...success };
    if (e.target.value) {
      handleInput(e);
    } else {
      setSuccess({ ...success, [e.target.id]: false });
      setError({ ...error, [e.target.id]: true });
    }
  };

  const handleSubmit = () => {
    if (password && rePassword) {
      reset();
    } else {
      setAlert(true);
    }
  };

  const reset = () => {
    const payload = {
      password,
      confirmCode,
    };

    axios
      .post(Config.EndPoint + "/user/reset/change", payload)
      .then((res) => {
        modalNotifRef.current.setShow({
          title: "Sukses",
          message: res.data,
          color: "success",
        });

        setTimeout(() => setRedirect(true), 100);
      })
      .catch((err) => {
        setSuccess({ ...success, repassword: false, password: false });
        setError({ ...error, repassword: true, password: true });
        if (err.response) {
          modalNotifRef.current.setShow({
            title: "Gagal",
            message: err.response.data,
            color: "error",
          });
        } else {
          modalNotifRef.current.setShow({
            title: "Gagal",
            message: "Koneksi jaringan terputus",
            color: "error",
          });
        }
      });
  };

  return (
    <>
      <ModalNotif ref={modalNotifRef} />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Container>
          <MKBox component="section" width="100%" py={3}>
            <Card>
              <MKBox p={3}>
                <Grid container item xs={12} md={12}>
                  <MKBox width="100%" display="flex" alignItems="center" flexDirection="column">
                    <MKTypography
                      mb={1}
                      variant="h5"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      Reset Password
                    </MKTypography>
                  </MKBox>
                </Grid>

                <Grid container spacing={2} mt={1} justifyContent="center">
                  <Grid item xs={12} md={12} mt={1}>
                    <MKInput
                      variant="standard"
                      type="password"
                      label="Password"
                      id="password"
                      error={error ? error.password : false}
                      success={success ? success.password : false}
                      value={password}
                      onChange={handleInput}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={12} mt={1}>
                    <MKInput
                      variant="standard"
                      type="password"
                      label="Re-Password"
                      id="repassword"
                      error={error ? error.repassword : false}
                      success={success ? success.repassword : false}
                      value={rePassword}
                      onChange={handleInput}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </Grid>

                  {alert ? (
                    <Grid item xs={12} md={12}>
                      <MKAlert color="warning">
                        <strong>Semua kolom harus diisi</strong>
                      </MKAlert>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid item xs={12} md={12}>
                    <MKBox display="flex" justifyContent="center">
                      <MKButton
                        type="button"
                        variant="gradient"
                        color="info"
                        disabled={!error.password && !error.repassword ? false : true}
                        onClick={handleSubmit}
                      >
                        SUBMIT
                      </MKButton>
                    </MKBox>
                  </Grid>
                </Grid>
              </MKBox>
            </Card>
          </MKBox>
        </Container>
      </MKBox>
    </>
  );
}

export default ResetPassword;
