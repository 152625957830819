import React, { createRef } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from "@mui/icons-material/Search";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

import Loaded from "contents/components/Loaded";
import Notif from "contents/components/Notif";

import _ from "lodash";

import secureStorage from "libraries/secureStorage";
import { wsClient } from "helper/dotmatrix";
import print from "helper/printer";
import ComponentToPrint from "helper/ComponentToPrint";
import modalReceipt from "helper/modalReceipt";
import axios from "axios";

class Printer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      // State Notif
      isOpen: false,
      color: "success",
      message: "",

      printers: [],
      selectedEnabled: "thermal",
      selectedPrinter: "",
      dotmatrix: "offline",
      bluetooth: false,
      selectedPrintFormat: null,

      dropdown: null,
      dropPrinter: null,
    }

    this.printRef = createRef()
    this.iconStyles = {
      ml: 1,
      fontWeight: "bold",
      transition: "transform 200ms ease-in-out",
    };
  }

  // Styles

  componentDidMount() {
    this.loadPrinter();
    this.getPrinters();
  }

  openDropdown = ({ currentTarget }) => this.setState({ dropdown: currentTarget })

  openDropdownPrinter = ({ currentTarget }) => this.setState({ dropPrinter: currentTarget })
  handleSwitch = (e) => {
    secureStorage.setItem("printer", e.target.value);
    this.setState({ selectedEnabled: e.target.value });
  };

  getPrinters = () => {
    // wsClient.send("get-printer", {}, (data) => {
    //   setPrinters(data);
    //   const printer = secureStorage.getItem("selectedPrinter");
    //   setSelectedPrinter(_.find(data, ({ name }) => name === printer.name));
    // });
    axios
      .post("http://127.0.0.1:4321/get-printers")
      .then((res) => {
        this.setState({ printers: res.data.printers });
        const printer = secureStorage.getItem("selectedPrinter");
        if (printer) this.setState({ selectedPrinter: printer });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const checkPrinter = ()=>{
  //   return new Promise((resolve,reject)=>{
  //     axios.post('http://localhost:4321/check')
  //     .then(res=>{
  //       const dotMatrix = res.data
  //       secureStorage.setItem("dotmatrix",dotMatrix)
  //       setDotmatrix(dotMatrix);
  //       resolve(dotMatrix)
  //     })
  //     .catch(err=>{
  //       resolve('offline')
  //     })
  //   })
  // }

  loadPrinter = async () => {
    // if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1) {
    //   if (!wsClient.connected) wsClient.on("connected", () => getPrinters());
    //   else getPrinters();
    // }
    const bluetooth = navigator.bluetooth && (await navigator.bluetooth.getAvailability());
    const selectedPrintFormat = secureStorage.getItem("printFormat");
    let selectedEnabled = secureStorage.getItem("printer");
    const dotmatrix = secureStorage.getItem("dotmatrix");

    // console.log({ selectedEnabled, bluetooth, selectedEnabled, selectedPrintFormat })

    if (!selectedEnabled) {
      if (dotmatrix === "online") selectedEnabled = "dotmatrix";
      if (bluetooth) selectedEnabled = "thermal"
      else {
        selectedEnabled = "deskjet";
      }
      secureStorage.setItem("printer", selectedEnabled)
    }
    this.setState({
      bluetooth,
      dotmatrix,
      selectedEnabled,
      selectedPrintFormat: selectedPrintFormat ? selectedPrintFormat : { name: "1/1", value: "OnePage" },
    });
  };

  render() {
    return (
      <Container>
        <MKBox component="section" width="100%" py={12}>
          {/* LOADED */}
          <Loaded open={this.state.isLoading} />
          {/* ALERT TOAST */}
          <Notif open={this.state.isOpen} color={this.state.color} message={this.state.message} />

          <MKBox
            p={3}
            coloredShadow="secondary"
            borderRadius="5px"
            mb={3}
            bgColor="white"
            shadow="xl"
          >
            {/* TITLE HEADER */}
            <Grid container item xs={12} mt={2} md={12} mx="auto" justifyContent="center">
              <MKBox width="100%" display="flex" justifyContent="center">
                <MKTypography
                  mb={1}
                  variant="h5"
                  color="text"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  Pengaturan Printer
                </MKTypography>
              </MKBox>
            </Grid>

            <Grid container spacing={2} mt={1} justifyContent="flex-start">
              {/* Choose Printer Thermal */}
              {this.state.bluetooth && (
                <>
                  {/* Switch ON/OFF */}
                  <Grid item xs={12} sm={12} md={8}>
                    <Switch
                      onChange={this.handleSwitch}
                      value="thermal"
                      checked={this.state.selectedEnabled === "thermal" ? true : false}
                    />
                    <MKTypography
                      variant="button"
                      color="text"
                      fontWeight="medium"
                      ml={1}
                      sx={{ cursor: "pointer", userSelect: "none" }}
                    >
                      THERMAL
                    </MKTypography>
                  </Grid>
                  {/* BUTTON TEST PRINT THERMAL */}
                  <Grid item xs={12} md={8}>
                    <MKButton
                      type="button"
                      variant="gradient"
                      color="info"
                      disabled={this.state.selectedEnabled === "thermal" ? false : true}
                      onClick={(e) => {
                        print({ title: "TEST PRINT", body: TestPrintBody });
                      }}
                    >
                      TEST PRINT
                    </MKButton>
                  </Grid>
                </>
              )}

              {/* PRINTER DOTMATRIC OR POS */}
              {/* {navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1 && ( */}
              <Grid item xs={12} md={8}>
                <Grid container mt={1}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Switch
                      onChange={this.handleSwitch}
                      value="dotmatrix"
                      disabled={this.state.dotmatrix === "online" ? false : true}
                      checked={this.state.selectedEnabled === "dotmatrix" ? true : false}
                    />
                    <MKTypography
                      variant="button"
                      color="text"
                      fontWeight="medium"
                      ml={1}
                      sx={{ cursor: "pointer", userSelect: "none" }}
                    >
                      DOT MATRIX
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Switch
                      onChange={this.handleSwitch}
                      value="pos"
                      disabled={this.state.dotmatrix === "online" ? false : true}
                      checked={this.state.selectedEnabled === "pos" ? true : false}
                    />
                    <MKTypography
                      variant="button"
                      color="text"
                      fontWeight="medium"
                      ml={1}
                      sx={{ cursor: "pointer", userSelect: "none" }}
                    >
                      POS
                    </MKTypography>
                  </Grid>
                  {this.state.dotmatrix === "online" && (
                    <Grid item xs={12} sm={6} md={6} mt={1}>
                      <MKButton variant="outlined" color="dark" onClick={this.openDropdownPrinter}>
                        {this.state.selectedPrinter ? this.state.selectedPrinter : "Pilih Printer"}
                        <Icon
                          sx={{
                            transform: this.state.dropPrinter ? "rotate(180deg)" : "rotate(0)",
                            ...this.iconStyles,
                          }}
                        >
                          expand_more
                        </Icon>
                      </MKButton>
                      <Menu
                        anchorEl={this.state.dropPrinter}
                        getcontentanchorel={null}
                        open={Boolean(this.state.dropPrinter)}
                        onClose={() => this.setState({ dropPrinter: null })}
                      >
                        {this.state.printers.length > 0 ? (
                          this.state.printers.map((key, idx) => (
                            <MenuItem
                              onClick={() => {
                                console.log(this.state.printers);
                                secureStorage.setItem("selectedPrinter", key);
                                // wsClient.send("set-printer", { printer: key });
                                this.setState({
                                  selectedPrinter: key,
                                  dropPrinter: null,
                                });
                              }}
                              key={idx}
                            >
                              {key}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem
                            onClick={() => {
                              this.setState({ dropPrinter: null });
                            }}
                          >
                            Not Found
                          </MenuItem>
                        )}
                      </Menu>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {/* {BUTTON TEST PRINT DOTMATRIC OR POS} */}
              <Grid item xs={12} md={8}>
                <MKButton
                  type="button"
                  variant="gradient"
                  color="info"
                  disabled={
                    this.state.dotmatrix === "online"
                      ? this.state.selectedEnabled === "dotmatrix"
                        ? false
                        : true
                      : true
                  }
                  onClick={(e) => {
                    print({ title: "TEST PRINT", body: TestPrintBody, productId: 14 });
                  }}
                >
                  TEST PRINT
                </MKButton>
              </Grid>
              {/* END DOTMETRIC OR POS*/}

              {/* PRINTER DESKJET/INJET/LASRJET */}
              <Grid item xs={12} sm={12} md={8} ml={1}>
                <Grid container mt={1}>
                  <Grid item xs={12} sm={6} md={6} ml={{ xs: -1.5 }}>
                    <Switch
                      onChange={this.handleSwitch}
                      value="deskjet"
                      checked={this.state.selectedEnabled === "deskjet" ? true : false}
                    />
                    <MKTypography
                      variant="button"
                      color="text"
                      fontWeight="medium"
                      ml={1}
                      sx={{ cursor: "pointer", userSelect: "none" }}
                    >
                      DESKJET/INKJET/LASERJET
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MKButton variant="outlined" color="dark" onClick={this.openDropdown}>
                      {this.state.selectedPrintFormat?.name}
                      <Icon
                        sx={{
                          transform: this.state.dropdown ? "rotate(180deg)" : "rotate(0)",
                          ...this.iconStyles,
                        }}
                      >
                        expand_more
                      </Icon>
                    </MKButton>
                    <Menu
                      anchorEl={this.state.dropdown}
                      getcontentanchorel={null}
                      open={Boolean(this.state.dropdown)}
                      onClose={() => this.setState({ dropdown: null })}
                    >
                      {PrintFormats.map((key, idx) => (
                        <MenuItem
                          onClick={() => {
                            secureStorage.setItem("printFormat", key);
                            this.setState({ selectedPrintFormat: key, dropdown: null });
                          }}
                          key={idx}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>

              {/* BUTTON TEST PRINT DESKJET */}
              {navigator.userAgent.toLocaleLowerCase().indexOf("electron/") === -1 && (
                <Grid item xs={12} md={8}>
                  <MKButton
                    type="button"
                    variant="gradient"
                    color="info"
                    disabled={this.state.selectedEnabled === "deskjet" ? false : true}
                    onClick={(e) => {
                      print({
                        title: "TEST PRINT",
                        body: TestPrintBody,
                        ref: this.printRef,
                        productId: 14,
                      });
                    }}
                  >
                    TEST PRINT
                  </MKButton>
                </Grid>
              )}
            </Grid>

            {secureStorage.getItem("printer") === "deskjet" && (
              <ComponentToPrint
                title={"TEST PRINT"}
                body={modalReceipt(TestPrintBody)}
                ref={this.printRef}
              />
            )}
          </MKBox>
        </MKBox>
      </Container>
    );
  }
}

export default Printer;

const TestPrintBody = {
  subscriberNo: {
    caption: "IDPEL",
    value: "588400000047",
  },
  name: {
    caption: "NAMA",
    value: "JUNG EUNBI",
  },
  segmentPower: {
    caption: "TARIF/DAYA",
    value: "R1/450",
  },
  referenceNo: {
    caption: "NO REF",
    value: "121R387D70E6F7042B1A01D08B9A3",
  },
  qty: {
    caption: "TOTAL TAG",
    value: "1",
  },
  period: {
    caption: "PERIODE",
    value: "OKT21",
  },
  standMeter: {
    caption: "STAND METER",
    value: "00003243 - 00003521",
  },
  amount: {
    caption: "RP TAG",
    value: 469889,
  },
  admin: {
    caption: "ADMIN BANK",
    value: 3000,
  },
  total: {
    caption: "TOTAL BAYAR",
    value: 472889,
  },
  info: {
    caption: "INFO",
    value: "Informasi Hubungi Call Center: 123 Atau Hub. PLN Terdekat",
  },
};
const PrintFormats = [
  { name: "1/1", value: "OnePage" },
  { name: "1/4 - 2 Columns", value: "TwoColumns" },
  { name: "1/4 - 3 Columns", value: "ThreeColumns" },
];
