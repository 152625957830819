import moment from "moment";
import loader from "./loader";
// import _ from 'lodash'
import secureStorage from "libraries/secureStorage";
import getinfo from "helper/getinfo";
// import cutStr from "helper/receipt/cutstr";

const receipt = (payload) => {
  const printer = secureStorage.getItem("printer");
  const user = secureStorage.getItem("account");
  const info = secureStorage.getItem("domainInfo");
  const title = `${payload.title.padStart(105, " ")}\r\n`;
  const body = payload.body;
  const date = body.date && body.date.value ? body.date.value : payload.createdAt;

  // const totalPayment = body.filter((item,index)=>index >= body.length-4 && index < body.length-1 )

  const leftPage = [
    ["TGL", moment(date).format("DD/MM/YY HH:mm")],
    ["NO VA", body.vaNo.value],
    [body.name.caption, body.name.value],
    ["BLN", body.month.value],
    ["PREMI", body.premiumFee.value],
    ["RP TAG", body.amount.value],
    ["ADMIN", body.admin.value],
    ["BAYAR", body.total.value],
  ];

  for (let i = leftPage.length; i < 11; i++) leftPage.push([""]);

  const middlePage = [
    ["TANGGAL", moment(date).format("DD/MM/YY HH:mm")],
    ["NO VA", body.vaNo.value],
    [body.name.caption, body.name.value],
    ["BLN", body.month.value],
    ["NOCABANG", body.branchCode.value],
    [body.branchName.caption, body.branchName.value],

    ...getinfo(body.info.value),
  ];

  printer === "dotmatrix"
    ? middlePage.push([`${String.fromCharCode(27)}4Loket : ${user.name}`])
    : middlePage.push([`Loket : ${user.name}`]);
  printer === "dotmatrix"
    ? middlePage.push([`Powered by ${payload.appName}${String.fromCharCode(27)}5`])
    : middlePage.push([`Powered by ${payload.appName}`]);

  for (let i = middlePage.length; i < 11; i++) middlePage.push([""]);
  // console.log(middlePage)
  const rightPage = [
    ["PREMI", body.premiumFee.value],
    ["TUNGGAKAN", body.outstandingAmount.value],
    [body.amount.caption, body.amount.value],
    [body.admin.caption, body.admin.value],
    [body.total.caption, body.total.value],
  ];
  for (let i = rightPage.length; i < 11; i++) rightPage.push([""]);

  if (printer === "dotmatrix") return loader(title, leftPage, middlePage, rightPage, payload);
  else return { title, leftPage, middlePage, rightPage, payload };
};
export default receipt;
